import React, { useState } from 'react'

import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';
import { ReactComponent as UnChecked } from '../../assets/images/remember-me-box.svg';
import { ReactComponent as Checked } from '../../assets/images/remember-me-box-selected.svg';
import '../../assets/css/widget/add-network-popup.scss'

export default ({ close, save }) => {

    const [errors, setErrors] = useState([])
    const [network, setNetwork] = useState({ security: "open" })

    const onNameChange = value => {
        setErrors(errors.filter(error => error !== 'name'))
        setNetwork({ ...network, name: value })
    }

    const onPasswordChange = value => {
        setErrors(errors.filter(error => error !== 'password'))
        setNetwork({ ...network, x_passphrase: value })
    }

    return (
        <div className='add-network-popup'>
            <div className="add-network-popup-content">
                <CloseIcon className='close-icon' onClick={close} />
                <label className='add-network-popup-title'>Create network</label>
                <div className='add-network-popup-label-input'>
                    <label>Name</label>
                    <input placeholder='Network name' value={network.name || ''} autoFocus onChange={e => onNameChange(e.target.value)}
                        className={`${errors.length > 0 && errors.includes('name') && 'input-error'}`}
                    />
                </div>
                <div className='add-network-popup-label-input'>
                    <label>Secured</label>
                    {network.security === 'wpapsk' ? <Checked className='secured-logo' onClick={() => setNetwork({ ...network, security: "open" })} /> :
                        <UnChecked className='secured-logo' onClick={() => setNetwork({ ...network, security: 'wpapsk' })} />
                    }
                </div>
                {network.security === 'wpapsk' &&
                    <div className='add-network-popup-label-input'>
                        <label>Password</label>
                        <input placeholder='Network password' type='password' value={network.x_passphrase || ''} onChange={e => onPasswordChange(e.target.value)}
                            className={`${errors.length > 0 && errors.includes('password') && 'input-error'}`}
                        />
                    </div>
                }
                <div className='actions'>
                    <button className='back' onClick={close} >Close</button>
                    <button onClick={() => save(network)}>Save</button>
                </div>
            </div>
        </div>
    )
}