import React, { useState, useEffect, useContext, useRef } from 'react'

import Dropdown from './contactDropdown'
import CreateTagPopup from './createTagPopup'
import AddContactPopup from './addContactPopup'
import AssignTagPopup from './assignTagPopup'
import RemovePopup from './removePopup'
import api from '../../../utils/api'
import { VenuesContext } from '../../../context/venueContext'
import Loader from 'react-loader-spinner'
import { ReactComponent as EditBtn } from '../../../assets/images/edit-btn.svg';
import { ReactComponent as AssignTagBtn } from '../../../assets/images/add-contact-icon.svg';
import { ReactComponent as DeleteBtn } from '../../../assets/images/delete-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/delete.svg';

import '../../../assets/css/sms/contact/contacts.scss'

export default () => {
    const inputFile = useRef(null)
    const [removeTagActice, setRemoveTagActive] = useState(false)
    const [selectedContacts, setSelectedContacts] = useState([])
    const [tagIsLoading, setTagIsLoading] = useState(true)
    const [contactIsLoading, setContactIsLoading] = useState(true)
    const [tagName, setTagName] = useState('')
    const [name, setName] = useState('')
    const [tags, setTags] = useState([])
    const [selectedTag, setSelectedTag] = useState()
    const [selectedFile, setSelectedFile] = useState()
    const [selectAll, setSelectAll] = useState(false)

    const [contacts, setContacts] = useState([])
    const [totalContacts, setTotalContacts] = useState(0)

    const { selected } = useContext(VenuesContext)

    const [showCreateTagPopup, setShowCreateTagPopup] = useState(false)
    const [showAddContactPopup, setShowAddContactPopup] = useState(false)
    const [showAssignTag, setShowAssignTag] = useState(false)
    const [showEdit, setShowEdit] = useState()
    const [updateContact, setUpdateContact] = useState()
    const [showRemovePopup, setShowRemovePopup] = useState(false)
    const [showRemoveTag, setShowRemoveTag] = useState()
    const [showRemoveTagPopup, setShowRemoveTagPopup] = useState()

    useEffect(() => {
        const fetchData = async () => {
            setTagIsLoading(true)
            const { data } = await api.get(`/organisations/${selected.externalId}/tags?name=${tagName}`)
            setTags(data)
            setTagIsLoading(false)
        }
        if (selected)
            fetchData()
    }, [selected, tagName])

    useEffect(() => {
        const fetchData = async () => {
            setContactIsLoading(true)
            const { data } = await api.get(`/organisations/${selected.externalId}/contacts?type=&page=0&name=${name}&tag=${selectedTag ? selectedTag.externalId : ''}&sort=forename&sort=surname&size=1000&requirePhone=true`)
            setContacts(data.content)
            setTotalContacts(data.total)
            setContactIsLoading(false)
        }

        if (selected)
            fetchData()
    }, [selected, name, selectedTag])

    const createTag = async tag => {
        const { data } = await api.post(`/organisations/${selected.externalId}/tags`, tag)
        tag.externalId = data.externalId
        setTags([...tags, tag])
    }

    const saveContact = async contact => {
        const newContact = { ...contact, contactTags: contact.tags }
        contact.tags = contact.tags.map(tag => tag.externalId)
        const { data } = await api.post(`/organisations/${selected.externalId}/contacts`, contact)
        setContacts([...contacts, { ...newContact, externalId: data.externalId }])
        const tagsUsed = contact.tags
        setTags(tags.map(tag => tagsUsed.includes(tag.externalId) ? { ...tag, contacts: tag.contacts + 1 } : tag))
    }

    useEffect(() => {
        setSelectedContacts(selectAll ? contacts : [])
    }, [selectAll])

    const assignTags = async tags => {
        await api.post(`/organisations/${selected.externalId}/contacts/tags`, { contactExternalIds: selectedContacts.map(sc => sc.externalId), tagExternalIds: tags.map(t => t.externalId) })
        setContacts(contacts.map(contact => selectedContacts.find(sc => sc.externalId === contact.externalId) ?
            ({ ...contact, contactTags: contact.contactTags.concat(tags.filter(t => !contact.contactTags.map(ct => ct.externalId).find(ct => t.externalId === ct.externalId))) })
            :
            contact))
        setSelectedContacts([])
    }

    const showEditContact = (e, contact) => {
        e.preventDefault()
        e.stopPropagation()
        setUpdateContact(contact)
    }

    const saveUpdatedContact = async contact => {
        const newContact = { ...contact, contactTags: contact.tags }
        contact.tags = contact.tags.map(tag => tag.externalId)
        await api.put(`/organisations/${selected.externalId}/contacts/${contact.externalId}`, contact)
        setContacts(contacts.map(c => c.externalId === contact.externalId ? newContact : c))
        const tagsUsed = contact.tags
        setTags(tags.map(tag => tagsUsed.includes(tag.externalId) ? { ...tag, contacts: tag.contacts + 1 } : tag))
    }

    const removeContacts = async () => {
        await api.delete(`/organisations/${selected.externalId}/contacts`, { data: { contactExternalIds: selectedContacts.map(sc => sc.externalId) } })
        setContacts(contacts.filter(contact => !selectedContacts.map(sc => sc.externalId).includes(contact.externalId)))
        const tagIds = selectedContacts.map(sc => sc.contactTags).flat().map(tag => tag.externalId)
        setTags(tags.map(t => tagIds.includes(t.externalId) ? { ...t, contacts: t.contacts - 1 } : t))
        setSelectedContacts([])
        setShowRemovePopup(false)
    }

    const deleteTag = async (e, contact, tag) => {
        e.stopPropagation()
        setShowRemoveTagPopup({ contact: contact.externalId, tag: tag.externalId })
    }

    const removeTag = async () => {
        const { contact, tag } = showRemoveTagPopup
        await api.delete(`/organisations/${selected.externalId}/contacts/${contact}/tags/${tag}`, contact)
        setSelectedContacts(selectedContacts.map(sc => sc.externalId === contact ? { ...sc, contactTags: sc.contactTags.filter(ct => ct.externalId !== tag) } : sc))
        setContacts(contacts.map(c => c.externalId === contact ? { ...c, contactTags: c.contactTags.filter(ct => ct.externalId !== tag) } : c))
        setTags(tags.map(t => t.externalId === tag ? { ...t, contacts: t.contacts - 1 } : t))
        setShowRemoveTagPopup(null)
    }

    return (
        <div className='sms-contacts-page'>
            <input className='file-selector-input' type='file' ref={inputFile} onChange={(e) => setSelectedFile(e.target.files[0])} />
            <div className='tags'>
                <div className='tags-top'>
                    <div className='top'>
                        <img src={require('../../../assets/images/tag.svg')} />
                        <label>Tags</label>
                    </div>
                    <input placeholder='Search...' type='search' value={tagName || ''} onChange={e => setTagName(e.target.value)} />
                    <div className='actions'>
                        <button onClick={() => setShowAddContactPopup(true)}>Add contact</button>
                        <button onClick={() => setShowCreateTagPopup(true)}>Create tag</button>
                    </div>
                    <hr />
                </div>
                <div className='tags-bottom'>
                    <div className={`all-contacts ${!selectedTag && 'all-contacts-selected'}`} onClick={() => setSelectedTag(null)}>
                        <label>All contacts</label>
                        <label>{totalContacts} contacts</label>
                    </div>
                    {tags && tags.length > 0 ?
                        <div className='tag-selector'>
                            {tags.map(tag =>
                                <div className={`tag ${selectedTag && selectedTag.externalId === tag.externalId && 'tag-selected'}`} id={tag.id} onClick={() => setSelectedTag(tag)}>
                                    <div className='dot' style={{ backgroundColor: tag.color, borderColor: tag.color === '#ffffff' ? '#C4C4C4' : tag.color }} />
                                    <div className='name'>
                                        <label>{tag.name}</label>
                                        <label>{tag.contacts} contacts</label>
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <div className='tag-selector no-tags'>
                            {tagIsLoading ?
                                <Loader
                                    type="Oval"
                                    color="#EA5459"
                                    height={30}
                                    width={30}
                                />
                                :
                                <label>You have no tags yet</label>
                            }
                        </div>
                    }
                </div>

            </div>

            <div className='contacts'>
                <div className='top'>
                    <label className='title'>All Contacts</label>
                    <div className='actions'>
                        <div className='action-btn' onClick={() => setShowAssignTag(selectedContacts.length > 0)}>
                            <AssignTagBtn className={selectedContacts.length === 0 ? 'inactive-btn' : 'active-btn'} />
                            <label>Add tag</label>
                        </div>
                        <div className='action-btn' onClick={() => setShowRemovePopup(selectedContacts.length > 0)}>
                            <DeleteBtn className={selectedContacts.length === 0 ? 'inactive-btn' : 'active-btn'} />
                            <label>Delete</label>
                        </div>
                        {/* <div className='action-btn'>
                            <img src={require('../../../assets/images/remove-icon.svg')} />
                            <label>Remove tag</label>
                        </div> */}
                    </div>
                    <input placeholder='Search...' type='search' onChange={e => setName(e.target.value)} />
                </div>
                {contacts && contacts.length > 0 ?
                    <div className='contacts-container'>
                        <div className='header'>
                            <div className='selector' onClick={() => setSelectAll(!selectAll)}>
                                {selectAll && <div className='selector-selected' />}
                            </div>
                            <label>Contact</label>
                            <label>Tags</label>
                            <label>Customs</label>
                        </div>
                        <div className='sms-contact-items'>
                            {contacts.map(contact =>
                                <div className={`sms-contact-item ${selectedContacts.find(sc => sc.externalId === contact.externalId) && 'sms-contact-item-selected'}`}
                                    onClick={() => selectedContacts.find(sc => sc.externalId === contact.externalId) ? setSelectedContacts(selectedContacts.filter(sc => sc.externalId !== contact.externalId)) : setSelectedContacts([...selectedContacts, contact])}
                                    onMouseEnter={() => setShowEdit(contact.externalId)}
                                    onMouseLeave={() => setShowEdit(null)}
                                >
                                    <div className='selector'>
                                        {selectedContacts.find(sc => sc.externalId === contact.externalId) && <div className='selector-selected' />}
                                    </div>
                                    <div className='name-phone'>
                                        <label>{contact.forename} {contact.surname}</label>
                                        <label>{contact.phones && contact.phones.length > 0 && contact.phones[0].phoneNumber}</label>
                                    </div>
                                    <div className='contact-tags'>
                                        {contact.contactTags.map(tag =>
                                            <div className='contact-tag-container' style={{ backgroundColor: tag.color, borderColor: tag.color === '#ffffff' ? '#C4C4C4' : tag.color }}
                                                onMouseEnter={() => setShowRemoveTag(`${contact.externalId}-${tag.externalId}`)}
                                                onMouseLeave={() => setShowRemoveTag(null)}
                                            >
                                                <label className='contact-tag' style={{ color: tag.color === '#ffffff' ? 'black' : 'white' }}> {tag.name}</label>
                                                {showRemoveTag === `${contact.externalId}-${tag.externalId}` && <CloseIcon className={`remove-icon ${tag.color === '#ffffff' && 'remove-icon-black'}`} onClick={e => deleteTag(e, contact, tag)} />}
                                            </div>
                                        )}
                                    </div>
                                    <label className='no-custom'>No custom values</label>
                                    {showEdit === contact.externalId && <EditBtn className='edit-contact-btn' onClick={e => showEditContact(e, contact)} />}
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <div className='no-contacts'>
                        {contactIsLoading ?
                            <Loader
                                type="Oval"
                                color="#EA5459"
                                height={30}
                                width={30}
                            />
                            :
                            <>
                                <label>You have no contacts yet</label>
                                <label>Start adding contacts or import an existing list</label>
                                <button onClick={() => setShowAddContactPopup(true)}>Add contact</button>
                            </>
                        }
                    </div>
                }
            </div>
            {showCreateTagPopup && <CreateTagPopup createTag={createTag} close={() => setShowCreateTagPopup(false)} />}
            {showAddContactPopup && <AddContactPopup selectedTag={selectedTag} tags={tags} save={saveContact} close={() => setShowAddContactPopup(false)} inputFile={inputFile} selectedFile={selectedFile} organisationId={selected.externalId} />}
            {showAssignTag && <AssignTagPopup tags={tags} close={() => setShowAssignTag(false)} assignTags={assignTags} />}
            {updateContact && <AddContactPopup tags={tags} save={saveUpdatedContact} close={() => setUpdateContact(false)} organisationId={selected.externalId} update={true} updateContact={updateContact} />}
            {showRemovePopup && <RemovePopup title='Remove Contacts' text={<label>Remove <span>{selectedContacts.length}</span> selected contacts?</label>} close={() => setShowRemovePopup(false)} remove={removeContacts} />}
            {showRemoveTagPopup && <RemovePopup title='Delete tag' text={<label>Are you sure you want to delete this tag?</label>} close={() => setShowRemoveTagPopup(null)} remove={removeTag} />}
        </div >
    )
}