import React, { useState, useEffect } from 'react'

import { MenuItem } from '../components/menuItem'

import { ReactComponent as ArrowLeftIcon } from './../assets/images/arrow-left.svg';

import '../assets/css/sidebar.scss'

export const Sidebar = ({menuItems, selectSubitem, locked, changeLocked }) => {

    const [smallSidebar, setSmallSidebar] = useState(!JSON.parse(localStorage.getItem('sidebar-locked')))

    const changeSidebar = (a) => {
        if (!locked)
            setSmallSidebar(a)
    }

    return (
        <div className={`sidebar ${smallSidebar && 'small-sidebar'}`} onMouseEnter={() => changeSidebar(false)} onMouseLeave={() => changeSidebar(true)}>
            <div className='menu'>
                <div className='menu-icons'>
                    <div className='menu-logo'>
                        <img className='logo-icon' src={require("../assets/images/small-icon.svg")} />
                        <img className='logo' src={require("../assets/images/unaro-main.svg")} />
                    </div>
                </div>
                <div className={`menu-btn ${!locked && 'menu-btn-small-sidebar'}`} onClick={changeLocked}>
                    <div className='line'></div>
                    <ArrowLeftIcon className='icon' />
                </div>
                {menuItems.map(item => <MenuItem key={item.id} item={item} smallMenu={smallSidebar} onClick={() => selectSubitem(item.id)} onSubmenuClick={selectSubitem} smallSidebar={smallSidebar} />)}
            </div>

            <div className='settings'>
                <hr />
                <div className='info'>
                    <label><a target="_blank" href="https://unaro.co.uk/privacy-policy ">Privacy</a></label>
                    <div className="vertical"></div>
                    <label>Terms</label>
                    <div className="vertical"></div>
                    <label>Help</label>
                </div>
                <div className='copyright'>
                    <label>&copy;</label>
                    <label>Copyright</label>
                    <label>2020</label>
                </div>
            </div>
        </div>
    )
}