export const chartDefaults = {
    label: 'Visits',
    fill: true,
    lineTension: 0.4,
    backgroundColor: '#ffcc33',
    borderColor: '#ffcc33',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#ffcc33',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 5,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#ffcc33',
    pointHoverBorderColor: '#ffcc33',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    // data: [65, 59, 80, 81, 56, 55, 40]
}

export const defaultLandingPage = {
    bkgrdExternalId: "",
    boxBorderColor: "#111111",
    boxBorderVisibility: "SHOW",
    boxColor: "#ffffff",
    buttonColor: "#1269ff",
    email: true,
    enabled: true,
    errorTextColor: "#ff0d23",
    facebook: true,
    linkColor: "#ffffff",
    linkHoverColor: "#111111",
    linkedin: true,
    logoExternalId: "#111111",
    menuOpacity: 1,
    textColor: "#FFFFFF",
    twitter: true,
    waitIndicatorColor: "#111111",
    facebookColor: '#ffffff',
    twitterColor: '#ffffff',
    linkedinColor: '#ffffff',
    goButtonColor: '#000000',
    logoColor: '#FFFFFF',
    welcomeText: 'Welcome To',
    descriptionText: ' How would you like to log in today?',
    boxSize: 'BIG',
    track: false,
    inputColor: '#8B8B8B',
}