import React from 'react'

import '../../assets/css/integrations/integrations-item.scss'

export default ({ integration, removeIntegration, updateIntegration }) => {

    const getIcon = (connector) => {
        switch (connector) {
            case 'MAILCHIMP':
                return require('../../assets/images/mailchimp.png')
            case 'SENDGRID':
                return require('../../assets/images/sendgrid.png')
            case 'FB':
                return require('../../assets/images/facebook.png')
            case 'UNIFI':
                return require('../../assets/images/unifi-logo.png')
            default:
                return require('../../assets/images/settings-icon.svg')
        }
    }

    return (
        <div className='integration-item'>
            <div className='integration-item-details'>
                <div className='integration-icon'>
                    <img src={getIcon(integration.connector)} />
                </div>
                <label className='integration-name'>{integration.name}</label>
                <label className='integration-created'>{integration.createdAt}</label>
            </div>

            <div className='integration-item-actions'>
                <button className='test-btn'>Test</button>
                <button onClick={() => updateIntegration(integration)} className='update-btn'>Edit</button>
                <button onClick={() => removeIntegration(integration)}>Remove</button>
            </div>
        </div>
    )
}
