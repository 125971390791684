import React, { useState } from 'react'
import { useHistory } from "react-router-dom";

import { ReactComponent as DropdownLogo } from '../../assets/images/dropdown-icon.svg';

import '../../assets/css/widget/user-dropdown.scss'

export const UserDropdown = () => {
    const history = useHistory()

    const user = JSON.parse(localStorage.getItem('user'))

    const account = () => {
        history.push('/account')
    }

    const settings = () => {
        history.push('/settings')
    }

    const signOut = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('current-organisation')
        localStorage.removeItem('sidebar-locked')
        localStorage.removeItem('selected-organisation')
        localStorage.removeItem('dashboard-layout')
        history.replace('/login')
    }

    const data = [
        {
            id: 0,
            name: 'My account',
            action: account
        },
        {
            id: 1,
            name: 'Settings',
            action: settings
        },
        {
            id: 2,
            name: 'Sign out',
            action: signOut
        }
    ]

    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }

    const changeSelected = (option) => {
        setOpen(false)
        option.action()
    }

    return (
        <div className='user-dropdown-container' tabIndex="0" onBlur={() => setOpen(false)}>
            <div className={`dropdown ${open && 'dropdown-open'}`} onClick={() => toggleOpen()} >
                <div className='ud-name'>
                    {user.avatarUrl ?
                        <img className='profile-picture' src={user.avatarUrl} alt='' />
                        :
                        <div className='profile-picture-initials'>
                            {`${user.forename ? user.forename[0] : ''}${user.surname ? user.surname[0] : ''}`}
                        </div>
                    }
                    <label className='profile-name'>{`${user.forename} ${user.surname}`}</label>
                </div>
                <DropdownLogo className='logo' />
            </div>
            {open &&
                <div className='options'>
                    {data.map(option => <label key={option.id} onClick={() => changeSelected(option)}>{option.name}</label>)}
                </div>
            }
        </div>
    )
}