import React from 'react'

import '../../assets/css/widget/action-popup.scss'

export default ({ text, onYes, onNo }) => {
    return (
        <div className='action-popup'>
            <div className="action-popup-content">
                <h3>{text}</h3>
                <div className='action-popup-actions'>
                    <button className='action-popup-no' onClick={onNo}>No</button>
                    <button onClick={onYes}>Yes</button>
                </div>
            </div>
        </div>
    )
}