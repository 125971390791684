import React from 'react'

import Loader from 'react-loader-spinner'

import '../../assets/css/billing/plans.scss'

export default ({ title, subtitle, plans, buy }) => {

    const colors = ['#FFCC33', '#FF9933', '#EA5454', '#5B5B5B']

    return (
        <div className='plans-component'>
            <label className='plans-title'>{title}</label>
            <div className='plans-subtitle'>{subtitle}</div>
            {plans ?
                <div className='plans'>
                    {plans.map((plan, i) =>
                        <div className='plan' key={plan.id}>
                            <div className='plan-top' style={{ backgroundColor: colors[i % 4] }}>
                                <label>{plan.name}</label>
                            </div>
                            <div className='plan-content'>
                                <label className='plan-price'>£{plan.price} per month</label>
                                <div className='plan-items'>
                                    {
                                        Object.entries(plan.features).map(([key, feature]) =>
                                            <div className='plan-item'>
                                                <img src={require('./../../assets/images/plan-item-icon.svg')} />
                                                <label>{feature}</label>
                                            </div>
                                        )
                                    }
                                </div>
                                <button onClick={() => buy(plan)}>Buy Now</button>
                            </div>
                        </div>
                    )}
                </div>
                :
                <div className='payment-loader'>
                    <Loader
                        type="Oval"
                        color="#EA5459"
                        height={30}
                        width={30}
                    />
                </div>
            }
        </div>
    )
}