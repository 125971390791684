import React from 'react'

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import { PrivateRoute } from '../components/privateRoute'
import { Main } from '../pages/main'
import { Login } from '../pages/login'
import { ActivateUser } from '../pages/activateUser'
import ForgotPassword from '../pages/forgotPassword'
import ResetPassword from '../pages/resetPassword'
import SignUp from '../pages/signup'
import Page from '../pages/page'
import PricePlans from '../pages/pricePlans'

export const Routes = () => {
    return (
        <HashRouter>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path='/activate' >
                    <ActivateUser />
                </Route>
                <Route path='/password/new' >
                    <ForgotPassword />
                </Route>
                <Route path='/password/reset' >
                    <ResetPassword />
                </Route>
                <Route path='/signup'>
                    <SignUp />
                </Route>
                <Route path='/page'>
                    <Page />
                </Route>
                <Route path='/card'>
                    <PricePlans />
                </Route>
                <PrivateRoute path="/" Component={Main} />
                <Route render={() => <Redirect to="/dashboard" />} />
            </Switch>
        </HashRouter>
    )
}