import React, { useState, useContext, useEffect } from 'react'

import { useHistory } from "react-router-dom";

import api from '../../utils/api'

import AddIntegrationsItem from './addIntegrationsItem'
import AddIntegrationPopup from './addIntegrationPopup'
import { VenuesContext } from '../../context/venueContext'

import '../../assets/css/integrations/add-integrations.scss'

export default ({ integrations, setIntegrations }) => {

    const [data, setData] = useState([
        {
            id: 0,
            name: 'Mailchimp',
            icon: require('../../assets/images/mailchimp.png'),
            disabled: false,
            connector: 'MAILCHIMP',
            unsetVenues: []
        },
        {
            id: 1,
            name: 'Messagebird',
            icon: require('../../assets/images/messagebird.svg'),
            disabled: true,
            connector: 'MB',
            unsetVenues: []
        },
        {
            id: 2,
            name: 'Sendgrid',
            icon: require('../../assets/images/sendgrid-full.svg'),
            disabled: true,
            connector: 'SG',
            unsetVenues: []
        },
        {
            id: 3,
            name: 'Facebook',
            icon: require('../../assets/images/facebook.png'),
            disabled: true,
            connector: 'FB',
            unsetVenues: []
        },
        {
            id: 4,
            name: 'Unifi',
            icon: require('../../assets/images/unifi-logo.png'),
            disabled: false,
            connector: 'UNIFI',
            unsetVenues: []
        }
    ])

    const history = useHistory()
    const { selected } = useContext(VenuesContext)
    const [showAddPopup, setShowAddPopup] = useState()
    const [organisations, setOrganisations] = useState([])

    const getVenues = (organisations, integrations, connector) => {
        const organisationsWithIntegration = integrations.filter(integration => integration.connector === connector).map(integration => integration.organisationId)
        return organisations.filter(organisation => !organisationsWithIntegration.includes(organisation.externalId))
    }

    const fetchOrganisations = async () => {
        const result = await api.get(`/organisations/${selected.externalId}/network?relationship=VENUE&page=0size=20`)
        let venues = result.data.content
        if (venues.length === 0)
            venues = [selected, ...result.data.content]
        setOrganisations(venues)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (selected)
                fetchOrganisations()
        }
        fetchData()
    }, [selected, integrations])

    useEffect(() => {
        setData(data.map(d => ({ ...d, unsetVenues: getVenues(organisations, integrations, d.connector) })))
    }, [organisations, integrations])

    const addIntegration = (integration) => {
        const newIntegrations = [...integrations, integration]
        setIntegrations(newIntegrations)
        const venues = getVenues(organisations, newIntegrations, integration.connector)
        if (venues.length === 0) {
            setShowAddPopup(null)
        } else {
            setShowAddPopup({...showAddPopup, 
                unsetVenues: showAddPopup.unsetVenues.filter(venue => venue.externalId !== integration.organisationId)})
        }
    }

    return (
        <div className='add-integrations'>
            {data.map(d => <AddIntegrationsItem onClick={() => setShowAddPopup(d)} key={d.id} {...d} allSet={d.unsetVenues.length === 0} />)}
            {showAddPopup && <AddIntegrationPopup close={() => setShowAddPopup(null)} integrations={integrations} addIntegration={addIntegration} data={showAddPopup} />}
        </div>
    )
}