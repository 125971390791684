import React, { useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import api from '../utils/api'

import { BackButton } from '../components/widget/backButton'
import { DeletePopup } from '../components/widget/deletePopup'
import UpdateIntegrationPopup from '../components/integrations/updateIntegrationPopup'

import ShowIntegration from '../components/integrations/showIntegrations'
import AddIntegrations from '../components/integrations/addIntegrations'

import '../assets/css/integrations/integrations.scss'

export default ({ organisationId }) => {
    const match = useRouteMatch()
    const history = useHistory()

    const [integrations, setIntegrations] = useState([])

    const [deletedIntegration, setDeletedIntegration] = useState()
    const [updateIntegration, setUpdateIntegration] = useState()


    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`/organisation/${organisationId}/integrations`)
            setIntegrations(data)
        }
        fetchData()
    }, [organisationId])

    const removeIntegration = async () => {
        await api.delete(`/organisations/${deletedIntegration.organisationId}/integrations/${deletedIntegration.externalId}`)
        setIntegrations(integrations.filter(integration => integration.externalId !== deletedIntegration.externalId))
        setDeletedIntegration(null)
    }

    const changeIntegration = integration => {
        setIntegrations(integrations.map(i => i.externalId === integration.externalId ? integration : i))
    }

    return (
        <div className='integrations'>
            <BackButton />
            <label className='integrations-title'>Integrations</label>
            <Switch>
                <Route path={`${match.path}/add`}>
                    <AddIntegrations integrations={integrations} setIntegrations={setIntegrations} />
                </Route>
                <Route path={match.path}>
                    <ShowIntegration integrations={integrations} createNewIntegration={() => history.push(`${match.path}/add`)} removeIntegration={setDeletedIntegration} updateIntegration={setUpdateIntegration} />
                </Route>
            </Switch>
            {deletedIntegration && <DeletePopup onCancel={() => setDeletedIntegration(null)} onDelete={removeIntegration} />}
            {updateIntegration && <UpdateIntegrationPopup integration={updateIntegration} close={() => setUpdateIntegration(null)} updateIntegration={changeIntegration} />}
        </div>
    )
}