import React, { useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory, useLocation } from "react-router-dom";

import api from '../utils/api'

import { BackButton } from '../components/widget/backButton'
import Subscription from '../components/billing/subscriptions'
import PaymentMethods from '../components/billing/paymentMethods'
import Invoices from '../components/billing/invoices'
import Payments from '../components/billing/payments'
import DeletePopup from '../components/buttonedPopup'

import '../assets/css/billing/billing.scss'

export default () => {
    const match = useRouteMatch()
    const history = useHistory()
    const location = useLocation()
    const [selected, setSelected] = useState(JSON.parse(localStorage.getItem('selected-organisation')))
    const user = JSON.parse(localStorage.getItem('user'))
    const adminUser = user.organisations[0].orgType !== 'ORGANISATION'
    const organisationId = adminUser && selected && selected.orgType === 'ORGANISATION' ? selected.externalId :  user.organisations[0].externalId


    const [current, setCurrent] = useState('Subscriptions')
    const [suspended, setSuspended] = useState()

    useEffect(() => {
        if (location.pathname === '/billing/cards')
            setCurrent('Manage Cards')
        else if (location.pathname === '/billing/subscriptions')
            setCurrent('Subscriptions')
        // else if (location.pathname === '/billing/payments')
        //     setCurrent('Payments')
        else if (location.pathname === '/billing/invoices')
            setCurrent('Invoices')

    }, [location.pathname])

    const suspend = async () => {
        if (selected.isSuspended) {
            await api.delete(`/organisations/${selected.externalId}/suspend`)
        } else {
            await api.put(`/organisations/${selected.externalId}/suspend`)
        }
        setSelected({ ...selected, isSuspended: !selected.isSuspended })
        setSuspended(null)
    }

    return (
        <div className='billing-page'>
            <BackButton />
            <div className='billing-top'>
                <label className='title'>My Account/{current}</label>
                <div className='billing-actions'>
                    {adminUser && selected && selected.orgType === 'ORGANISATION' &&
                        <button onClick={() => setSuspended(true)}>{selected.isSuspended ? 'Unsuspend account' : 'Suspend account'}</button>
                    }
                </div>
            </div>
            <div className='billing-content'>
                <div className='left'>
                    <label className={location.pathname === '/billing/subscriptions' && 'menu-item-selected'} onClick={() => history.push(`${match.path}/subscriptions`)}>Subscriptions</label>
                    <label className={location.pathname === '/billing/cards' && 'menu-item-selected'} onClick={() => history.push(`${match.path}/cards`)}>Manage Cards</label>
                    {/* <label className={location.pathname === '/billing/payments' && 'menu-item-selected'} onClick={() => history.push(`${match.path}/payments`)}>Payments</label> */}
                    <label className={location.pathname === '/billing/invoices' && 'menu-item-selected'} onClick={() => history.push(`${match.path}/invoices`)}>Invoices</label>
                </div>
                <div className='right'>
                    <Switch>
                        <Route path={`${match.path}/subscriptions`}>
                            <Subscription userOrganisationId={organisationId} />
                        </Route>
                        <Route path={`${match.path}/cards`}>
                            <PaymentMethods organisationId={organisationId} />
                        </Route>
                        {/* <Route path={`${match.path}/payments`}>
                            <Payments organisationId={organisationId} />
                        </Route> */}
                        <Route path={`${match.path}/invoices`}>
                            <Invoices organisationId={organisationId} />
                        </Route>
                    </Switch>
                </div>
            </div>
            {suspended && <DeletePopup title='Delete customer' text={`Are you sure you want to ${selected.isSuspended ? 'unsuspend' : 'suspend'} account?`} close={() => setSuspended(null)} action={suspend} actionText={selected.isSuspended ? 'Unsuspend' : 'Suspend'} />}
        </div>
    )
}