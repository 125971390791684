import React from 'react'

import { timeDifference } from '../../utils/common'

import { ReactComponent as RemoveIcon } from '../../assets/images/remove-from-list.svg';

import '../../assets/css/alert/contact-item.scss'

export default ({ customer, remove }) => {

    const getAvatar = (customer) => {
        if (customer.avatarUrl) {
            if (customer.avatarUrl.includes('facebook'))
                return `${customer.avatarUrl}?width=300&height=300`
            else
                return customer.avatarUrl
        }
        return null
    }

    return (
        <div className='contact-item'>
            <RemoveIcon className='remove-icon' onClick={remove} />
            <img src={getAvatar(customer) || require('../../assets/images/user.svg')} />
            <div className='names'>
                <label className='name'>{customer.forename} {customer.surname}</label>
                <hr />
                <label className='last-visit'>Visited {customer.lastVisit ? timeDifference(new Date(), new Date(customer.lastVisit * 1000)) : 'never'}</label>
            </div>
        </div>
    )
}