import React from 'react'

import { ReactComponent as MaleIcon } from '../assets/images/male.svg';
import { ReactComponent as FemaleIcon } from '../assets/images/female.svg';

import '../assets/css/demographics.scss'

export default ({ demographics }) => {
    return (
        <div className='demographics'>
            <div className='demographics-top'>
                <div className='demographics-logo'>
                    <img src={require("../assets/images/position-icon.svg")} alt='' />
                </div>
                <label className='card-title'>Demographics</label>
            </div>
            <hr />
            <div className='demographics-stats-container'>
                <div className='demographics-stats'>
                    <MaleIcon className='demographics-icon' />
                    <label className='male-percent'>{((demographics.male * 100 / (demographics.male + demographics.female)) || 0).toFixed(0)}%</label>
                </div>

                <div className='demographics-stats'>
                    <FemaleIcon className='demographics-icon' />
                    <label className='female-percent'>{((demographics.female * 100 / (demographics.male + demographics.female)) || 0).toFixed(0)}%</label>
                </div>
            </div>
        </div>
    )
}