import React from 'react'

import '../../assets/css/integrations/add-integrations-item.scss'

export default ({ disabled = true, icon, name, onClick, allSet }) => {

    return (
        <div className='add-integrations-item'>
            <img src={icon} />
            <label>{name}</label>
            <button disabled={disabled || allSet} onClick={onClick}>{disabled ? 'Coming soon' : allSet ? 'All set' : 'Add integration'}</button>
        </div>
    )
}