import React from 'react'

import { Statistics } from '../components/statistics'
import { Chart } from './chart'

import '../assets/css/analytics.scss'

export const Analytics = ({ organisationId }) => {
    const admin = JSON.parse(localStorage.getItem('user')).organisations[0].orgType !== 'ORGANISATION' && !localStorage.getItem('selected-organisation')
    return (
        <div className={`analytics ${admin && 'analytics-row'}`}>
            <Chart organisationId={organisationId} />
            <Statistics organisationId={organisationId}/>
        </div>

    )
}