import React, { useRef, useState, useEffect } from 'react'

import '../../../assets/css/main-dashboard/widgets/emails-collected.scss'

export default ({ style, value = 0, remove, edit }) => {

    const ref = useRef()

    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])

    return (
        <div className={`emails-collected-widget ${edit && 'emails-collected-widget-edit'}`} style={style} ref={ref}>
            <div className='emails-collected-widget-container'>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                <img className='emails-collected-widget-icon' src={require('../../../assets/images/connect-icon.svg')} />
                <label className='emails-collected-widget-title'>Total Emails Collected</label>
                <div className='emails-collected-widget-content'>
                    <label className='emails-collected-widget-value' style={{ fontSize: (height / 6) }} >{value}</label>
                    <div className='emails-collected-widget-bottom'>
                        <img style={{ height: (height / 10) > 16 ? 16 : (height / 10), width: (height / 10) > 16 ? 16 : (height / 10) }} src={require('../../../assets/images/calendar-icon.svg')} />
                        <label style={{ fontSize: (height / 12) > 12 ? 12 : (height / 12) }} >All time</label>
                    </div>
                </div>
            </div>
        </div>
    )
}