import React, { useState } from 'react'
import { SketchPicker } from 'react-color';

import '../../assets/css/widget/color-picker.scss'

export const ColorPicker = ({ title, value, setValue }) => {

    const [showPicker, setShowPicker] = useState(false)

    const onBlur = event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowPicker(false)
        }
    }

    return (
        <div className='color-picker-container' tabIndex="0" onBlur={onBlur}>
            <div className='color-picker'>
                <label className='color-picker-label'>{title}</label>
                <div className='switch-color' onClick={() => setShowPicker(true)}>
                    <label className='color-label'>{value}</label>
                    <div style={{ borderRadius: '50px', width: '20px', height: '20px', marginLeft: '20px', backgroundColor: value }} />
                </div>

            </div>
            {showPicker && <SketchPicker className='sketch-picker' color={value} onChangeComplete={c => setValue(c.hex)} />}
        </div>
    )
}