import React, { useState, useEffect } from 'react'

import api from '../../utils/api'
import { ReactComponent as DesignLogo } from '../../assets/images/design-icon.svg';
import { DeletePopup } from '../../components/widget/deletePopup'
import { LandingPageItem } from './landingPageItem'

import '../../assets/css/design/landing-pages.scss'

export const LandingPages = ({ organisationId, setPopupType, type }) => {
    const [landingPages, setLandingPages] = useState([])
    const [deleteLandingpageId, setDeleteLandingpageId] = useState()

    const fetctLandingPages = async () => {
        const url = type === 'poster' ? `/organisations/${organisationId}/posters` : `/organisations/${organisationId}/landingPages?track=${type === 'track'}`
        const result = await api.get(url)
        setLandingPages(result.data)
    }

    const deleteLandingpage = async () => {
        if (type === 'poster')
            await api.delete(`/organisations/${organisationId}/posters/${deleteLandingpageId}`)
        else
            await api.delete(`/landingPages/${deleteLandingpageId}`)
        setLandingPages([...landingPages.filter(landingpage => landingpage.externalId !== deleteLandingpageId)])
        setDeleteLandingpageId(null)
    }

    useEffect(() => {
        const fetchData = async () => {
            fetctLandingPages()
        }
        fetchData()
    }, [organisationId])

    return (
        <div className='landing-pages'>
            <div className='landing-pages-top'>
                <div className='landing-pages-logo'>
                    <DesignLogo className='landing-page-edit-logo-svg' />
                </div>
                <label className='landing-pages-title'>{`Manage ${type === 'poster' ? 'poster' : 'landing page'} design`}</label>
            </div>
            <div className='landing-pages-grid'>
                {landingPages.map(landingPage => <LandingPageItem key={landingPage.externalId} landingPage={landingPage} type={type} onDelete={(landingPageId) => setDeleteLandingpageId(landingPageId)} />)}
            </div>
            <button className='add-landing-page-btn' onClick={() => setPopupType(type)}>{`Create a new ${type === 'poster' ? 'poster' : 'landing page'}`}</button>
            {deleteLandingpageId && <DeletePopup onCancel={() => setDeleteLandingpageId(null)} onDelete={deleteLandingpage} />}
        </div>
    )
}