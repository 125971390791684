import React, { useState, useEffect } from 'react'
import ChartWidget from '../components/main-dashboard/widgets/chart'
import MessagesWidget from '../components/main-dashboard/widgets/messages'
import EmailsCollectedWidget from '../components/main-dashboard/widgets/emailsCollected'
import CustomersWidget from '../components/main-dashboard/widgets/customers'
import DemographicsWidget from '../components/main-dashboard/widgets/demographics'
import CustomerLoginsWidget from '../components/main-dashboard/widgets/customerLogins'
import RGL, { WidthProvider } from "react-grid-layout";
import api from '../utils/api'

import '../assets/css/main-dashboard.scss'

export default ({ organisationId }) => {
    const ReactGridLayout = WidthProvider(RGL);

    const selected = JSON.parse(localStorage.getItem('selected-organisation'))
    const user = JSON.parse(localStorage.getItem('user'))
    const adminUser = user.organisations[0].orgType !== 'ORGANISATION'
    const userOrganisation = adminUser && selected && selected.orgType === 'ORGANISATION' ? selected.externalId : user.organisations[0].externalId

    const [statistics, setStatistics] = useState()
    const [messageStatistics, setMessageStatistics] = useState({})
    const [analytics, setAnalytics] = useState()
    const [activeLicenses, setActiveLicenses] = useState([])

    const [selectors, setSelectors] = useState([
        {
            externalId: 0,
            name: "Day",
            unit: "day",
            selected: false,
            apiUnit: 'hourly'
        },
        {
            externalId: 1,
            name: "Week",
            unit: "week",
            selected: false,
            apiUnit: 'daily'
        },
        {
            externalId: 2,
            name: "Month",
            unit: "month",
            selected: true,
            apiUnit: 'daily'
        },
        {
            externalId: 3,
            name: "Year",
            unit: "year",
            selected: false,
            apiUnit: 'monthly'
        }
    ])

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`organisations/${userOrganisation}/licences`)
            const currentDate = Math.floor(Date.now() / 1000)
            setActiveLicenses(data.filter(license => license.startDate < currentDate && license.expiryDate > currentDate).map(license => license.licenceType))

        }
        fetchData()
    }, [userOrganisation])


    useEffect(() => {
        const fetchData = async () => {
            if (organisationId != null) {
                const result = await api.get(`/organisation/${organisationId}/summary`)
                setStatistics(result.data)
            }
        }
        fetchData()
    }, [organisationId])

    useEffect(() => {
        const fetchData = async () => {
            if (organisationId != null) {
                const selected = selectors.find(s => s.selected)
                const result = await api.get(`/organisation/${organisationId}/analytics?unit=${selected.apiUnit}&startDate=${getStartDate(selected.unit)}`)
                setAnalytics(result.data)
            }
        }
        fetchData()
    }, [organisationId, JSON.stringify(selectors)])

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`/sms/organisation/${organisationId}/messages/status/statistics`)
            setMessageStatistics(data)
        }
        if (organisationId)
            fetchData()
    }, [organisationId])

    const getStartDate = unit => {
        if (unit === 'day') {
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - 1)
            startDate.setHours(startDate.getHours(), 0, 0, 0);
            return startDate.getTime() / 1000

        } else if (unit === 'week') {
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - 7)
            startDate.setHours(0, 0, 0, 0)
            return startDate.getTime() / 1000

        } else if (unit === 'month') {
            const now = new Date()
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate())
            startDate.setHours(0, 0, 0, 0)
            return startDate.getTime() / 1000
        } else if (unit === 'year') {
            const startDate = new Date()
            startDate.setHours(0, 0, 0, 0);
            startDate.setMonth(startDate.getMonth() - 12, 1)
            return startDate.getTime() / 1000
        }
    }



    const props = {
        className: "layout",
        items: 20,
        rowHeight: 50,
        onLayoutChange: function () { },
        cols: 12,
        isDraggable: true,
        isResizable: true,
    };

    const generateLayout = () => {
        const p = props;
        return new Array(p.items).fill(0).map((item, i) => {
            const y = p.y || Math.ceil(Math.random() * 4) + 1;
            return {
                x: (i * 2) % 12,
                y: Math.floor(i / 6) * y,
                w: 2,
                h: y,
                i: i.toString()
            };
        });
    }

    const [state, setState] = useState()

    const onLayoutChange = (layout) => {
        setState(layout)
        // saveToLS("layout", layout);
        // setState({ layout });
        // props.onLayoutChange(layout);
    }

    return (
        <div className=''>
            {/* <div className='row'>
                <CustomerLoginsWidget style={{ flex: 1 }} statistics={statistics} />
                <EmailsCollectedWidget style={{ flex: 1 }} value={statistics ? Object.entries(statistics.signups).map(([key, value]) => value.total).reduce((c, t) => t += c) : 0} />
                <DemographicsWidget
                    style={{ flex: 1 }}
                    male={statistics ? ((statistics.demographics.male * 100 / (statistics.demographics.male + statistics.demographics.female)) || 0).toFixed(0) : 0}
                    female={statistics ? ((statistics.demographics.female * 100 / (statistics.demographics.male + statistics.demographics.female)) || 0).toFixed(0) : 0}
                />
            </div>
            <div className='row' style={{ flex: 1.5 }}>
                <ChartWidget
                    style={{ flex: 3 }}
                    yTitle='Total visits'
                    title='Visits'
                    icon={require('../assets/images/connect-icon.svg')}
                    selectors={selectors}
                    setSelectors={setSelectors}
                    field='visits'
                    analytics={analytics}
                />
                <CustomersWidget
                    style={{ flex: 1 }}
                    connectedCustomers={statistics ? statistics.connectedDevices : 0}
                    newCustomers={statistics ? Object.entries(statistics.signups).map(([key, value]) => value.today).reduce((c, t) => t += c) : 0}
                    returnRate={statistics ? Math.round(statistics.visits.returnRate) : 0}
                />
                {(activeLicenses.includes('SMS')) &&
                    <MessagesWidget style={{ flex: 1 }} statistics={messageStatistics} />
                }
            </div> */}
            <ReactGridLayout
                layout={state}
                {...props}
                onLayoutChange={onLayoutChange}
                margin={[20, 20]}
                useCSSTransforms = {true}

            >
                <div key="1" data-grid={{ w: 4, h: 4, x: 0, y: 0 }}>
                    <CustomerLoginsWidget statistics={statistics} />
                </div>
                <div key="2" data-grid={{ w: 2, h: 5, x: 2, y: 0 }}>
                    <EmailsCollectedWidget value={statistics ? Object.entries(statistics.signups).map(([key, value]) => value.total).reduce((c, t) => t += c) : 0} />
                </div>
                <div key="3" data-grid={{ w: 2, h: 5, x: 4, y: 0 }}>
                    <MessagesWidget statistics={messageStatistics} />
                </div>
                <div key="4" data-grid={{ w: 2, h: 3, x: 6, y: 0 }} style={{ backgroundColor: 'grey' }}>
                    <span className="text">4</span>
                </div>
                <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0 }} style={{ backgroundColor: 'grey' }}>
                    <span className="text">5</span>
                </div>
            </ReactGridLayout>
        </div>
    )
}