import React from 'react'
import { useRouteMatch, useHistory } from "react-router-dom";

import '../../assets/css/design/landing-page-item.scss'

export const LandingPageItem = ({ landingPage, type, onDelete }) => {
    const match = useRouteMatch()
    const history = useHistory()

    const getLogo = () => {
        if (!landingPage || !landingPage.creatives)
            return type === 'track' ? require('../../assets/images/unaro-full-icon.svg') : require('../../assets/images/rsz_landing_page.png')
        const creative = landingPage.creatives.find(creative => creative.type === type === 'poster' ? 'POSTER_LOGO' : 'LOGO')
        return creative ? creative.url : require('../../assets/images/rsz_landing_page.png')
    }

    return (
        <div className='landing-page-item'>
            <div className='landing-page-item-description'>
                <label>{landingPage.name}</label>
                <hr />
                <div className='landing-page-item-actions'>
                    <button className='action-btn action-btn-edit' onClick={() => history.push({ pathname: `${match.path}/${landingPage.externalId}`, state: { create: false } })}>Edit</button>
                    <button className='action-btn' onClick={() => onDelete(landingPage.externalId)}>Delete</button>
                </div>
            </div>
            <img src={getLogo()} alt='' />
        </div>
    )
}