import React, { useRef, useState } from 'react'

import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import uuid from 'react-uuid'
import { useHistory } from "react-router-dom";

import api, { cloudinaryApi } from '../../utils/api'
import { isValidEmail } from '../../utils/common'
import { InfoPopup } from '../widget/infoPopup'

import 'react-google-places-autocomplete/dist/index.min.css';
import '../../assets/css/account/edit-account.scss'

export default ({ account, setAccount, organisation, setOrganisation }) => {

    const [errors, setErrors] = useState([])
    const [popupMessage, setPopupMessage] = useState()
    const inputFile = useRef(null)
    const history = useHistory()

    const onFirstNameChange = value => {
        setErrors(errors.filter(error => error !== 'forename'))
        setAccount({ ...account, forename: value })
    }

    const onLastNameChange = value => {
        setErrors(errors.filter(error => error !== 'surname'))
        setAccount({ ...account, surname: value })
    }

    const onEmailChange = value => {
        setErrors(errors.filter(error => error !== 'email'))
        setAccount({ ...account, email: value })
    }

    const onPhoneNumberChange = value => {
        setAccount({ ...account, phoneNumber: value })
    }

    const onOrganiastionNameChange = value => {
        setErrors(errors.filter(error => error !== 'organisationName'))
        setOrganisation({ ...organisation, name: value })
    }

    const onOrganisationDescriptionChange = value => {
        setOrganisation({ ...organisation, description: value })
    }

    const onOrganisationEmailChange = value => {
        setErrors(errors.filter(error => error !== 'organisationEmail'))
        setOrganisation({ ...organisation, email: value })
    }

    const onOrganisationPhoneNumberChange = value => {
        setOrganisation({ ...organisation, phone: value })
    }

    const getComponent = (components, name) => {
        return components.find(c => c.types.includes(name)) || {}
    }

    const onSelectSuggestion = async seggestion => {
        const geocodes = await geocodeByPlaceId(seggestion.place_id)
        const geocode = geocodes[0]
        const latLng = await getLatLng(geocode)

        const address = {}
        const country = getComponent(geocode.address_components, 'country')
        address.country = country.long_name
        address.countryCode = country.short_name
        address.fullAddress = geocode.formatted_address
        address.fullAddressLine2 = ''
        address.googlePlaceId = geocode.place_id
        address.isBilling = true
        address.isPrimary = true
        address.lat = latLng.lat
        address.lon = latLng.lng
        address.name = geocode.address_components[0].short_name
        address.postcodeZipcode = getComponent(geocode.address_components, 'postal_code').long_name
        address.city = getComponent(geocode.address_components, 'postal_town').long_name

        setOrganisation({ ...organisation, location: address })
    }

    const getSignature = async (type, folder, trackingKey) => {
        const result = await api.get(`/upload/sign?mediaUsageType=${type}&folder=${folder}&trackingKey=${trackingKey}`)
        return result.data
    }

    const uploadToCloudinary = async (file, signature) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('api_key', signature['apiKey'])
        formData.append('type', 'upload')
        formData.append('folder', signature['uploadOptions']['folder'])
        formData.append('context', signature['context'])
        formData.append('timestamp', signature['timestamp'].toString())
        formData.append('signature', signature['signature'])

        const response = await cloudinaryApi.post(`/${signature.cloudName}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return response.data
    }

    const uploadFile = async (type, folder, file) => {
        const trackingKey = uuid()
        const signature = await getSignature(type, folder, trackingKey)
        return await uploadToCloudinary(file, signature)
    }

    const setFile = async file => {
        const { url } = await uploadFile('AVATAR', 'avatar', file)
        setAccount({ ...account, avatarUrl: url })
    }

    const updateAccount = async () => {
        await api.put('/user', account)
        const user = JSON.parse(localStorage.getItem('user'))
        user.forename = account.forename
        user.surname = account.surname
        user.avatarUrl = account.avatarUrl
        localStorage.setItem('user', JSON.stringify(user))
    }

    const updateOrganisation = async () => {
        await api.put(`/organisations/${organisation.externalId}`, organisation)
    }

    const save = async () => {
        var e = []
        if (!account.forename || account.forename.length < 3)
            e = [...e, 'forename']
        if (!account.surname || account.surname.length < 3)
            e = [...e, 'surname']
        if (!account.email || account.email.length < 3)
            e = [...e, 'email']
        if (!organisation.name || organisation.name.length < 3)
            e = [...e, 'organisationName']

        setErrors(e)

        if (e.length > 0) {
            setPopupMessage('Please fill all mandatory fields')
            setTimeout(() => setPopupMessage(null), 2000)
        }

        if (e.length === 0) {
            setErrors([])

            if (account.email && !isValidEmail(account.email)) {
                setErrors([...errors, 'email'])
                setPopupMessage('Invalid email')
                setTimeout(() => setPopupMessage(null), 2000)
            } else if (organisation.email && !isValidEmail(organisation.email)) {
                setErrors([...errors, 'organisationEmail'])
                setPopupMessage('Invalid business email')
                setTimeout(() => setPopupMessage(null), 2000)
            } else {
                await updateAccount()
                await updateOrganisation()
                history.goBack()
            }
        }
    }

    return (
        <div className='edit-account-page'>
            <div className='edit-account'>
                <div className='account-details'>
                    <div className='account-details-contact'>
                        <input className='file-select-input' type='file' ref={inputFile} onChange={(e) => setFile(e.target.files[0])} />
                        {account.avatarUrl && <img src={account.avatarUrl} onClick={() => inputFile.current.click()} />}
                        {!account.avatarUrl &&
                            <div className='my-account-initials' onClick={() => inputFile.current.click()} >
                                {`${account.forename ? account.forename[0] : ''}${account.surname ? account.surname[0] : ''}`}
                            </div>
                        }
                        <div className='avatar-selector' onClick={() => inputFile.current.click()} >
                            <label>Change image</label>
                        </div>

                        <div className='account-details-name'>
                            <label className='my-profile-label'>My profile</label>
                            <div className='edit-account-label-input'>
                                <label>First name</label>
                                <input placeholder='First name' value={account.forename || ''} onChange={e => onFirstNameChange(e.target.value)}
                                    className={`${errors.length > 0 && errors.includes('forename') && 'input-error'}`}
                                />
                            </div>
                            <div className='edit-account-label-input'>
                                <label>Last name</label>
                                <input placeholder='Last name' value={account.surname || ''} onChange={e => onLastNameChange(e.target.value)}
                                    className={`${errors.length > 0 && errors.includes('surname') && 'input-error'}`}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className='account-details-infos'>
                        <div className='edit-account-label-input'>
                            <label>Email</label>
                            <input placeholder='Email' value={account.email || ''} onChange={e => onEmailChange(e.target.value)}
                                className={`${errors.length > 0 && errors.includes('email') && 'input-error'}`}
                            />
                        </div>
                        <div className='edit-account-label-input'>
                            <label>Phone number</label>
                            <input placeholder='Phone number' value={account.phoneNumber || ''} onChange={e => onPhoneNumberChange(e.target.value)} />
                        </div>
                    </div> */}
                </div>

                <div className='business-details'>
                    <label className='my-profile-label'>My busines</label>
                    <div className='edit-account-label-input'>
                        <label>Name</label>
                        <input placeholder='Business name' value={organisation.name || ''} onChange={e => onOrganiastionNameChange(e.target.value)}
                            className={`${errors.length > 0 && errors.includes('organisationName') && 'input-error'}`}
                        />
                    </div>
                    <div className='edit-account-label-input'>
                        <label>Description</label>
                        <textarea placeholder='Business description' value={organisation.description || ''} onChange={e => onOrganisationDescriptionChange(e.target.value)} />
                    </div>
                    <div className='edit-account-label-input'>
                        <label>Email</label>
                        <input placeholder='Business email' value={organisation.email || ''} onChange={e => onOrganisationEmailChange(e.target.value)}
                            className={`${errors.length > 0 && errors.includes('organisationEmail') && 'input-error'}`}
                        />
                    </div>
                    <div className='edit-account-label-input'>
                        <label>Phone number</label>
                        <input placeholder='Business phone number' value={organisation.phone || ''} onChange={e => onOrganisationPhoneNumberChange(e.target.value)} />
                    </div>
                    <div className='edit-account-label-input'>
                        <label>Location</label>
                        <GooglePlacesAutocomplete
                            placeholder='Location'
                            autocompletionRequest={{

                                componentRestrictions: {
                                    country: ['uk'],
                                }
                            }}
                            onSelect={onSelectSuggestion}
                        />
                    </div>
                </div>
            </div>
            <button className='save-btn' onClick={save}>Save changes</button>
            {popupMessage && <InfoPopup text={popupMessage} onOk={() => setPopupMessage(null)} />}
        </div >
    )
}