import React from 'react'

import '../assets/css/number-navigator.scss'

export const NavigationNumber = ({ navigator, onClick }) => {
    return (
        <div className={`number-navigator ${navigator.selected && 'number-navigator-selected'}`} onClick={() => onClick(navigator.id)}>
            <label>{navigator.text}</label>
        </div>
    )
}