import React, { useState } from 'react'

import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';

import '../../assets/css/widget/tags-input.scss'

export default ({ tags, setTags }) => {

    const [value, setValue] = useState('')

    const addTags = event => {
        if (event.target.value !== '') {
            setTags([...tags, event.target.value])
            event.target.value = ''
        }
    }

    const removeTag = indexToRemove => {
        setTags([...tags.filter((_, index) => index !== indexToRemove)])
    }

    const onKey = e => {
        if (e.key === 'Enter')
            addTags(e)
        else if (e.key === 'Backspace' && value.length === 0) {
            tags.pop()
            setTags([...tags])
        }
        setValue(e.target.value)
    }

    return (
        <div className='tags-input'>
            <ul id='tags'>
                {tags.map((tag, index) =>
                    <li className='tag-input-tag' key={index}>
                        <span className='tag-title'>{tag}</span>
                        <CloseIcon className='close-icon' onClick={() => removeTag(index)} />
                    </li>
                )}
            </ul>
            <input type='text' placeholder='Press enter to add tag' onKeyUp={onKey} />
        </div>
    )
}