import React, { useRef, useState, useEffect } from 'react'

import '../../../assets/css/main-dashboard/widgets/demographics.scss'

export default ({ style, male = 0, female = 0, remove, edit }) => {

    const ref = useRef()

    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])


    return (
        <div className={`demographics-widget ${edit && 'demographics-widget-edit'}`} style={style} ref={ref}>
            <div className='demographics-widget-container' style={{ paddingTop: height < 200 ? '10px' : '20px', paddingBottom: height < 200 ? '10px' : '20px' }}>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                <img className='demographics-widget-icon' src={require('../../../assets/images/connect-icon.svg')} />
                <label className='demographics-widget-title'>Demographics</label>
                <div className='demographics-widget-content'>
                    <div className='demographics-widge-stat'>
                        <img src={require('../../../assets/images/male.svg')} style={{ height: ((height / 2) - 25) > 105 ? 105 : ((height / 2) - 25) }} />
                        <label style={{ fontSize: (height / 5) > 50 ? 50 : (height / 5) }}>{male}%</label>
                    </div>
                    <div className='demographics-widge-stat'>
                        <img src={require('../../../assets/images/female.svg')} style={{ height: ((height / 2) - 25) > 105 ? 105 : ((height / 2) - 25) }} />
                        <label style={{ fontSize: (height / 5) > 50 ? 50 : (height / 5) }} className='female'>{female}%</label>
                    </div>
                </div>
            </div>
        </div>
    )
}