import React from 'react'

import { TitledLabel } from '../customers/customerDetails'

import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as ShopIcon } from '../../assets/images/shop.svg';

import '../../assets/css/account/show-account.scss'

export default ({ account, organisation }) => {

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const getFormatedRegistration = () => {
        if (!account.registration)
            return ''
        const registration = new Date(account.registration * 1000)
        return `${registration.getDate()} ${months[registration.getMonth()]} ${registration.getFullYear()}`
    }

    return (
        <div className='show-account'>
            <div className='account-details'>
                <div className='account-details-contact'>
                    {account.avatarUrl && <img src={account.avatarUrl} />}
                    {!account.avatarUrl &&
                        <div className='my-account-initials'>
                            {`${account.forename ? account.forename[0] : ''}${account.surname ? account.surname[0] : ''}`}
                        </div>
                    }
                    <div className='account-details-name'>
                        <label className='my-profile-label'>My profile</label>
                        <label className='name-label'>{`${account.forename} ${account.surname}`}</label>
                    </div>
                </div>
                <div className='account-details-infos'>
                    <div className='account-details-info'>
                        <TitledLabel title='Email' text={account.email || 'UNKNOWN'} Icon={MailIcon} />
                        <TitledLabel title='Registration' text={getFormatedRegistration()} Icon={CalendarIcon} />
                    </div>
                    <div className='account-details-info'>
                        <TitledLabel title='Phone Number' text={account.phoneNumber || 'UNKNOWN'} Icon={PhoneIcon} />
                    </div>
                </div>
            </div>

            <div className='business-details'>
                <label className='my-profile-label'>My busines</label>
                <label className='name-label'>{organisation.name}</label>
                <label className='description-label'>{organisation.description}</label>

                <div className='busines-details-info'>
                    <div className='busines-details-info-item'>
                        <TitledLabel title='Email' text={organisation.email} Icon={MailIcon} />
                        <TitledLabel title='Phone Number' text={organisation.phone} Icon={PhoneIcon} />
                    </div>
                    <div className='busines-details-info-item'>
                        <TitledLabel title='Location' text={organisation.location ? organisation.location.fullAddress : ''} Icon={LocationIcon} />
                        <TitledLabel title='Venues' text={organisation.venues} Icon={ShopIcon} />
                    </div>
                </div>
            </div>
        </div>
    )
}