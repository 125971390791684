import React, { useRef, useState, useEffect } from 'react'

import '../../../assets/css/main-dashboard/widgets/sms-campaigns.scss'

export default ({ style, totalSent = 0, textsFailed = 0, contacts = 0, campaigns = 0, remove, edit }) => {

    const ref = useRef()

    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])

    return (
        <div className={`sms-campaigns-widget ${edit && 'sms-campaigns-widget-edit'}`} style={style} ref={ref}>
            <div className='sms-campaigns-widget-container'>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                <img className='sms-campaigns-widget-icon' src={require('../../../assets/images/sms-icon.svg')} />
                <label className='sms-campaigns-widget-title bordered sms-campaigns-widget-t'>SMS Total Sent</label>
                <div className='sms-campaigns-widget-content'>
                    <div className='scw-row'>
                        <img src={require('../../../assets/images/total-sent-icon.svg')} />
                        <div className='scwr-right'>
                            <label className='sms-campaigns-widget-value orange' style={{ fontSize: (height / 12) }}>{totalSent}</label>
                            <label className='sms-campaigns-widget-title'>Total Texts Sent</label>
                        </div>
                    </div>
                    <div className='scw-row'>
                        <img src={require('../../../assets/images/texts-failed-icon.svg')} />
                        <div className='scwr-right'>
                            <label className='sms-campaigns-widget-value red' style={{ fontSize: (height / 12) }}>{textsFailed}</label>
                            <label className='sms-campaigns-widget-title'>Texts Failed</label>
                        </div>
                    </div>     <div className='scw-row'>
                        <img src={require('../../../assets/images/total-sms-contacts-icon.svg')} />
                        <div className='scwr-right'>
                            <label className='sms-campaigns-widget-value yellow' style={{ fontSize: (height / 12) }}>{contacts}</label>
                            <label className='sms-campaigns-widget-title'>Total SMS Contacts</label>
                        </div>
                    </div>     <div className='scw-row'>
                        <img src={require('../../../assets/images/campaigns-running-icon.svg')} />
                        <div className='scwr-right'>
                            <label className='sms-campaigns-widget-value orange' style={{ fontSize: (height / 12) }}>{campaigns}</label>
                            <label className='sms-campaigns-widget-title'>Campaigns Running</label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}