import React, { useState } from 'react'

import { useLocation } from "react-router-dom";
import api from '../utils/api'

import ContactItem from '../components/alert/contactItem'

import { ReactComponent as ArrowIcon } from '../assets/images/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../assets/images/arrow-down.svg';
import { ReactComponent as Box } from '../assets/images/box.svg';

import '../assets/css/alert.scss'

export default ({ organisationId, setShowPopup }) => {

    const location = useLocation()

    const [contacts, setContacts] = useState(location.state && location.state.customers ? location.state.customers : [])
    const [alert, sertAlert] = useState({})
    const [isSliding, setIsSliding] = useState(false)
    const [errors, setErrors] = useState([])
    const [sent, setSent] = useState(false)

    const remove = externalId => {
        setContacts(contacts.filter(contact => contact.externalId !== externalId))
    }

    const sendAlert = async () => {
        var e = []
        if (!alert.from || alert.from.length < 3)
            e = [...e, 'from']
        if (!alert.subject || alert.subject.length < 3)
            e = [...e, 'subject']
        if (!alert.message || alert.message.length < 3)
            e = [...e, 'message']

        setErrors(e)

        if (e.length > 0) {
            setShowPopup('Please fill all mandatory fields')
            setTimeout(() => setShowPopup(null), 2000)
        } else if(contacts.length === 0) {
            setShowPopup('Contact list is empty')
            setTimeout(() => setShowPopup(null), 2000)
        } else if (e.length === 0) {
            setErrors([])
            const request = alert
            request.message = alert.message.replace(/\n/g, "<br>")
            request.contactIds = contacts.map(customer => customer.externalId)
            await api.post(`/organisations/${organisationId}/alert`, request)
            sertAlert({})
            setSent(true)
        }
    }

    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const scroll = (element, change, duration) => {
        setIsSliding(true)
        setTimeout(() => setIsSliding(false), duration)

        var start = element.scrollTop,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);

            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    const scrollDown = () => {
        if (!isSliding)
            scroll(document.getElementById('contacts-container'), 300, 500)
    }

    const changeFrom = value => {
        setErrors(errors.filter(error => error !== 'from'))
        sertAlert({ ...alert, from: value })
    }

    const changeSubject = value => {
        setErrors(errors.filter(error => error !== 'subject'))
        sertAlert({ ...alert, subject: value })
    }

    const changeMessage = value => {
        setErrors(errors.filter(error => error !== 'message'))
        sertAlert({ ...alert, message: value })
    }

    return (
        <div className='alert-page'>
            {sent ?
                <div className='alert-sent'>
                    <div className='alert-sent-top'>
                        <img src={require('../assets/images/done.svg')} />
                    </div>
                </div>
                :
                <div className='alert-container'>
                    <div className='alert-left'>
                        <div className={`${errors.length > 0 && errors.includes('from') && 'input-error'} alert-from`} >
                            <label>From:</label>
                            <input value={alert.from || ''} onChange={e => changeFrom(e.target.value)} />
                        </div>
                        <div className={`${errors.length > 0 && errors.includes('subject') && 'input-error'} alert-from`}>
                            <label>Subject:</label>
                            <input value={alert.subject || ''} onChange={e => changeSubject(e.target.value)} />
                        </div>
                        <div className={`${errors.length > 0 && errors.includes('message') && 'input-error'} alert-message`}>
                            <textarea value={alert.message || ''} onChange={e => changeMessage(e.target.value)} />
                        </div>
                    </div>

                    <div className='alert-right'>
                        <div className='alert-send-btn' onClick={sendAlert}>
                            <label>Send email to list below</label>
                            <ArrowIcon />
                        </div>
                        <div className='alert-contacts' id='contacts-container'>
                            {contacts.map(contact => <ContactItem key={contact.externalId} customer={contact} remove={() => remove(contact.externalId)} />)}
                        </div>
                        <div className='box'>
                            <Box className='box-icon' />
                            <Box className='box-icon' />
                        </div>
                        <div className='arrow-down' onClick={scrollDown}>
                            <ArrowDownIcon />
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}