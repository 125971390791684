import React from 'react'

import UnaroIcon from '../unaroIcon'

import { hexToRGB } from '../../../utils/common'
import '../../../assets/css/design/screens/join-screen.scss'

export default ({ landingPage, logoUrl }) => {
    return (
        <div className='join-screen' style={{ backgroundColor: !landingPage.boxSize || landingPage.boxSize === 'BIG' ? landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff' : hexToRGB('#FFFFFF', 0) }}>
            <div className='join-screen-top'>
                <label style={{ color: landingPage.textColor, marginTop: '10px', textAlign: 'center' }} className='phone-preview-text'>{landingPage.welcomeText || ''}</label>
                <div className='join-screen-small-box' style={{
                    backgroundColor: landingPage.boxSize === 'SMALL' ? landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff' : hexToRGB('#FFFFFF', 0),
                    border: landingPage.boxSize === 'SMALL' && landingPage.boxBorderVisibility === 'SHOW' ? `1px solid ${landingPage.boxBorderColor}` : 'none'
                }}>
                    {logoUrl ? <img className='phone-preview-logo' src={logoUrl} /> : <div className='logo-placeholder' />}
                    <label style={{ color: landingPage.textColor, marginTop: '10px', textAlign: 'center' }}>{`Hi First name Last name we recognized you from X. would you like to join ${landingPage.name}?`}</label>

                    <div className='join-screen-actions'>
                        <button style={{backgroundColor: landingPage.buttonColor}}>Join</button>
                        <button style={{backgroundColor: landingPage.buttonColor}}>Cancel</button>
                    </div>
                </div>
            </div>

            <div className='powered-by'>
                <label style={{ color: landingPage.logoColor || '#000000', fontSize: '12px' }}>POWERED BY</label>
                <div className='powered-by-unaro-icon'>
                    <UnaroIcon color={landingPage.logoColor || '#000000'} />
                </div>
            </div>
        </div>
    )
}