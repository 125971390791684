import React from 'react'

import '../../assets/css/billing/payment-done.scss'

export default ({ text, successTitle = 'Payment Success!', errorTitle = 'Payment Failed', finishBtnText = 'Finish', finish, error, email, type, close, retry }) => {
    return (
        <div className='payment-done'>
            <img src={require(`../../assets/images/payment-${error ? 'failed' : 'success'}.svg`)} />
            <label className='pd-title'>{error ? errorTitle : successTitle}</label>
            {error ?
                <label className='pd-subtitle pds-error'>Error Message: {error}</label>
                :
                <div className='pd-subtitle-container'>
                    {text ?
                        <div className='pd-subtitle-text'>{text}</div>
                        :
                        <label className='pd-subtitle'> An invoice has been sent to <span>{email}</span> Click<br /> below to go to your {type} Dashboard and start seeing results!</label>
                    }
                </div>
            }
            {error ?
                <div className='pd-actions'>
                    <button onClick={close}>Cancel</button>
                    <div className='retry-btn' onClick={retry}>
                        <label>Retry</label>
                        <img src={require('../../assets/images/retry-icon.svg')} />
                    </div>
                </div>
                :
                <button onClick={finish}>{finishBtnText}</button>
            }
        </div>
    )
}