import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom";

import { FileSelector } from '../../widget/fileSelector'
import { ColorPicker } from '../../widget/colorPicker'
import { TextSwitch } from '../../widget/textSwitch';
import { PercentSlider } from '../../widget/slider';
import { hexToRGB } from '../../../utils/common'
import QRCode from 'qrcode.react'

import ShowQrPopup from './showQrPopup'

import api, { cloudinaryApi } from '../../../utils/api'
import uuid from 'react-uuid'

import { ReactComponent as DesignLogo } from '../../../assets/images/design-icon.svg'
import { ReactComponent as AddIcon } from '../../../assets/images/add-icon.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/images/arrow.svg';
import { ReactComponent as AddMoreIcon } from '../../../assets/images/add.svg';
import { ReactComponent as DropdownLogo } from '../../../assets/images/drop-down-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/images/remove-from-list.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-qr-icon.svg';

import '../../../assets/css/design/track/edit-track-and-trace-page.scss'

export default () => {
    const urlRef = useRef(null);
    const { landingPageId } = useParams();
    const history = useHistory()
    const [landingPage, setLandingPage] = useState({})
    const [logoText, setLogoText] = useState('')
    const [backgroundText, setBackgroundText] = useState()
    const [logoUrl, setLogoUrl] = useState('')
    const [backgroundUrl, setBackgroundUrl] = useState()
    const [showAddMore, setShowAddMore] = useState(false)
    const [editDesign, setEditDesign] = useState(true)
    const [showQrPopup, setShowQrPopup] = useState(false)
    const [newFieldOptions, setNewFieldOptions] = useState([])
    const [settings, setSettings] = useState({})
    const [copyText, setCopyText] = useState('Copy to clipboard')
  
    useEffect(() => {
        if (!settings.requireDob && !newFieldOptions.includes('birthday'))
            setNewFieldOptions([...newFieldOptions, 'birthday'])
    }, [settings])

    const getUrl = (page, type) => {
        const creative = page.creatives.find(creative => creative.type === type)
        return creative ? creative.url : null
    }

    const getFileName = (page, type) => {
        const creative = page.creatives.find(creative => creative.type === type)
        return creative ? creative.url.substr(creative.url.lastIndexOf('/')) : ''
    }

    const fetctLandingPage = async () => {
        const result = await api.get(`/landingPages/${landingPageId}`)
        setLandingPage(result.data)
        setSettings(result.data.landingpageSettings)
        setLogoText(getFileName(result.data, 'LOGO'))
        setLogoUrl(getUrl(result.data, 'LOGO'))
        setBackgroundText(getFileName(result.data, 'BACKGROUND'))
        setBackgroundUrl(getUrl(result.data, 'BACKGROUND'))
    }

    useEffect(() => {
        const fetchData = async () => {
            fetctLandingPage()
        }
        fetchData()
    }, [landingPageId])

    const getSignature = async (type, folder, trackingKey) => {
        const result = await api.get(`/upload/sign?mediaGroupExternalId=${landingPage.externalId}&mediaUsageType=${type}&folder=${folder}&trackingKey=${trackingKey}`)
        return result.data
    }

    const uploadToCloudinary = async (file, signature) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('api_key', signature['apiKey'])
        formData.append('type', 'upload')
        formData.append('folder', signature['uploadOptions']['folder'])
        formData.append('context', signature['context'])
        formData.append('timestamp', signature['timestamp'].toString())
        formData.append('signature', signature['signature'])

        const response = await cloudinaryApi.post(`/${signature.cloudName}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return response.data
    }

    const uploadFile = async (type, folder, file) => {
        const trackingKey = uuid()
        const signature = await getSignature(type, folder, trackingKey)
        return await uploadToCloudinary(file, signature)
    }

    const uploadLogo = async file => {
        const { public_id, url } = await uploadFile('LOGO', 'logo', file)
        setLandingPage({ ...landingPage, logoExternalId: public_id })
        setLogoText(file.name)
        setLogoUrl(url)
    }

    const uploadBackground = async file => {
        const { public_id, url } = await uploadFile('BACKGROUND', 'bg', file)
        setLandingPage({ ...landingPage, bkgrdExternalId: public_id })
        setBackgroundText(file.name)
        setBackgroundUrl(url)
    }

    const saveLandingPage = async () => {
        landingPage.landingpageSettings = settings
        await api.put(`/landingPages/${landingPage.externalId}`, landingPage)
        history.goBack()
    }

    const onBlurPopup = event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowAddMore(false)
        }
    }

    const changeNewFieldOptions = field => {
        if (field === 'birthday')
            setSettings({ ...settings, requireDob: true })
        setNewFieldOptions(newFieldOptions.filter(o => o !== field))
        setShowAddMore(false)
    }

    const removeBirthday = () => {
        setSettings({ ...settings, requireDob: false })
        setNewFieldOptions(newFieldOptions.filter(o => o !== 'birthday'))
        setShowAddMore(false)
    }
    const changeToSettings = () => {
        setEditDesign(false)
    }

    const downloadQR = () => {
        const canvas = document.getElementById("qr");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qr.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const copyToClipboard = async () => {
        setCopyText('Copied!')
        setTimeout(() => setCopyText('Copy to clipboard'), 1000)
        await navigator.clipboard.writeText(`https://${process.env.REACT_APP_TT_URL}/#/qr/register/${landingPage.externalId}`);
    }

    return (
        <div className='edit-track-and-trace-page'>
            <div className='edit-track-and-trace'>
                <div className='edit-track-and-trace-top'>
                    <div className='edit-track-and-trace-title'>
                        <div className='edit-track-and-trace-logo'>
                            <DesignLogo className='edit-track-and-trace-logo-svg' />
                        </div>
                        <label className='landing-page-edit-title-text'>{editDesign ? 'Editor' : 'Settings'}</label>
                    </div>
                    <div className='landing-page-edit-actions'>
                        <label onClick={() => setEditDesign(true)} className={`landing-page-edit-top-select ${editDesign && 'landing-page-edit-top-select-selected'}`}>Design</label>
                        <label onClick={changeToSettings} className={`landing-page-edit-top-select ${!editDesign && 'landing-page-edit-top-select-selected'}`}>Settings</label>
                    </div>
                </div>
                <hr className='edit-track-and-trace-top-line' />
                {editDesign ?
                    <div>
                        <div className='edit-track-and-trace-name-input'>
                            <label>Name:</label>
                            <input value={landingPage.name || ''} onChange={e => setLandingPage({ ...landingPage, name: e.target.value })} />
                        </div>
                        <label className='edit-track-and-trace-subtitle'>Design - Landing Page</label>
                        <p className='edit-track-and-trace-description'>Use the form below to upload and select assets for your landing page.</p>
                        <FileSelector title='Select logo:' className='file-selector' fileName={logoText} setFile={uploadLogo} />
                        <FileSelector title='Select background:' className='file-selector' fileName={backgroundText} setFile={uploadBackground} />
                        <hr className='edit-track-and-trace-menu-line' />
                        <label className='edit-track-and-trace-subtitle'>Fields</label>
                        <div className='edit-track-and-trace-field'>
                            <label>Field 1</label>
                            <label className='edit-track-and-trace-fiel-name'>Forename</label>
                        </div>
                        <div className='edit-track-and-trace-field'>
                            <label>Field 2</label>
                            <label className='edit-track-and-trace-fiel-name'>Surname</label>
                        </div>
                        <div className='edit-track-and-trace-field'>
                            <label>Field 3</label>
                            <label className='edit-track-and-trace-fiel-name'>Phone number</label>
                        </div>
                        <div className='edit-track-and-trace-field'>
                            <label>Field 4</label>
                            <label className='edit-track-and-trace-fiel-name'>Email address</label>
                        </div>
                        <div className='edit-track-and-trace-field'>
                            <label>Field 5</label>
                            <label className='edit-track-and-trace-fiel-name'>Title</label>
                        </div>
                        <div className='edit-track-and-trace-field'>
                            <label>Field 6</label>
                            <label className='edit-track-and-trace-fiel-name'>Gender</label>
                        </div>
                        {settings.requireDob &&
                            <div className='edit-track-and-trace-field'>
                                <label>Field 7</label>
                                <label className='edit-track-and-trace-fiel-name'>Birthday</label>
                                <RemoveIcon className='remove-icon' onClick={removeBirthday} />
                            </div>
                        }
                        {newFieldOptions.length > 0 &&
                            <div className='add-field' onClick={() => setShowAddMore(true)} tabIndex="0" onBlur={onBlurPopup}>
                                <AddIcon />
                                <label>Add new field</label>
                                {showAddMore &&
                                    <div className='new-field-options'  >
                                        {newFieldOptions.map((option, id) => <label key={id} onClick={() => changeNewFieldOptions(option)}>{option}</label>)}
                                    </div>
                                }
                            </div>
                        }

                        <hr className='edit-track-and-trace-menu-line' />
                        <label className='edit-track-and-trace-subtitle'>Page styling</label>
                        <p className='edit-track-and-trace-description'>Please use the controls below to style text, hyperlinks and backgrounds.</p>
                        <PercentSlider title='Menu Box Opacity:' value={1 - landingPage.menuOpacity} setValue={value => setLandingPage({ ...landingPage, menuOpacity: 1 - value })} />
                        <ColorPicker title='Box Color:' value={landingPage.boxColor} setValue={value => setLandingPage({ ...landingPage, boxColor: value })} />
                        <TextSwitch title='Box Border Visibility:' value={landingPage.boxBorderVisibility} values={['HIDE', 'SHOW']} setValue={value => setLandingPage({ ...landingPage, boxBorderVisibility: value })} />
                        {landingPage.boxBorderVisibility === 'SHOW' &&
                            <ColorPicker title='Box Border Color:' value={landingPage.boxBorderColor} setValue={value => setLandingPage({ ...landingPage, boxBorderColor: value })} />
                        }
                        <ColorPicker title='Text Color:' value={landingPage.textColor} setValue={value => setLandingPage({ ...landingPage, textColor: value })} />
                        <ColorPicker title='Input Color:' value={landingPage.inputColor} setValue={value => setLandingPage({ ...landingPage, inputColor: value })} />
                        <ColorPicker title='Link Color:' value={landingPage.linkColor} setValue={value => setLandingPage({ ...landingPage, linkColor: value })} />
                        <ColorPicker title='Link Hover Color:' value={landingPage.linkHoverColor} setValue={value => setLandingPage({ ...landingPage, linkHoverColor: value })} />
                        <ColorPicker title='Error Text Color:' value={landingPage.errorTextColor} setValue={value => setLandingPage({ ...landingPage, errorTextColor: value })} />
                        <ColorPicker title='Button Color:' value={landingPage.buttonColor} setValue={value => setLandingPage({ ...landingPage, buttonColor: value })} />
                        <ColorPicker title='Add More Button Color:' value={landingPage.goButtonColor} setValue={value => setLandingPage({ ...landingPage, goButtonColor: value })} />

                        <ColorPicker title='Wait Indicator Color:' value={landingPage.waitIndicatorColor} setValue={value => setLandingPage({ ...landingPage, waitIndicatorColor: value })} />
                        <hr className='edit-track-and-trace-menu-line' />
                        <button className='dit-track-and-trace-save-btn' onClick={saveLandingPage}>Save changes</button>

                    </div>
                    :
                    <div className='track-and-trace-settings'>
                        <div className='qr'>
                            <div className='left'>
                                <label className='title'>Your QR Code</label>
                                <DownloadIcon />
                                <button onClick={downloadQR}>Download</button>
                            </div>
                            <div className='right'>
                                <QRCode id='qr' value={`https://${process.env.REACT_APP_TT_URL}/#/qr/register/${landingPage.externalId}`} size={200} />
                            </div>
                        </div>
                        <hr />
                        <div className='nfc'>
                            <label className='title'>Your NFC Code</label>
                            <div className='nfc-label'>
                                <label className='copy-btn' onClick={copyToClipboard}>{copyText}</label>
                                <label className='url' ref={urlRef}>{`https://${process.env.REACT_APP_TT_URL}/#/qr/register/${landingPage.externalId}`}</label>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className='edit-track-and-trace-preview'>
                <div className='edit-track-and-trace-preview-content'>
                    <div className='edit-track-and-trace-phone-preview'>

                        <img src={require('../../../assets/images/preview.png')} />

                        <div className='phone-preview-box' style={{ backgroundColor: backgroundUrl ? 'unset' : '#FECC33', backgroundImage: backgroundUrl ? `url(${backgroundUrl}` : '../../../assets/images/unaro-yellow-bg.svg' }}>
                            <div className='edit-track-and-trace-box' style={{ backgroundColor: landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff', border: landingPage.boxBorderVisibility === 'SHOW' ? `1px solid ${landingPage.boxBorderColor}` : 'none' }}>
                                <div className='edit-track-and-trace-top'>
                                    <img src={logoUrl ? logoUrl : require('../../../assets/images/unaro-full-icon.svg')} />
                                    <label style={{ color: landingPage.textColor }} className='connect-with-email'>Connect with email</label>
                                    <label style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} className='input-preview'>Forename:</label>
                                    <label style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} className='input-preview'>Surname:</label>
                                    <label style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} className='input-preview'>Phone number:</label>
                                    <label style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} className='input-preview'>Email:</label>
                                    <div className='input-preview title-preview' style={{ borderColor: landingPage.inputColor }} >
                                        <label style={{ color: landingPage.inputColor }} >Title:</label>
                                        <div>
                                            <label style={{ color: landingPage.inputColor }} >Mr</label>
                                            <DropdownLogo className='icon' />
                                        </div>
                                    </div>
                                    <div className='input-preview title-preview' style={{ borderColor: landingPage.inputColor }} >
                                        <label style={{ color: landingPage.inputColor }} >Gender:</label>
                                        <div>
                                            <label style={{ color: landingPage.inputColor }} >male</label>
                                            <DropdownLogo className='icon' />
                                        </div>
                                    </div>
                                    {settings.requireDob &&
                                        <div className='input-preview title-preview' style={{ borderColor: landingPage.inputColor }} >
                                            <label style={{ color: landingPage.inputColor }} >Date of Birth:</label>
                                            <div>
                                                <label style={{ color: landingPage.inputColor }} >DD/MM/YY</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='edit-track-and-trace-bottom'>
                                    <div className='edit-track-and-trace-register-btn' style={{ backgroundColor: landingPage.buttonColor }}>
                                        <label>Register</label>
                                        <ArrowIcon />
                                    </div>
                                    <div className='edit-track-and-trace-register-btn add-more' style={{ backgroundColor: landingPage.goButtonColor }}>
                                        <AddMoreIcon className='add-more-icon' />
                                        <label>Add More Guests</label>
                                    </div>
                                    <label className='privacy'>Click here to read our <span style={{ color: landingPage.linkColor }}>Privacy Policy</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showQrPopup && <ShowQrPopup landingPageId={landingPage.externalId} close={() => setShowQrPopup(false)} />}
        </div>
    )
}