import React, { useState, useEffect } from 'react'
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Area, AreaChart, Tooltip } from 'recharts';

import '../../../assets/css/main-dashboard/widgets/chart.scss'

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <label>{label}</label>
                {payload && payload.map((p, i) =>
                    <div className='tooltip-content' key={i}>
                        <div className='square' style={{ backgroundColor: p.stroke }} />
                        <label>{p.dataKey}: {p.value}</label>
                    </div>

                )}
            </div>
        );
    }

    return null;
};

export default ({ yTitle, style, title, icon, analytics, selectors, setSelectors, showSelectors = true, field, remove, edit }) => {

    const [chartData, setChartData] = useState([])
    const [names, setNames] = useState([])
    const [unit, setUnit] = useState('month')

    useEffect(() => {
        setUnit(selectors.find(s => s.selected).unit)
    }, [selectors])

    const getDayLabels = () => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() - 1)
        currentDate.setHours(currentDate.getHours(), 0, 0, 0);
        return Array.from(Array(24).keys()).map(h => currentDate.setTime(currentDate.getTime() + (60 * 60 * 1000)) / 1000)
    }

    const getWeekLabels = () => {
        return Array.from(Array(8).keys()).map(a => {
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 7 + a)
            currentDate.setHours(0, 0, 0, 0);
            return currentDate
        }).map(d => d.getTime() / 1000)
    }

    const getMonthLabels = () => {
        const now = new Date()
        const daysInMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate()
        return Array.from(Array(daysInMonth).keys()).map(a => {
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - daysInMonth + a + 1)
            currentDate.setHours(0, 0, 0, 0);
            return currentDate
        }).map(d => d.getTime() / 1000)
    }


    const getYearLabels = () => {
        return Array.from(Array(13).keys()).map(a => {
            const currentDate = new Date()
            currentDate.setMonth(currentDate.getMonth() - 11 + a, 1)
            currentDate.setHours(0, 0, 0, 0);
            return currentDate
        }).map(d => d.getTime() / 1000)
    }

    const getLabels = () => {
        if (unit === 'day')
            return getDayLabels()
        else if (unit === 'week')
            return getWeekLabels()
        else if (unit === 'month')
            return getMonthLabels()
        else if (unit === 'year')
            return getYearLabels()
    }

    const formatLabel = label => {
        if (unit === 'day')
            return `${label.getHours()}:${label.getMinutes()}0`
        else if (unit === 'week')
            return label.toString().split(' ')[0]
        else if (unit === 'month')
            return label.toString().split(' ')[2]
        else if (unit === 'year')
            return `${label.toString().split(' ')[1]} ${label.toString().split(' ')[3]}`
        else
            return ''
    }

    const formatTs = ts => {
        if (unit === 'day')
            return ts
        else {
            const date = new Date(ts * 1000)
            date.setHours(0, 0, 0, 0)
            return date.getTime() / 1000
        }
    }
    useEffect(() => {
        const labels = getLabels()
        const data = labels.map(l => new Date(l * 1000)).map(l => ({ name: formatLabel(l) }))

        if (analytics) {
            labels.forEach((label, i) => {
                analytics.forEach(stats => {
                    const organisation = stats.organisationName
                    const visits = stats[field]

                    const visit = visits.find(v => formatTs(v.timestamp) === label)
                    data[i][organisation] = visit ? visit.number : 0
                })
            })
        }

        setChartData(data)

    }, [analytics, unit])

    useEffect(() => {
        if (chartData && chartData.length > 0)
            setNames(Object.keys(chartData[0]).slice(1))
    }, [chartData])

    const changeSelected = selector => {
        setSelectors(selectors.map(s => ({ ...s, selected: s.externalId === selector.externalId })))
    }

    const colors = ['#FFCC33', '#FF9933', '#EA5454']

    return (
        <div className={`chart-widget ${edit && 'chart-widget-edit'}`} style={style}>
            <div className='chart-widget-container'>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                {icon && <img className='chart-widget-icon' src={icon} />}
                <div className='chart-widget-top'>
                    <label className='title'>{title}</label>
                    {showSelectors &&
                        <div className='selectors'>
                            {selectors.map(selector =>
                                <div key={selector.externalId} className={`selector ${selector.selected && 'selected-selector'}`} onClick={() => changeSelected(selector)}>
                                    <label>{selector.name}</label>
                                </div>
                            )}
                        </div>
                    }
                </div>
                <label className='y-title'>{yTitle}</label>
                {/* <label className='x-title'>Time</label> */}
                <div className='chart'>
                    <ResponsiveContainer width='99%' height="99%" minWidth="0" >
                        <AreaChart
                            data={chartData}
                        >
                            <defs>
                                <linearGradient id="0" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(250, 204, 51, 1)" />
                                    <stop offset="50%" stopColor="rgba(250, 204, 51, 0.5)" />
                                    <stop offset="100%" stopColor="rgba(250, 204, 51, 0)" />
                                </linearGradient>
                                <linearGradient id="1" x1="1" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(255, 253, 51, 1)" />
                                    <stop offset="50%" stopColor="rgba(255, 253, 51, 0.5)" />
                                    <stop offset="100%" stopColor="rgba(242, 253, 51, 0)" />
                                </linearGradient>
                                <linearGradient id="2" x1="2" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(234, 84, 84, 1)" />
                                    <stop offset="50%" stopColor="rgba(234, 84, 84, 0.5)" />
                                    <stop offset="100%" stopColor="rgba(234, 84, 84, 0)" />
                                </linearGradient>
                            </defs>
                            <Tooltip cursor={false} content={<CustomTooltip />} animationDuration={0} />
                            <CartesianGrid strokeDasharray="0" vertical={false} stroke='rgba(196, 196, 196, 0.1)' horizontalFill={['rgba(196, 196, 196, 0.1)', '#ffffff']} />
                            <YAxis allowDecimals={false} tick={{ fontSize: 10, }} type='number' axisLine={{ stroke: '#eeeeee' }} tickLine={false} tickCount='10' minTickGap={0} />
                            <XAxis dataKey="name" tick={{ fontSize: 10, }} axisLine={{ stroke: '#eeeeee' }} tickLine={false} minTickGap={0} interval={0} />
                            {names.map((name, i) =>
                                <Area type='monotone' dataKey={name} fill={`url('#${0}')`} stroke={colors[i % 3]} strokeWidth={3} key={i} />
                            )}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}