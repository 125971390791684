import React, { useState, useEffect } from 'react'

import api from '../../utils/api'
import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';
import { Dropdown } from '../../components/widget/dropdown'

import TagsInput from '../widget/tagInput'

import '../../assets/css/integrations/update-integration-popup.scss'


export default ({ integration, close, updateIntegration }) => {

    const [updatedIntegration, setUpdatedIntegration] = useState(integration)
    const [ids, setIds] = useState(integration.integrationParams.filter(id => id.paramName === 'AUDIENCE_ID').map(ip => ({ externalId: ip.externalId, id: ip.paramValue, name: ip.paramText })))
    const [selectedId, setSelectedId] = useState(ids[0])
    const [siteId, setSiteId] = useState()
    const [errors, setErrors] = useState([])
    const [showError, setShowError] = useState()
    const [serverError, setServerError] = useState()
    const userPass = integration.apiKey.split(';')
    const [name, setName] = useState(userPass[0])
    const [password, setPassword] = useState(userPass[1])

    const onBlur = event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            close()
        }
    }

    const changeApiKey = value => {
        setErrors(errors.filter(error => error !== 'apiKey'))
        setShowError()
        setSelectedId(null)
        setUpdatedIntegration({ ...updatedIntegration, apiKey: value })
    }

    const changeApiEndpoint = value => {
        setErrors(errors.filter(error => error !== 'apiEndpoint'))
        setShowError()
        setUpdatedIntegration({ ...updatedIntegration, apiEndpoint: value })
    }

    const changeName = value => {
        setErrors(errors.filter(error => error !== 'name'))
        setShowError()
        setName(value)
    }

    const changeSelected = selected => {
        setErrors(errors.filter(error => error !== 'id'))
        setShowError()
        setSelectedId(selected)
    }

    const changePassword = value => {
        setErrors(errors.filter(error => error !== 'password'))
        setShowError()
        setPassword(value)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get('/integrations/mailchimp', { headers: { apiKey: updatedIntegration.apiKey } })
                const audienceIds = data.lists.map((list, index) => ({ externalId: index, id: list.id, name: list.name }))
                setIds(audienceIds)
                changeSelected(audienceIds.find(id => id.id === integration.integrationParams.find(id => id.paramName === 'AUDIENCE_ID').paramValue))
            } catch (e) {
                setServerError('Invalid api key')
            }
        }
        if (integration.connector === 'MAILCHIMP' && integration.apiKey && integration.apiKey.length > 3)
            fetchData()

    }, [updatedIntegration.apiKey])

    useEffect(() => {
        if (integration) {
            const id = integration.integrationParams.find(id => id.paramName === 'SITE_ID')
            if (id)
                setSiteId(id.paramValue)
        }
    }, [integration])


    const save = async () => {
        var e = []

        if (integration.connector === 'MAILCHIMP') {
            if (!updatedIntegration.apiKey || updatedIntegration.apiKey === ' ')
                e = [...e, 'apiKey']
            if (!selectedId)
                e = [...e, 'id']
        } else if (integration.connector === 'UNIFI') {
            if (!password || password === ' ' || !/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(password))
                e = [...e, 'apiKey']
            if (!updatedIntegration.apiEndpoint || updatedIntegration.apiEndpoint === ' ')
                e = [...e, 'apiEndpoint']
            if (!name || name === ' ')
                e = [...e, 'name']
        }

        setErrors(e)

        if (e.length > 0) {
            if (e.includes('password'))
                setShowError('Password should contain 1 upercase, 1 lowercase and 1 number')
            else
                setShowError('Please fill all fields')
        } else {
            setErrors([])
            if (integration.connector === 'UNIFI') {
                integration.apiKey = `${name};${password}`
                integration.integrationParams = [{ paramName: 'SITE_ID', paramValue: siteId }]
            }
            await api.put(`/organisations/${integration.organisationId}/integrations/${integration.externalId}`, updatedIntegration)
            updateIntegration(updatedIntegration)
            close()
        }
    }

    const setTags = tags => {
        setUpdatedIntegration({ ...updatedIntegration, tags: tags })
    }

    return (
        <div className='update-integration-popup'>
            <div className='update-integration-popup-content' tabIndex="0" onBlur={onBlur}>

                <div className='update-integration-popup-content-top'>
                    <label className='update-integration-popup-title'>Edit integration</label>
                    <CloseIcon className='close-icon' onClick={close} />
                </div>

                <div className='update-integration-form'>
                    <div className='update-integration-popup-venue'>
                        <div className='update-integration-popup-venue-logo'>
                            <img src={require('../../assets/images/shop.svg')} />
                        </div>
                        <label>{updatedIntegration.name}</label>
                    </div>
                    {integration.connector === 'MAILCHIMP' ?
                        <>

                            <label className='update-integration-popup-input-label'>API key</label>
                            <input placeholder='Enter api key' autoFocus value={updatedIntegration.apiKey || ''} onChange={e => changeApiKey(e.target.value)} className={`update-integration-popup-item-input ${errors.length > 0 && errors.includes('apiKey') && 'input-error'}`} />

                            <label className='update-integration-popup-input-label'>Audience id</label>
                            <div className={`update-integration-popup-item-dropdown ${errors.length > 0 && errors.includes('id') && 'input-error'}`}>
                                <Dropdown options={ids} selected={selectedId || { name: 'Please select audience id' }} onSelect={changeSelected} />
                            </div>

                            <label className='update-integration-popup-input-label'>Tags</label>
                            <TagsInput tags={updatedIntegration.tags} setTags={setTags} />
                        </>
                        :
                        integration.connector === 'UNIFI' ?
                            <>
                                <label className='input-label'>Controller URL</label>
                                <input placeholder='Enter controller url' value={updatedIntegration.apiEndpoint || ''} onChange={e => changeApiEndpoint(e.target.value)} className={`update-input ${errors.length > 0 && errors.includes('apiEndpoint') && 'input-error'}`} />
                                <label className='input-label'>Username</label>
                                <input placeholder='Enter username' value={name || ''} onChange={e => changeName(e.target.value)} className={`update-input ${errors.length > 0 && errors.includes('name') && 'input-error'}`} />
                                <label className='input-label'>Password</label>
                                <input placeholder='Enter password' type='password' value={password || ''} onChange={e => changePassword(e.target.value)} className={`update-input ${errors.length > 0 && errors.includes('apiKey') && 'input-error'}`} />
                                <label className='input-label'>Password</label>
                                <input placeholder='Enter site ID' value={siteId || ''} onChange={e => setSiteId(e.target.value)} className={`update-input`} />
                            </>
                            :
                            <>
                                Coming soon
                              </>
                    }
                    {showError && <label className='error-label'>{showError}</label>}
                    {serverError && <label className='error-label'>{serverError}</label>}

                    <button onClick={save}>SAVE</button>
                </div>
            </div>
        </div>
    )
}