import React, { useState } from 'react'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentMethodForm from './addPaymentMethodForm'
import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';

import '../../assets/css/billing/add-payment-method-popup.scss'

export default ({ close, savePaymentMethod, secret }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    return (
        <div className='add-payment-method-popup' onClick={onClick}>
            <div className='add-payment-method-popup-content'>
                <div className='add-payment-method-top'>
                    <label>Add payment method</label>
                    <CloseIcon className='close-icon' onClick={close} />
                </div>
                <Elements stripe={stripePromise}>
                    <AddPaymentMethodForm close={close} save={savePaymentMethod} secret={secret} />
                </Elements>
            </div>
        </div>
    )
}