import React from 'react'
import { FileSelector } from '../widget/fileSelector'
import { ColorPicker } from '../widget/colorPicker'
import { jsPDF } from "jspdf";
import { hexToRGBNoO } from '../../utils/common'
import '../../assets/fonts/Roboto-Light-normal'
import '../../assets/fonts/Roboto-Bold-bold'

import '../../assets/css/poster/create-poster.scss'

export default ({ poster, setPoster, uploadFile, logoText, setLogoText, logoUrl, logo, setLogoUrl, save }) => {

    const uploadLogo = async file => {
        const { public_id, url } = await uploadFile('POSTER_LOGO', 'logo', file)
        setPoster({ ...poster, logoExternalId: public_id })
        setLogoText(file.name)
        setLogoUrl(url)
    }

    const changeMessage = value => {
        if (value.split(' ').length <= 40)
            setPoster({ ...poster, message: value })
    }

    const exportPdf = () => {
        const doc = new jsPDF();
        const [r, g, b] = hexToRGBNoO(poster.backgroundColor)
        doc.setFillColor(r, g, b);
        doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height - 70, "F")
        doc.setTextColor('#ffffff')
        doc.setFontSize(14)
        doc.setFont('Roboto-Light', 'normal');
        doc.text("Powered by", doc.internal.pageSize.width / 2, 20, { align: 'center' })
        doc.addImage(require('../../assets/images/unaro.png'), "PNG", doc.internal.pageSize.width / 2 - 45 / 2, 25, 45, 9)
        doc.addImage(require('../../assets/images/wifi-icon.png'), "PNG", doc.internal.pageSize.width / 2 - 105 / 2, 45, 105, 105)
        doc.setFontSize(35)
        doc.setFont('Roboto-Bold', 'bold');
        doc.text("Join our free public", doc.internal.pageSize.width / 2, 170, { align: 'center' });

        doc.setFontSize(80)
        doc.setFont('Roboto-Bold', 'bold');
        doc.text("Wi-Fi", doc.internal.pageSize.width / 2, 200, { align: 'center' });

        doc.setFillColor(255, 255, 255);
        doc.rect(0, 250, 210, 50, "F")

        const [r2, g2, b2] = hexToRGBNoO(poster.accentColor)
        doc.setFillColor(r2, g2, b2);
        doc.lines([[74.76, 37.8, 134.4, -42, 210, 10], [0, -44.1], [-210, 0]], 8.4, 255, [1, 1], 'F', true)

        doc.setFillColor(r, g, b);
        doc.lines([[63, 42, 147, -42, 210, 0], [0, -42], [-210, 0]], 0, 250, [1, 1], 'F', true)

        doc.setFont('Roboto-Light', 'normal');
        doc.setTextColor('#000000')
        doc.setFontSize(12)
        doc.text(poster.message, 10, 275, { maxWidth: logo ? 130 : 200 });

        if (logo) {
            const ratio = logo.width / logo.height
            const width = 25 * ratio
            doc.addImage(logoUrl, logo.format.toUpperCase(), 145 + width / 2, 265, width, 25)
        }

        doc.save(`${poster.name}.pdf`);
    }

    return (
        <div className='create-poster'>
            <label className='subtitle'>Design - WI-FI Poster</label>
            <p className='description'>Use the form below to upload and select assets for your poster.</p>
            <div className='name-input'>
                <label>Name:</label>
                <input value={poster.name || ''} onChange={e => setPoster({ ...poster, name: e.target.value })} />
            </div>
            <FileSelector title='Select logo:' fileName={logoText} setFile={uploadLogo} />
            <ColorPicker title='Background Color:' value={poster.backgroundColor} setValue={value => setPoster({ ...poster, backgroundColor: value })} />
            <ColorPicker title='Accent Color:' value={poster.accentColor} setValue={value => setPoster({ ...poster, accentColor: value })} />
            <hr className='menu-line' />
            <label className='subtitle'>Design - Custom Message</label>
            <p className='description'>If you’d like your poster to have a custom message use this form to create one, it will appear in the bottom left of the poster. (Max 40 words)</p>
            <textarea value={poster.message || ''} onChange={e => changeMessage(e.target.value)} placeholder='Custom message here' />
            <div className='actions'>
                <button onClick={save}>Save</button>
                <button onClick={exportPdf}>Export PDF</button>
            </div>
        </div>
    )
}