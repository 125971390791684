import React, { useState, useEffect, useRef } from 'react'

import api from '../utils/api'
import { useHistory } from "react-router-dom";

import { useGoogleMaps } from "react-hook-google-maps";
import '../assets/css/map.scss'

export const Map = () => {
    const history = useHistory()

    const [positions, setPositions] = useState([])
    const [name, setName] = useState('')
    const prevMarkersRef = useRef([]);

    const { ref, map, google } = useGoogleMaps(process.env.REACT_APP_GOOGLE_MAPS_KEY,
        {
            center: { lat: 51.5074, lng: 0.1278 },
            zoom: 7
        },
    );

    const selectNetwork = (orgLocation) => {
        const { parentOrganisation } = orgLocation
        localStorage.setItem('selected-organisation', JSON.stringify({ externalId: parentOrganisation.externalId, name: parentOrganisation.name }))
        localStorage.setItem('admin-page', '/dashboard')
        localStorage.setItem('current-organisation', orgLocation.externalId)
        history.replace(`/dashboard`)
        window.location.reload();
    }

    const clearMarkers = (markers) => {
        for (let m of markers) {
            m.setMap(null);
        }
    }

    const createMarkers = (locations) => {
        if (map) {
            locations.forEach(p => {
                const content = `<label id="clickableItem ${p.externalId}">${p.name}<label>`
                const infowindow = new google.maps.InfoWindow({ content: content });
                const marker = new google.maps.Marker({ position: { lat: p.geo.lat, lng: p.geo.lon }, map, icon: { url: require('../assets/images/pin.svg'), scaledSize: new google.maps.Size(35, 35) } })
                prevMarkersRef.current.push(marker);
                google.maps.event.addListener(marker, 'click', () => { infowindow.open(map, marker) })
                google.maps.event.addListener(infowindow, 'domready', () => {
                    document.getElementById(`clickableItem ${p.externalId}`).addEventListener("click", onClick);
                    function onClick() {
                        selectNetwork(p)
                    }
                });
            })
        }
    }

    createMarkers(positions)

    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(localStorage.getItem('user'))
            const result = await api.get(`/organisations/${user.organisations[0].externalId}/geo_location?relationship=VENUE&size=1000&name=${name}`)
            const results = result.data.content.filter(position => position.geo != null).filter(position => position.geo.lat != null);
            if (JSON.stringify(results) !== JSON.stringify(positions))
                setPositions(results)
        }
        fetchData()
    }, [name])

    useEffect(() => {
        clearMarkers(prevMarkersRef.current);
        createMarkers(positions)
    }, [positions])

    const filter = (e) => {
        if (e.target.value.length > 2)
            setName(e.target.value)
        else
            setName("")
    }

    return (
        <div className='map-container'>
            <div className='map-top'>
                <div className='title'>
                    <div>
                        <div className='logo'>
                            <img src={require("../assets/images/position-icon.svg")} />
                        </div>
                    </div>
                    <label>Our locations</label>
                </div>
                <div className='search'>
                    <input type='search' placeholder='Search locations...' onChange={e => filter(e)} />
                </div>
            </div>
            <div ref={ref} className='map' >
                <div
                    lat={51.5074}
                    lng={0.1278}
                    text="My Marker"
                />
            </div>
        </div>
    )
}