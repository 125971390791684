import React, { useState, useEffect } from 'react'

import uuid from 'react-uuid'
import api, { cloudinaryApi } from '../../utils/api'

import { useParams, useHistory, useLocation } from "react-router-dom";

import CreatePoster from './createPoster'
import PosterPreview from './posterPreview'

import { ReactComponent as DesignLogo } from '../../assets/images/design-icon.svg'

import '../../assets/css/poster/poster.scss'

export default ({ organisationId }) => {

    const history = useHistory()

    const { posterId } = useParams();

    const [posterLogoText, setPosterLogoText] = useState('')
    const [posterLogoUrl, setPosterLogoUrl] = useState('')
    const [logo, setLogo] = useState()
    const [poster, setPoster] = useState({ backgroundColor: '#E73E1C', name: 'Default' })

    const getSignature = async (type, folder, trackingKey) => {
        const result = await api.get(`/upload/sign?mediaGroupExternalId=${posterId}&mediaUsageType=${type}&folder=${folder}&trackingKey=${trackingKey}`)
        return result.data
    }

    const uploadToCloudinary = async (file, signature) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('api_key', signature['apiKey'])
        formData.append('type', 'upload')
        formData.append('folder', signature['uploadOptions']['folder'])
        formData.append('context', signature['context'])
        formData.append('timestamp', signature['timestamp'].toString())
        formData.append('signature', signature['signature'])

        const response = await cloudinaryApi.post(`/${signature.cloudName}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return response.data
    }

    const getFileName = (page, type) => {
        const creative = page.creatives.find(creative => creative.type === type)
        return creative ? creative.url.substr(creative.url.lastIndexOf('/')) : ''
    }

    const getLogo = (page, type) => {
        return page.creatives.find(creative => creative.type === type)
    }

    const getUrl = (page, type) => {
        const creative = page.creatives.find(creative => creative.type === type)
        return creative ? creative.url : null
    }

    const uploadFile = async (type, folder, file) => {
        const trackingKey = uuid()
        const signature = await getSignature(type, folder, trackingKey)
        return await uploadToCloudinary(file, signature)
    }

    const fetchPoster = async () => {
        const result = await api.get(`/organisations/${organisationId}/posters/${posterId}`)
        setPoster(result.data)
        setPosterLogoText(getFileName(result.data, 'POSTER_LOGO'))
        setPosterLogoUrl(getUrl(result.data, 'POSTER_LOGO'))
        setLogo(getLogo(result.data, 'POSTER_LOGO'))
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchPoster()
        }
        fetchData()
    }, [posterId])

    const savePoster = async () => {
        await api.put(`/organisations/${organisationId}/posters/${poster.externalId}`, poster)
        history.goBack()
    }

    return (
        <div className='poster-page'>
            <div className='pp-edit'>
                <div className='pp-edit-top'>
                    <div className='pp-edit-title'>
                        <div className='pp-edit-logo'>
                            <DesignLogo className='pp-edit-logo-svg' />
                        </div>
                        <label className='pp-title-text'>Editor</label>
                    </div>
                </div>
                <hr className='pp-top-line' />
                <CreatePoster
                    poster={poster}
                    setPoster={setPoster}
                    uploadFile={uploadFile}
                    logoText={posterLogoText}
                    logoUrl={posterLogoUrl}
                    setLogoText={setPosterLogoText}
                    setLogoUrl={setPosterLogoUrl}
                    save={savePoster}
                    logo={logo}
                />
            </div>
            <PosterPreview poster={poster} logoUrl={posterLogoUrl} />
        </div>
    )
}