import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";

import api from '../utils/api'
import { InfoPopup } from '../components/widget/infoPopup'
import { Dropdown } from '../components/widget/dropdown'

import { ReactComponent as PersonIcon } from '../assets/images/network-icon.svg';
import { ReactComponent as UnselectedBox } from '../assets/images/remember-me-box.svg';
import { ReactComponent as SelectedBox } from '../assets/images/remember-me-box-selected.svg';

import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

import '../assets/css/organisation.scss'

import 'react-google-places-autocomplete/dist/index.min.css';

export const Organisation = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const selectedOrganisation = JSON.parse(localStorage.getItem('selected-organisation'))
    const admin = user.organisations[0].orgType !== 'ORGANISATION' && !selectedOrganisation
    const organisationId = selectedOrganisation ? selectedOrganisation.externalId : user.organisations[0].externalId

    const [organisation, setOrganisation] = useState({})
    const [owner, setOwner] = useState({})
    const [location, setLocation] = useState({ isPrimary: true })
    const [errors, setErrors] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [showFillAllPopup, setShowFillAllPopup] = useState(false)
    const [addVenue, setAddVenue] = useState(false)
    const [addAccount, setAddAccount] = useState(admin)
    const [venueName, setVenueName] = useState()
    const [responseError, setResponseError] = useState()
    const [industries, setIndustries] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState()
    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get('/industries')
            setIndustries(data)
            setSelectedIndustry(data[0])
        }
        fetchData()
    }, [])

    const getComponent = (components, name) => {
        return components.find(c => c.types.includes(name)) || {}
    }

    const onSelectSuggestion = async (seggestion) => {
        const geocodes = await geocodeByPlaceId(seggestion.place_id)
        const geocode = geocodes[0]
        const latLng = await getLatLng(geocode)

        const address = {}
        // address.areaCode = getComponent(geocode.address_components, 'administrative_area_level_2').long_name
        const country = getComponent(geocode.address_components, 'country')
        address.country = country.long_name
        address.countryCode = country.short_name
        address.fullAddress = geocode.formatted_address
        address.fullAddressLine2 = ''
        address.googlePlaceId = geocode.place_id
        address.isBilling = true
        address.isPrimary = true
        address.lat = latLng.lat
        address.lon = latLng.lng
        address.name = geocode.address_components[0].short_name
        address.postcodeZipcode = getComponent(geocode.address_components, 'postal_code').long_name
        address.city = getComponent(geocode.address_components, 'postal_town').long_name

        setLocation(address)
    }

    const saveOrganisation = async () => {
        var e = []
        if (!organisation.name || organisation.name.length < 3)
            e = [...e, 'name']
        // if (!organisation.description || organisation.description.length < 3)
        //     e = [...e, 'description']
        if (!organisation.email || organisation.email.length < 3)
            e = [...e, 'organisationEmail']
        if (!organisation.phone || organisation.phone.length < 3)
            e = [...e, 'organisationPhone']

        if (addAccount) {
            if (!owner.forename || owner.forename.length < 3)
                e = [...e, 'forename']
            if (!owner.surname || owner.surname.length < 3)
                e = [...e, 'surname']
            if (!owner.mobilePhone || owner.mobilePhone.length < 3)
                e = [...e, 'mobilePhone']
            if (!owner.email || owner.email.length < 3)
                e = [...e, 'email']
            if (!owner.password || owner.password.length < 3)
                e = [...e, 'password']
        }

        if (!location.fullAddress || location.fullAddress.length < 3)
            e = [...e, 'fullAddress']
        if (!location.city || location.city.length < 3)
            e = [...e, 'city']
        if (!location.country || location.country.length < 3)
            e = [...e, 'country']
        if (!location.postcodeZipcode || location.postcodeZipcode.length < 3)
            e = [...e, 'postcodeZipcode']
        if (addVenue && (!venueName || venueName.length < 3))
            e = [...e, 'venueName']

        setErrors(e)

        if (e.length > 0) {
            setShowFillAllPopup(true)
            setTimeout(() => setShowFillAllPopup(false), 2000)
        }

        if (e.length === 0) {
            setErrors([])
            organisation.relationship = admin ? 'CUSTOMER' : 'VENUE'
            organisation.tenantExternalId = user.organisations[0].externalId
            organisation.orgType = 'ORGANISATION'
            organisation.industryExternalId = selectedIndustry.externalId

            if (addAccount)
                organisation.owner = owner
            organisation.location = location

            if (admin && addVenue) {
                const childOrganisation = { name: venueName, relationship: 'VENUE' }
                organisation.childOrganisation = childOrganisation
            }

            try {
                await api.post(`/organisations/${organisationId}/network`, organisation)
                setShowPopup(true)
                setTimeout(() => setShowPopup(false), 2000)

                setOrganisation({})
                setOwner({})
                setLocation({})
                history.goBack()
            } catch (e) {
                setResponseError(e.response.data.errorDescription)
                setTimeout(() => setResponseError(null), 4000)
            }
        }
    }

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const changeOrganisationName = (name) => {
        setErrors(errors.filter(error => error !== 'name'))
        setOrganisation({ ...organisation, name: name })
    }

    const changeOrganisationDescription = (value) => {
        setErrors(errors.filter(error => error !== 'description'))
        setOrganisation({ ...organisation, description: value })
    }

    const changeOrganisationEmail = (value) => {
        setErrors(errors.filter(error => error !== 'organisationEmail'))
        setOrganisation({ ...organisation, email: value })
    }

    const changeOrganisationPhone = (value) => {
        setErrors(errors.filter(error => error !== 'organisationPhone'))
        setOrganisation({ ...organisation, phone: value })
    }

    const changeOwnerForename = (value) => {
        setErrors(errors.filter(error => error !== 'forename'))
        setOwner({ ...owner, forename: capitalize(value) })
    }

    const changeOwnerSurname = (value) => {
        setErrors(errors.filter(error => error !== 'surname'))
        setOwner({ ...owner, surname: capitalize(value) })
    }

    const changeOwnerMobilePhone = (value) => {
        setErrors(errors.filter(error => error !== 'mobilePhone'))
        setOwner({ ...owner, mobilePhone: value })
    }

    const changeOwnerEmail = (value) => {
        setErrors(errors.filter(error => error !== 'email'))
        setOwner({ ...owner, email: value })
    }

    const changeOwnerPassword = (value) => {
        setErrors(errors.filter(error => error !== 'password'))
        setOwner({ ...owner, password: value })
    }

    const changeLocationFullAddress = (value) => {
        setErrors(errors.filter(error => error !== 'fullAddress'))
        setLocation({ ...location, fullAddress: value })
    }

    const changeLocationCity = (value) => {
        setErrors(errors.filter(error => error !== 'city'))
        setLocation({ ...location, city: value })
    }

    const changeLocationCountry = (value) => {
        setErrors(errors.filter(error => error !== 'country'))
        setLocation({ ...location, country: value })
    }

    const changeLocationPostcodeZipcode = (value) => {
        setErrors(errors.filter(error => error !== 'postcodeZipcode'))
        setLocation({ ...location, postcodeZipcode: value })
    }

    const changeVenueName = (value) => {
        setErrors(errors.filter(error => error !== 'venueName'))
        setVenueName(value)
    }

    return (
        <div className='organisation'>
            <div className='add-organisation'>
                <div className='add-organisation-top'>
                    <div className='add-organisation-logo'>
                        <PersonIcon className='add-organisation-logo-svg' />
                    </div>
                    <label>{`Add ${admin ? 'customer' : 'venue'}`}</label>
                </div>

                <div className='add-organisation-content'>
                    <div className='add-organisation-details'>
                        <label className='add-organisation-subtitle'>{`${admin ? 'Customer' : 'Venue'} details`}</label>
                        <div className='add-organisation-input'>
                            <label>{`${admin ? 'Business' : 'Venue'} Name`}<span>*</span></label>
                            <input placeholder={`Please enter ${admin ? 'business' : 'venue'} name`} className={`${errors.length > 0 && errors.includes('name') && 'input-error'}`} value={organisation.name || ''} onChange={e => changeOrganisationName(e.target.value)} />
                        </div>
                        {/* <div className='add-organisation-input'>
                            <label>Description</label>
                            <input placeholder='Please enter description' className={`${errors.length > 0 && errors.includes('description') && 'input-error'}`} value={organisation.description || ''} onChange={e => changeOrganisationDescription(e.target.value)} />
                        </div> */}
                        <div className='add-organisation-input'>
                            <label>{`${admin ? 'Customer' : 'Venue'} Email`}<span>*</span></label>
                            <input placeholder={`Please enter ${admin ? 'customer' : 'venue'} email`} className={`${errors.length > 0 && errors.includes('organisationEmail') && 'input-error'}`} value={organisation.email || ''} onChange={e => changeOrganisationEmail(e.target.value)} />
                        </div>
                        <div className='add-organisation-input'>
                            <label>{`${admin ? 'Customer' : 'Venue'} Phone`}<span>*</span></label>
                            <input placeholder={`Please enter ${admin ? 'customer' : 'venue'} phone number`} className={`${errors.length > 0 && errors.includes('organisationPhone') && 'input-error'}`} value={organisation.phone || ''} onChange={e => changeOrganisationPhone(e.target.value)} />
                        </div>
                        <div className='add-organisation-input'>
                            <label>Industry<span>*</span></label>
                            <Dropdown options={industries} selected={selectedIndustry} onSelect={setSelectedIndustry} />

                        </div>
                        <label className='add-organisation-subtitle'>Account details</label>

                        {!admin &&
                            < div className='checkbox-input'>
                                <label>Create account</label>
                                {addAccount ? <SelectedBox className='devices-select-icon' onClick={() => setAddAccount(false)} /> : <UnselectedBox className='devices-select-icon' onClick={() => setAddAccount(true)} />}
                            </div>
                        }
                        {addAccount &&
                            <div>
                                <div className='add-organisation-input'>
                                    <label>First Name<span>*</span></label>
                                    <input placeholder='Please enter first name' className={`${errors.length > 0 && errors.includes('forename') && 'input-error'}`} value={owner.forename || ''} onChange={e => changeOwnerForename(e.target.value)} />
                                </div>
                                <div className='add-organisation-input'>
                                    <label>Last Name<span>*</span></label>
                                    <input placeholder='Please enter last name' className={`${errors.length > 0 && errors.includes('surname') && 'input-error'}`} value={owner.surname || ''} onChange={e => changeOwnerSurname(e.target.value)} />
                                </div>
                                <div className='add-organisation-input'>
                                    <label>Phone Number<span>*</span></label>
                                    <input placeholder='Please enter phone number' className={`${errors.length > 0 && errors.includes('mobilePhone') && 'input-error'}`} value={owner.mobilePhone || ''} onChange={e => changeOwnerMobilePhone(e.target.value)} />
                                </div>
                                <div className='add-organisation-input'>
                                    <label>Email<span>*</span></label>
                                    <input placeholder='Please enter email' className={`${errors.length > 0 && errors.includes('email') && 'input-error'}`} value={owner.email || ''} onChange={e => changeOwnerEmail(e.target.value)} />
                                </div>
                                <div className='add-organisation-input'>
                                    <label>Password<span>*</span></label>
                                    <input placeholder='Please enter password' className={`${errors.length > 0 && errors.includes('password') && 'input-error'}`} type='password' value={owner.password || ''} onChange={e => changeOwnerPassword(e.target.value)} />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='add-organisation-details add-organisation-address'>
                        <label className='add-organisation-subtitle'>Address details</label>
                        <div className='add-organisation-input'>
                            <label>Search</label>
                            <GooglePlacesAutocomplete
                                placeholder='Search...'
                                autocompletionRequest={{

                                    componentRestrictions: {
                                        country: ['uk'],
                                    }
                                }}
                                onSelect={onSelectSuggestion}
                            />
                        </div>
                        <label className='add-organisation-subtitle'>Address</label>
                        <div className='add-organisation-input'>
                            <label>Line1<span>*</span></label>
                            <input placeholder='Please enter line1' className={`${errors.length > 0 && errors.includes('fullAddress') && 'input-error'}`} value={location.fullAddress || ''} onChange={e => changeLocationFullAddress(e.target.value)} />
                        </div>
                        <div className='add-organisation-input'>
                            <label>Line2</label>
                            <input placeholder='Please enter line2' value={location.fullAddressLine2 || ''} onChange={e => setLocation({ ...location, fullAddressLine2: e.target.value })} />
                        </div>
                        <div className='add-organisation-input'>
                            <label>City<span>*</span></label>
                            <input placeholder='Please enter city' className={`${errors.length > 0 && errors.includes('city') && 'input-error'}`} value={location.city || ''} onChange={e => changeLocationCity(e.target.value)} />
                        </div>
                        <div className='add-organisation-input'>
                            <label>Country<span>*</span></label>
                            <input placeholder='Please enter country' className={`${errors.length > 0 && errors.includes('country') && 'input-error'}`} value={location.country || ''} onChange={e => changeLocationCountry(e.target.value)} />
                        </div>
                        <div className='add-organisation-input'>
                            <label>Postcode<span>*</span></label>
                            <input placeholder='Please enter postcode' className={`${errors.length > 0 && errors.includes('postcodeZipcode') && 'input-error'}`} value={location.postcodeZipcode || ''} onChange={e => changeLocationPostcodeZipcode(e.target.value)} />
                        </div>
                        {admin &&
                            <div className='add-venue'>
                                <label className='add-organisation-subtitle'>Venue</label>
                                <div className='checkbox-input'>
                                    <label>Create venue with location details</label>
                                    {addVenue ? <SelectedBox className='devices-select-icon' onClick={() => setAddVenue(false)} /> : <UnselectedBox className='devices-select-icon' onClick={() => setAddVenue(true)} />}
                                </div>
                                {addVenue === true &&
                                    <div className='add-organisation-input'>
                                        <label>Venue Name<span>*</span></label>
                                        <input placeholder='Please enter venue name' className={`${errors.length > 0 && errors.includes('venueName') && 'input-error'}`} value={venueName || ''} onChange={e => changeVenueName(e.target.value)} />
                                    </div>
                                }
                            </div>
                        }

                    </div>
                </div>
                <button onClick={saveOrganisation}>{`Add new ${admin ? 'customer' : 'venue'}`}</button>
            </div>
            {showPopup && <InfoPopup text={`${admin ? 'Customer' : 'Venue'} created`} onOk={() => setShowPopup(false)} />}
            {showFillAllPopup && <InfoPopup text='Please fill all mandatory fields' onOk={() => setShowFillAllPopup(false)} />}
            {responseError && <InfoPopup text={responseError} onOk={() => setResponseError(null)} />}

        </div >
    )
}