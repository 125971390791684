import React, { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom";

import api from '../../utils/api'
import { ReactComponent as DesignLogo } from '../../assets/images/design-icon.svg'

import { EditLandingPageDesign } from './editLandingPageDesign'
import { EditLandingPageSettings } from './editLandingPageSettings'

import LoginScreen from './screens/loginScreen'
import WelcomeBackScreen from './screens/welcomeBackScreen'
import JoinScreen from './screens/joinScreen'
import ConsentScreen from './screens/consentScreen'
import EmailRegistrationScreen from './screens/emailRegistrationScreen'
import EmailPasswordScreen from './screens/emailPasswordScreen'

import ActionPopup from '../widget/actionPopup'
import BulletNavigator from '../widget/ bulletNavigator'


import '../../assets/css/design/edit-landing-page.scss'

export const EditLandingPage = () => {
    const history = useHistory()
    const location = useLocation()

    const create = location.state ? location.state.create : false
    
    const [editDesign, setEditDesign] = useState(true)
    const [settingsUsed, setSettingsUsed] = useState(!JSON.parse(create))

    const [logoText, setLogoText] = useState('')
    const [backgroundText, setBackgroundText] = useState()

    const [logoUrl, setLogoUrl] = useState('')
    const [backgroundUrl, setBackgroundUrl] = useState()

    const { landingPageId } = useParams();

    const [landingPage, setLandingPage] = useState({})
    const [settings, setSettings] = useState({})

    const [showPopup, setShowPopup] = useState(false)

    const previewScreensData = [
        {
            id: 0,
            name: 'login',
        },
        {
            id: 1,
            name: 'join'
        },
        {
            id: 2,
            name: 'welcome'
        },
        {
            id: 3,
            name: 'emailPassword'
        },
        {
            id: 4,
            name: 'consent'
        },
        {
            id: 5,
            name: 'emailRegistration'
        }
    ]

    const [previewScreens, setPreviewScreens] = useState(previewScreensData)

    const [current, setCurrent] = useState(previewScreens[0].id)

    const fetctLandingPage = async () => {
        const result = await api.get(`/landingPages/${landingPageId}`)
        setLandingPage(result.data)
        setSettings(result.data.landingpageSettings)
        setLogoText(getFileName(result.data, 'LOGO'))
        setLogoUrl(getUrl(result.data, 'LOGO'))
        setBackgroundText(getFileName(result.data, 'BACKGROUND'))
        setBackgroundUrl(getUrl(result.data, 'BACKGROUND'))
    }

    useEffect(() => {
        const fetchData = async () => {
            fetctLandingPage()
        }
        fetchData()
    }, [landingPageId])

    useEffect(() => {
        setPreviewScreens(previewScreensData.filter(screen => settings.requirePassword || screen.name !== 'emailPassword').filter(screen => settings.showTermsAndConditions || screen.name !== 'consent'))
    }, [settings])

    const getFileName = (page, type) => {
        const creative = page.creatives.find(creative => creative.type === type)
        return creative ? creative.url.substr(creative.url.lastIndexOf('/')) : ''
    }

    const getUrl = (page, type) => {
        const creative = page.creatives.find(creative => creative.type === type)
        return creative ? creative.url : null
    }

    const changeToSettings = () => {
        setSettingsUsed(true)
        setEditDesign(false)
    }

    const saveLandingPage = async () => {
        if (!settingsUsed)
            setShowPopup(true)
        else {
            landingPage.landingpageSettings = settings
            await api.put(`/landingPages/${landingPage.externalId}`, landingPage)
            history.goBack()
        }
    }


    const onNo = () => {
        setShowPopup(false)
        setSettingsUsed(true)
    }

    const onYes = () => {
        setShowPopup(false)
        setEditDesign(false)
        setSettingsUsed(true)
    }

    const getScreen = () => {
        switch (current) {
            case 0: return <LoginScreen landingPage={landingPage} logoUrl={logoUrl} />
            case 1: return <JoinScreen landingPage={landingPage} logoUrl={logoUrl} />
            case 2: return <WelcomeBackScreen landingPage={landingPage} logoUrl={logoUrl} />
            case 3: return <EmailPasswordScreen landingPage={landingPage} logoUrl={logoUrl} />
            case 4: return <ConsentScreen landingPage={landingPage}/>
            case 5: return <EmailRegistrationScreen landingPage={landingPage} settings={settings} logo={logoUrl} />
        }
    }

    return (
        <div className='landing-page-edit'>
            <div className='edit'>
                <div className='landing-page-edit-top'>
                    <div className='landing-page-edit-title'>
                        <div className='landing-page-edit-logo'>
                            <DesignLogo className='landing-page-edit-logo-svg' />
                        </div>
                        <label className='landing-page-edit-title-text'>{editDesign ? 'Editor' : 'Settings'}</label>
                    </div>

                    <div className='landing-page-edit-actions'>
                        <label onClick={() => setEditDesign(true)} className={`landing-page-edit-top-select ${editDesign && 'landing-page-edit-top-select-selected'}`}>Design</label>
                        <label onClick={changeToSettings} className={`landing-page-edit-top-select ${!editDesign && 'landing-page-edit-top-select-selected'}`}>Settings</label>
                    </div>
                </div>
                <hr className='edit-landing-page-top-line' />
                {editDesign ?

                    <EditLandingPageDesign
                        landingPage={landingPage}
                        setLandingPage={setLandingPage}
                        logoText={logoText}
                        setLogoText={setLogoText}
                        setLogoUrl={setLogoUrl}
                        backgroundText={backgroundText}
                        setBackgroundText={setBackgroundText}
                        setBackgroundUrl={setBackgroundUrl}
                        settingsUsed={settingsUsed}
                        setEditDesign={setEditDesign}
                        save={saveLandingPage}
                    />
                    :

                    <EditLandingPageSettings
                        settings={settings}
                        setSettings={setSettings}
                        save={saveLandingPage}
                    />

                }
            </div >

            <div className='preview'>
                <div className='preview-content'>
                    <div className='preview-navigator'>
                        <div className='preview-bullet-navigator'>
                            <BulletNavigator screens={previewScreens.map(ps => ps.id)} current={current} setCurrent={setCurrent} />
                        </div>
                    </div>
                    <div className='phone-preview'>

                        <img src={require('../../assets/images/preview.png')} />

                        <div className='phone-preview-box' style={{ backgroundImage: `url(${backgroundUrl}` }}>
                            {getScreen()}
                        </div>
                    </div>
                </div>
                {showPopup && <ActionPopup onNo={onNo} onYes={onYes} text='Do you want to change something in settings tab?' />}
            </div>
        </div >
    )
}