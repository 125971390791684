import React, { useState, useEffect } from "react";

import api from '../utils/api'

import { NetworkItem } from '../components/networkItem'
import { useHistory } from "react-router-dom";
import { PageNavigator } from '../components/pageNavigator'
import { PageNavigationContext } from '../context/navigationContext'
import DeletePopup from '../components/buttonedPopup'
import EportPopup from '../components/exportPopup'
import SuccessPopup from '../components/successPopup'
import FileSaver from 'file-saver';

import '../assets/css/network.scss'

export const Network = ({ setShowPopup }) => {
    const history = useHistory()

    const [networks, setNetworks] = useState([])
    const [name, setName] = useState('')
    const [navigation, setNavigation] = useState({ total: 1, current: 0 })
    const [deleteCustomer, setDeleteCustomer] = useState()
    const [showExportPopup, setShowExportPopup] = useState(false)
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)
    const [industries, setIndustries] = useState([])

    const fetchNetworks = async (page) => {
        const user = JSON.parse(localStorage.getItem('user'))
        const result = await api.get(`/organisations/${user.organisations[0].externalId}/network?relationship=CUSTOMER&relationship=VENUE&page=${page}&size=20&name=${name}`)
        setNetworks(result.data.content)
        setNavigation({ total: Math.ceil(result.data.total / 20), current: result.data.pageable.page })
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchNetworks(0)
        }
        fetchData()
    }, [name])

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get('/industries')
            setIndustries(data)
        }
        fetchData()
    }, [])

    const selectNetwork = (network) => {
        localStorage.setItem('selected-organisation', JSON.stringify(network))
        localStorage.setItem('admin-page', '/network')

        history.replace(`/dashboard`)
        window.location.reload();
    }

    const filter = (e) => {
        if (e.target.value.length > 2)
            setName(e.target.value)
        else
            setName("")
    }

    const deleteNetwork = async () => {
        await api.delete(`/organisations/${deleteCustomer.externalId}`)
        setNetworks(networks.filter(n => n.externalId !== deleteCustomer.externalId))
        setDeleteCustomer(null)
    }

    const exportCustomers = async fields => {
        const { data } = await api.post(`/organisations/export/csv`, { fields: fields.includes('BUSINESS_NAME') ? [fields[0], 'INDUSTRY', ...fields.slice(1)] : fields, industries: industries.filter(i => i.selected).map(i => i.externalId) })
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const today = new Date()
        FileSaver.saveAs(csvData, `Unaro Dashboard Export ${today.getDate()}/${today.getMonth()}/${today.getFullYear()}.csv`);
        setShowExportPopup(false)
        setShowSuccessPopup(true)
        setIndustries(industries.map(i => ({ ...i, selected: false })))
    }

    const closeExport = () => {
        setShowExportPopup(false)
        setIndustries(industries.map(i => ({ ...i, selected: false })))
    }

    return (
        <div className='network-page'>
            <label className='network-title'>Network</label>
            <div className='actions'>
                <input type='search' className='search' placeholder='Search...' onChange={e => filter(e)} />
                <div className='navigation'>
                    <PageNavigationContext.Provider value={{ navigation, setNavigation }}>
                        <PageNavigator changePage={(page) => fetchNetworks(page)} />
                    </PageNavigationContext.Provider>
                </div>
                <div className='right'>
                    <button className='export-btn' onClick={() => setShowExportPopup(true)}>Export</button>
                    <button className='add-btn' onClick={() => history.push('/organisation')}></button>
                </div>
            </div>
            <div className='network-grid'>
                {networks.map(network =>
                    <div key={network.externalId} className='network-item'>
                        <NetworkItem
                            network={network}
                            setShowPopup={setShowPopup}
                            setNetwork={updated => setNetworks(networks.map(n => n.externalId === updated.externalId ? updated : n))}
                            selectNetwork={selectNetwork}
                            deleteNetwork={customer => setDeleteCustomer(customer)} />
                    </div>
                )}
            </div>
            {deleteCustomer && <DeletePopup title='Delete customer' text='Are you sure you want to delete?' close={() => setDeleteCustomer(null)} action={deleteNetwork} actionText='Delete' />}
            {showExportPopup && <EportPopup close={closeExport} industries={industries} setIndustries={setIndustries} exportCustomers={exportCustomers} />}
            {showSuccessPopup && <SuccessPopup message='Export Successful!' close={() => setShowSuccessPopup(false)} />}
        </div>
    )
}