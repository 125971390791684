import React, { useContext } from 'react'

import { NavigationNumber } from './navigationNumber'
import { PageNavigationContext } from '../context/navigationContext'

import '../assets/css/page-navigator.scss'

export const PageNavigator = ({ changePage }) => {

    const { navigation, setNavigation } = useContext(PageNavigationContext)

    const onNavigation = (page) => {
        if (page < navigation.total && page >= 0)
            changePage(page)
    }

    const getNumberNavigationButtons = () => {
        var total = navigation.total
        var buttons = []
        if (total > 5) {
            const current = navigation.current
            if (current < 3)
                buttons = [...Array(5).keys()]
            else if (current + 4 > total)
                buttons = [total - 5, total - 4, total - 3, total - 2, total - 1]
            else {
                buttons = [current - 2, current - 1, current, current + 1, current + 2]
            }
        } else
            buttons = [...Array(total).keys()]

        return buttons.map(nr => ({ id: nr, text: nr + 1, selected: nr === navigation.current }));
    }

    return (
        <div className='page-navigator'>
            <button onClick={() => onNavigation(navigation.current - 1)}>PREV</button>
            <div className='number-navigation'>
                {getNumberNavigationButtons().map(number => <NavigationNumber key={number.id} navigator={number} onClick={onNavigation} />)}
            </div>
            <button onClick={() => onNavigation(navigation.current + 1)}>NEXT</button>
        </div>
    )
}