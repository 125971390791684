import React, { useState } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import { LandingPages } from '../components/design/landingPages'
import { EditLandingPage } from '../components/design/editLandingPage'
import EditTrackAndTracePage from '../components/design/track/editTrackAndTracePage'
import { BackButton } from '../components/widget/backButton'
import Poster from '../components/poster/poster'
import api from '../utils/api'
import Popup from '../components/widget/popup'
import { defaultLandingPage } from '../utils/defaults'

import { ReactComponent as DesignLogo } from '../assets/images/design-icon.svg';
import { ReactComponent as AddIcon } from '../assets/images/add-icon.svg';

import '../assets/css/design.scss'

export const Design = ({ organisationId }) => {

    const match = useRouteMatch()
    const history = useHistory()
    const [popupType, setPopupType] = useState()

    const createProject = async (landingPageId, landingPageName) => {
        const project = {}
        project.name = landingPageName
        project.externalId = landingPageId
        project.description = ''

        await api.post('/media', project)
    }

    const saveLandingPage = async (name) => {
        const type = popupType
        setPopupType()

        if (type === 'poster') {
            const poster = { name: name, backgroundColor: '#E73E1C', accentColor: '#F29739', message: 'We take pride in providing excellent customer service and would love to hear what you think and if we can improve on any of our services. Please let us know your opinions by clicking the link in the email you receive from us, or by leaving a Google or Trustpilot review.' }
            const response = await api.post(`/organisations/${organisationId}/posters`, poster)
            createProject(response.data.externalId, name)
            history.push({ pathname: `${match.path}/poster/${response.data.externalId}`, state: { create: true } })
        } else {
            defaultLandingPage.name = name
            defaultLandingPage.track = type === 'track'
            if (type === 'track') {
                defaultLandingPage.boxBorderVisibility = 'HIDE'
                defaultLandingPage.menuOpacity = 0
                defaultLandingPage.buttonColor = '#FECC33'
                defaultLandingPage.goButtonColor = '#FF9A22'
                defaultLandingPage.linkColor = '#000000'
                defaultLandingPage.textColor = '#e85558'
            } else {
                defaultLandingPage.landingpageSettings = { showTermsAndConditions: true }
            }
            const response = await api.post(`/organisations/${organisationId}/landingPages`, defaultLandingPage)
            createProject(response.data.externalId, name)
            history.push({ pathname: `${match.path}/${type}/${response.data.externalId}`, state: { create: true } })
        }
    }

    return (
        <div className='design-page'>
            <BackButton />
            <label className='design-title'>Design - Poster</label>
            {popupType && popupType != 'poster' &&
                <p className='design-description'>The Landing Page Designer enables you to create an unlimited amount of WiFi landing pages for your service. Existing landing pages are shown below and are assigned to a venue in the Venue Landing Page tab. To add a new landing page click the button at the bottom of the screen.</p>
            }
            <Switch>
                <Route path={`${match.path}/wifi/:landingPageId`}>
                    <EditLandingPage />
                </Route>
                <Route path={`${match.path}/wifi`}>
                    <LandingPages organisationId={organisationId} type={'wifi'} setPopupType={setPopupType} />
                </Route>
                <Route path={`${match.path}/track/:landingPageId`}>
                    <EditTrackAndTracePage />
                </Route>
                <Route path={`${match.path}/track`}>
                    <LandingPages organisationId={organisationId} type={'track'} setPopupType={setPopupType} />
                </Route>
                <Route path={`${match.path}/poster/:posterId`}>
                    <Poster organisationId={organisationId} />
                </Route>
                <Route path={`${match.path}/poster`}>
                    <LandingPages organisationId={organisationId} type={'poster'} setPopupType={setPopupType} />
                </Route>
                <Route path={match.path}>
                    <div className='design-page-editor'>
                        <div className='design-page-editor-top'>
                            <div className='design-page-editor-logo'>
                                <DesignLogo className='design-page-editor-logo-svg' />
                            </div>
                            <label className='design-page-editor-title'>Manage landing page design</label>
                        </div>
                        <div className='design-page-editor-types'>
                            <div className='design-page-editor-type'>
                                <div className='design-page-editor-type-top'>
                                    <img src={require('../assets/images/wifi-login.png')} />
                                </div>
                                <div className='design-page-editor-type-bottom'>
                                    <label className='design-page-editor-type-title'>Wifi Login Page</label>
                                    <div className='design-page-editor-type-buttons'>
                                        <div className='design-page-editor-type-button' onClick={() => setPopupType('wifi')}>
                                            <AddIcon />
                                            <label>Create New</label>
                                        </div>
                                        <div className='design-page-editor-type-button existing' onClick={() => history.push(`${match.path}/wifi`)}>
                                            <label>Manage Existing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='design-page-editor-type'>
                                <div className='design-page-editor-type-top tt'>
                                    <img src={require('../assets/images/track-and-trace.png')} />
                                </div>
                                <div className='design-page-editor-type-bottom'>
                                    <label className='design-page-editor-type-title'>Track & Trace Page</label>
                                    <div className='design-page-editor-type-buttons'>
                                        <div className='design-page-editor-type-button' onClick={() => setPopupType('track')}>
                                            <AddIcon />
                                            <label>Create New</label>
                                        </div>
                                        <div className='design-page-editor-type-button existing' onClick={() => history.push(`${match.path}/track`)}>
                                            <label>Manage Existing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='design-page-editor-type'>
                                <div className='design-page-editor-type-top wifi'>
                                    <img src={require('../assets/images/wifi-poster.png')} />
                                </div>
                                <div className='design-page-editor-type-bottom'>
                                    <label className='design-page-editor-type-title'>Wi-Fi Poster</label>
                                    <div className='design-page-editor-type-buttons'>
                                        <div className='design-page-editor-type-button' onClick={() => setPopupType('poster')}>
                                            <AddIcon />
                                            <label>Create New</label>
                                        </div>
                                        <div className='design-page-editor-type-button existing' onClick={() => history.push(`${match.path}/poster`)}>
                                            <label>Manage Existing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Route>
            </Switch>
            {popupType && <Popup onCancel={() => setPopupType()} onCreate={saveLandingPage} type={popupType} />}
        </div >
    )
}