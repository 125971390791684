import React from 'react'

import '../../assets/css/design/edit-landing-page-settings.scss'

import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import { TextSwitch } from '../widget/textSwitch';
import { ButtonedInput } from '../widget/buttonedInput'

const RemovableLabel = ({ text, onRemove }) => {
    return (
        <div className='removable-label'>
            <label >{text}</label>
            <DeleteIcon className='remove-icon' onClick={onRemove} />
        </div>
    )
}

export const EditLandingPageSettings = ({ settings, setSettings, save }) => {

    const admin = JSON.parse(localStorage.getItem('user')).organisations[0].orgType !== 'ORGANISATION'

    const addSsid = (value) => {
        if (!settings.ssids)
            settings.ssids = []
        settings.ssids = [...settings.ssids, { ssid: value }]
        setSettings({ ...settings })
    }

    const removeSsid = (value) => {
        settings.ssids = settings.ssids.filter(ssid => ssid !== value)
        setSettings({ ...settings })
    }

    const addEmail = (value) => {
        if (!settings.emailWhitelists)
            settings.emailWhitelists = []
        settings.emailWhitelists = [...settings.emailWhitelists, { emailDomain: value }]
        setSettings({ ...settings })
    }

    const removeEmail = (value) => {
        settings.emailWhitelists = settings.emailWhitelists.filter(email => email !== value)
        setSettings({ ...settings })
    }

    const changeFbLikesEnabled = () => {
        setSettings({ ...settings, facebookLikes: !settings.facebookLikes, facebookUrl: null })
    }

    const changeDebug = () => {
        setSettings({ ...settings, debug: !settings.debug })
    }

    return (
        <div className='edit-landing-page-settings'>
            <div className='edit-landing-page-settings-top'>
                <div className='edit-landing-page-settings-switch'>
                    <TextSwitch title='Company Name:' value={settings.requireCompanyName ? 'On' : 'Off'} values={['On', 'Off']} setValue={value => setSettings({ ...settings, requireCompanyName: value === 'On' })} />
                    <TextSwitch title='DOB:' value={settings.requireDob ? 'On' : 'Off'} values={['On', 'Off']} setValue={value => setSettings({ ...settings, requireDob: value === 'On' })} />
                    <TextSwitch title='Postcode:' value={settings.requireZipPostcode ? 'On' : 'Off'} values={['On', 'Off']} setValue={value => setSettings({ ...settings, requireZipPostcode: value === 'On' })} />
                </div>
                <div className='edit-landing-page-settings-switch'>
                    <TextSwitch title='Gender:' value={settings.requireGender ? 'On' : 'Off'} values={['On', 'Off']} setValue={value => setSettings({ ...settings, requireGender: value === 'On' })} />
                    <TextSwitch title='Phone Number:' value={settings.requireMobile ? 'On' : 'Off'} values={['On', 'Off']} setValue={value => setSettings({ ...settings, requireMobile: value === 'On' })} />
                    <TextSwitch title='Require Password:' value={settings.requirePassword ? 'On' : 'Off'} values={['On', 'Off']} setValue={value => setSettings({ ...settings, requirePassword: value === 'On' })} />
                </div>
                <div className='edit-landing-page-settings-switch'>
                    <TextSwitch title='Terms and conditions:' value={settings.showTermsAndConditions ? 'On' : 'Off'} values={['On', 'Off']} setValue={value => setSettings({ ...settings, showTermsAndConditions: value === 'On' })} />
                </div>
                <div className='redirect-input'>
                    <label>Redirect url:</label>
                    <input placeholder='Enter redirect url' value={settings.redirectUrl || ''} onChange={e => setSettings({ ...settings, redirectUrl: e.target.value })} />
                </div>
                <div className='edit-landing-page-settings-inputs'>
                    <div className='edit-landing-page-settings-ssid'>
                        <ButtonedInput title='SSID' placeholder='Enter SSID' save={value => addSsid(value)} />
                        {settings.ssids && settings.ssids.map((ssid, i) => <RemovableLabel key={i} text={ssid.ssid} onRemove={() => removeSsid(ssid)} />)}
                    </div>
                    <div className='edit-landing-page-settings-email'>
                        <ButtonedInput title='Email domains' placeholder='Enter email' value='activedorset.net' save={vaue => addEmail(vaue)} />
                        {settings.emailWhitelists && settings.emailWhitelists.map((email, i) => <RemovableLabel key={i} text={email.emailDomain} onRemove={() => removeEmail(email)} />)}
                    </div>
                </div>
                <hr className='edit-landing-page-settings-line' />
                <div className='facebook-like'>
                    <div className='fb-likes-switch'>
                        <label>Enabled Facebook Likes</label>
                        <img className='switch' src={require(`../../assets/images/switch-${settings.facebookLikes ? 'on' : 'off'}.svg`)} onClick={changeFbLikesEnabled} />
                    </div>
                    {settings.facebookLikes &&
                        <div className='fb-url-input'>
                            <label>Facebook Page URL</label>
                            <input value={settings.facebookUrl || ''} onChange={e => setSettings({ ...settings, facebookUrl: e.target.value })} />
                        </div>
                    }
                </div>

                {admin &&
                    <div className='debug-switch'>
                        <label>Debug</label>
                        <img className='switch' src={require(`../../assets/images/switch-${settings.debug ? 'on' : 'off'}.svg`)} onClick={changeDebug} />
                    </div>
                }
            </div>


            <div className='edit-landing-page-settings-bottom'>

                <hr className='edit-landing-page-settings-line' />
                <button className='edit-landing-page-save-btn' onClick={save}>Save changes</button>
            </div>
        </div>
    )
}
