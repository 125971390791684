import React, { useState } from 'react'

import { CustomerItem } from './customerItem'
import { PageNavigator } from '../../components/pageNavigator'
import { PageNavigationContext } from '../../context/navigationContext'
import Filter from '../../components/widget/filter'
import { BackButton } from '../widget/backButton'
import AddCustomersPopup from '../design/track/addCustomerPopup'
import api from '../../utils/api'
import { useHistory } from "react-router-dom";

import { ReactComponent as CalendarIcon } from '../../assets/images/date-icon.svg';
import { ReactComponent as AddIcon } from '../../assets/images/add-icon.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/alert-icon.svg';

import Calendar from 'react-calendar';

import '../../assets/css/customers/show-customers.scss'
import 'react-calendar/dist/Calendar.css';

export const ShowCustomers = ({ customers, fetctCustomers, filters, setFilters, setName, navigation, setNavigation, selectedDate, setSelectedDate, track, organisationId, goToAlert }) => {

    const history = useHistory();

    const [showCalendar, setShowCalendar] = useState(false)
    const [showAddCustomers, setShowAddCustomers] = useState(false)
    const [trackLandingPage, setTrackLandingPage] = useState({})

    const changeSelected = (id) => {
        if (id < 2) {
            const changed = filters.find(filter => filter.id === id)
            localStorage.setItem('l-filter', changed.selected ? '' : changed.type)
            setFilters([...filters.map(x => {
                if (x.id === id)
                    return { ...x, selected: !x.selected }
                else if (x.id > 1)
                    return x
                else
                    return { ...x, selected: false }
            })])
        } else {
            localStorage.setItem('r-filter', filters.find(filter => filter.id === id).type)

            setFilters([...filters.map(x => {
                if (x.id === id)
                    return { ...x, selected: true }
                else if (x.id === 0 || x.id === 1)
                    return x
                else
                    return { ...x, selected: false }
            })])
        }
    }

    const filter = (e) => {
        if (e.target.value.length > 2)
            setName(e.target.value)
        else
            setName("")
    }

    const onBlur = event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowCalendar(false)
        }
    }

    const onDateChange = date => {
        date.setMilliseconds(0)
        date.setSeconds(0)
        date.setMinutes(0)
        date.setHours(0)
        if (selectedDate && date.getTime() === selectedDate.getTime())
            setSelectedDate(null)
        else
            setSelectedDate(date)
        setShowCalendar(false)
    }

    const showAddCustomersPopup = async () => {
        const { data: landingPages } = await api.get(`/organisations/${organisationId}/landingPages?track=${track === true}`)
        if (landingPages.length > 0) {
            const { data: landingPage } = await api.get(`/landingPages/${landingPages[0].externalId}`)
            setTrackLandingPage(landingPage)
        }
        setShowAddCustomers(true)
    }

    return (
        <div className='show-customers-page'>
            <div className='actions'>
                <div className='actions-top-left'>
                    <BackButton />
                    <input type='search' className='search' placeholder='Search...' onChange={e => filter(e)} />
                </div>
                <div className='navigation'>
                    <PageNavigationContext.Provider value={{ navigation, setNavigation }}>
                        <PageNavigator changePage={(page) => fetctCustomers(page)} />
                    </PageNavigationContext.Provider>
                </div>
                <div className='action-top-right'>
                    {track && <div className='action-btn' onClick={() => setShowCalendar(true)} tabIndex="0" onBlur={onBlur}>
                        {showCalendar && <Calendar className='calendar' onChange={onDateChange} value={selectedDate} />}
                        <CalendarIcon className='action-btn-icon' />
                        <label>DATE</label>
                    </div>
                    }
                    {track && <div className='action-btn' onClick={showAddCustomersPopup}>
                        <AddIcon className='action-btn-icon' />
                        <label>ADD</label>
                    </div>
                    }
                    {filters &&
                        <div className='filters'>
                            {filters.map(filter => <Filter key={filter.id} filter={filter} Icon={filter.icon} changeSelected={changeSelected} />)}
                        </div>
                    }
                </div>
            </div>
            <div className='customer-grid'>
                {customers.map((customer, i) =>
                    <div key={i} className='customer-item'>
                        <CustomerItem customer={customer} />
                    </div>
                )}
            </div>

            {track && <div className='action-btn alert-btn' onClick={goToAlert}>
                <AlertIcon className='action-btn-icon' />
                <label>ALERT</label>
            </div>
            }
            {showAddCustomers && <AddCustomersPopup organisationId={organisationId} landingPage={trackLandingPage} close={() => setShowAddCustomers(false)} />}
        </div >
    )
}