import React, { useState } from 'react';
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner'

import '../../assets/css/billing/checkout-form.scss'

export default ({ close, save, secret, hideCancel = false }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [name, setName] = useState()
    const [country, setCountry] = useState({ name: 'United Kingdom', code: 'GB' })
    const [line1, setLine1] = useState()
    const [line2, setLine2] = useState()
    const [city, setCity] = useState()
    const [zip, setZip] = useState()
    const [error, setError] = useState()
    const [paymentInProgress, setPaymentInProgress] = useState(false)

    const createPaymentMethod = async (event) => {
        event.preventDefault();
        if (!name || name.length < 3)
            setError(true)
        else {
            setPaymentInProgress(true)
            const element = elements.getElement(CardElement)
            const response = await stripe.confirmCardSetup(secret, {
                payment_method: {
                    type: 'card',
                    card: element,
                    billing_details: { name: name, address: { country: country.code, line1: line1, line2: line2, city: city, postal_code: zip } },
                }
            })
            save(response)
        }
    };

    const changeName = e => {
        setError('')
        setName(e.target.value)
    }

    return (
        <div className='checkout-form'>
            <label>Cardholder Name</label>
            <input className={error ? 'input-error' : ''} value={name || ''} onChange={changeName} />
            <label>Card Details</label>
            <CardElement className='card-element' options={{ hidePostalCode: true }} />
            <label>Country</label>
            <input value={country.name} disabled='true' />
            <label>Address</label>
            <div className='cf-row-input'>
                <input placeholder='Address Line 1' value={line1 || ''} onChange={e => setLine1(e.target.value)} />
                <input placeholder='Address Line 2' value={line2 || ''} onChange={e => setLine2(e.target.value)} />
            </div>
            <label></label>
            <div className='cf-row-input'>
                <input placeholder='City' value={city || ''} onChange={e => setCity(e.target.value)} />
                <input placeholder='ZIP/Postal Code' value={zip || ''} onChange={e => setZip(e.target.value)} />
            </div>
            {paymentInProgress ?
                <div className='payment-loader'>
                    <Loader
                        type="Oval"
                        color="#EA5459"
                        height={30}
                        width={30}
                    />
                </div>

                :
                <div className='payment-actions'>
                    {!hideCancel && <button onClick={close}>Cancel</button>}
                    <button onClick={createPaymentMethod} disabled={!stripe}>Save</button>
                </div>
            }
        </div>
    );
};