import React from 'react'

import '../../assets/css/widget/switch-btn.scss'

export default ({ active, setActice }) => {
    return (
        <div className={`switch-btn ${active && 'active'}`} onClick={() => setActice(!active)}>
            <div className='switch' />
        </div>
    )
}