import React, { useState, useEffect } from 'react'

import api from '../../utils/api'
import AddPaymentMethodPopup from './addPaymentMethodPopup'
import Loader from 'react-loader-spinner'
import DeletePmPopup from './deletePmPopup'
import UpdatePopup from './updatePaymentMethodPopup'

import '../../assets/css/billing/payment-methods.scss'

export default ({ organisationId }) => {

    const [paymentMethods, setPaymentMethods] = useState([])
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false)
    const [loading, setLoading] = useState(false)
    const [secret, setSecret] = useState()
    const [removedPm, setRemovedPm] = useState()
    const [counter, setCounter] = useState(0)
    const [updatedPm, setUpdatedPm] = useState()

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const { data } = await api.get(`/organisation/${organisationId}/paymentmethods`)
            setPaymentMethods(data)
            setLoading(false)
        }
        fetchData()
    }, [organisationId, counter])

    const removePaymentMethod = async () => {
        setPaymentMethods(paymentMethods.filter(pm => pm.id !== removedPm.id))
        setRemovedPm(null)
        await api.delete(`/organisation/${organisationId}/paymentmethod/${removedPm.id}`)
    }

    const savePaymentMethod = (pm) => {
        setCounter(counter + 1)
        setShowAddPaymentMethod(false)
    }

    const addPm = async () => {
        const { data } = await api.post(`/organisation/${organisationId}/paymentmethods`)
        setSecret(data.token)
        setShowAddPaymentMethod(true)
    }

    const changeDefault = paymentMethodId => {
        api.put(`/organisation/${organisationId}/paymentmethod/${paymentMethodId}/default`)
        setPaymentMethods(paymentMethods.map(pm => ({ ...pm, isDefault: pm.id === paymentMethodId })))
    }

    const updatePm = async () => {
        api.put(`/organisation/${organisationId}/paymentmethod/${updatedPm.id}`, { name: updatedPm.name, address: updatedPm.address })
        setPaymentMethods(paymentMethods.map(pm => pm.id === updatedPm.id ? updatedPm : pm))
        setUpdatedPm(null)
    }

    return (
        <div className='payment-methods-page'>
            <button onClick={addPm}>Add payment method</button>
            <div className='header'>
                <label>TYPE</label>
                <label>CARD</label>
                <label>USER</label>
                <label>EXPIRY DATE</label>
                <label />
                <label />
            </div>
            {loading ?
                <div className='payment-methods-loader'>
                    <Loader
                        type="Oval"
                        color="#EA5459"
                        height={30}
                        width={30}
                    />
                </div>
                :
                <div className='payment-methods'>
                    {paymentMethods.map(paymentMethod =>
                        <div className='payment-method'>
                            <div className='pm-type'>
                                <img src={require(`../../assets/images/${paymentMethod.cardBrand === 'visa' ? 'visa' : 'mastercard'}.svg`)} />
                            </div>
                            <label>{paymentMethod.cardBrand}****{paymentMethod.last4Digits}</label>
                            <label>{paymentMethod.name}</label>
                            <label>{paymentMethod.expMonth}/{paymentMethod.expYear}</label>
                            <div className='default-pm'>
                                {paymentMethod.isDefault ? <label className='default-label'>Default</label>
                                    :
                                    <label className='default-label change-default' onClick={() => changeDefault(paymentMethod.id)}>Default</label>}
                            </div>
                            <div className='pm-actions'>
                                <img src={require('../../assets/images/edit.svg')} onClick={() => setUpdatedPm(paymentMethod)} />
                                <img src={require('../../assets/images/pm-delete-icon.svg')} onClick={() => setRemovedPm(paymentMethod)} />
                            </div>
                        </div>
                    )}
                </div>
            }
            {showAddPaymentMethod && secret && <AddPaymentMethodPopup close={() => setShowAddPaymentMethod(false)} savePaymentMethod={savePaymentMethod} secret={secret} />}
            {removedPm && <DeletePmPopup name={`${removedPm.cardBrand}****${removedPm.last4Digits}`} cancel={() => setRemovedPm(null)} remove={removePaymentMethod} />}
            {updatedPm && <UpdatePopup close={() => setUpdatedPm(null)} paymentMethod={updatedPm} setPaymentMethod={setUpdatedPm} savePaymentMethod={updatePm} />}
        </div>
    )
}