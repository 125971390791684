import React from 'react'

import UnaroIcon from '../unaroIcon'

import { hexToRGB } from '../../../utils/common'

import { SocialPreview } from '../../widget/socialPreview';
import { ReactComponent as LoginIcon } from '../../../assets/images/login.svg';

import '../../../assets/css/design/screens/email-password-screen.scss'


export default ({ landingPage, logoUrl }) => {
    return (
        <div className='email-password-screen' style={{ backgroundColor: !landingPage.boxSize || landingPage.boxSize === 'BIG' ? landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff' : hexToRGB('#FFFFFF', 0) }}>
            <div className='email-password-top'>
                <label style={{ color: landingPage.textColor, marginTop: '10px', textAlign: 'center' }} className='phone-preview-text'>{landingPage.welcomeText || ''}</label>
                <div className='email-password-screen-small-box' style={{
                    backgroundColor: landingPage.boxSize === 'SMALL' ? landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff' : hexToRGB('#FFFFFF', 0),
                    border: landingPage.boxSize === 'SMALL' && landingPage.boxBorderVisibility === 'SHOW' ? `1px solid ${landingPage.boxBorderColor}` : 'none'
                }}>
                    {logoUrl ? <img className='phone-preview-logo' src={logoUrl} /> : <div className='logo-placeholder' />}
                    <label style={{ color: landingPage.textColor, marginTop: '10px', textAlign: 'center' }}>{`Welcome back First name Last name, would you like to connect to ${landingPage.name}?`}</label>
                    <input placeholder='Email' />
                    <input placeholder='Password' />
                    <div className='phone-preview-social'>
                        <SocialPreview Icon={LoginIcon} color={landingPage.buttonColor || '#ffffff'} />
                    </div>
                </div>
            </div>

            <div className='powered-by'>
                <label style={{ color: landingPage.logoColor || '#000000', fontSize: '12px' }}>POWERED BY</label>
                <div className='powered-by-unaro-icon'>
                    <UnaroIcon color={landingPage.logoColor || '#000000'} />
                </div>
            </div>
        </div >
    )
}