import React, { useState } from 'react'

import { ReactComponent as DropdownLogo } from '../../assets/images/dropdown-icon.svg';

import '../../assets/css/widget/dropdown.scss'

export const Dropdown = ({ options = [], selected = '', onSelect, className }) => {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        if (options.length > 0)
            setOpen(!open)
    }

    const changeSelected = (option) => {
        setOpen(false)
        onSelect(option)
    }

    return (
        <div className={`dropdown-container ${className}`} tabIndex="0" onBlur={() => setOpen(false)}>
            <div className={`dropdown ${open && 'dropdown-open'}`} onClick={() => toggleOpen()}   >
                <label>{selected.name}</label>
                <DropdownLogo className='logo' />
            </div>
            {open &&
                <div className='options'>
                    {options.map(option => <label key={option.externalId} onClick={() => changeSelected(option)}>{option.name}</label>)}
                </div>
            }
        </div>
    )
}