import React from 'react'
import { useHistory } from "react-router-dom";

import { ReactComponent as DropdownLogo } from '../assets/images/dropdown-icon.svg';

import '../assets/css/menu-item.scss'

export const MenuItem = ({ item, onClick, smallMenu, onSubmenuClick, smallSidebar }) => {
    const history = useHistory();

    const onPress = () => {
        if (item.subitems && item.subitems.length > 0 && item.active ) {
            onSubmenuClick(item.id, item.subitems[0].id)
            history.push(item.subitems[0].redirect)
        } else if (item.active) {
            history.push(item.redirect)
            onClick()
        }
    }

    const onSubitemClick = subitem => {
        if (subitem.active) {
            history.push(subitem.redirect)
            onSubmenuClick(item.id, subitem.id)
        }
    }

    const getClass = () => {
        if (item.selected)
            return `menu-item-selected ${smallMenu && 'small-menu-item-selected'} ${!item.active && 'inactive-item'}`
        else
            return `menu-item ${smallMenu && 'small-menu-item'} ${!item.active && 'inactive-item'}`
    }

    return (
        <div className={getClass()}>
            <div className='item' onClick={() => onPress()}>
                <div className='left'>
                    <div>
                        <img src={item.active ? item.logo : item.inactiveLogo} className='item-logo' />
                    </div>
                    <label>{item.text}</label>
                </div>
                {item.subitems && item.subitems.length > 0 && <DropdownLogo className={`arrow-icon ${item.showSubitems && 'arrow-icon-down'}`} />}
            </div>
            {item.subitems && item.subitems.length > 0 && item.showSubitems && <div className='subitems'>
                {item.subitems.map(subitem =>
                    <div key={subitem.id} className={`subitem ${subitem.selected && 'subitem-selected'}`} onClick={() => onSubitemClick(subitem)}>
                        <div>
                            <subitem.Icon className={`subitem-logo ${subitem.selected && 'subitem-logo-selected'} 
                            ${!subitem.active && 'subitem-logo-inactive'} 
                            ${subitem.selected && subitem.text === 'Contacts' && 'subitem-logo-person'}  
                            ${subitem.selected && subitem.text === 'Reports' && 'subitem-logo-reports'}`} />
                        </div>
                        <label>{subitem.text}</label>
                    </div>
                )}
            </div>
            }
        </div>
    )
}