import React, { useState, useEffect } from 'react'

import { HashRouter, Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

import { Sidebar } from '../components/sidebar'
import { Dashboard } from './dashboard'
import { TopBar } from '../components/topBar'
import MainDashboard from './mainDashboard'

import { Network } from './network'
import Settings from './settings'
import { Design } from './design'
import { Customers } from './customers'
import Track from './track'
import Alert from './alert'
import { InviteUser } from './inviteUser'
import { Organisation } from './organisation';
import { Account } from './account'
import Integrations from './integrations'
import Venues from './venues'
import Billing from './billing'
import { Devices } from './devices'
import NotAvailable from '../components/notAvailable'
import CreateCampaign from '../components/sms/campaign/createCampaign'
import ManageCampaigns from '../components/sms/campaign/manageCampaigns'
import SmsReports from '../components/sms/reports/reports'
import SmsReport from '../components/sms/reports/report'

import Suspend from './suspend'

import CampaignDashbaord from '../components/sms/dashboard/dashboard'
import Contacts from '../components/sms/contact/contacts'
import Poster from '../components/poster/poster'

import { InfoPopup } from '../components/widget/infoPopup'

import { VenuesContext } from '../context/venueContext'

import api from '../utils/api'

import { ReactComponent as LandingPageIcon } from './../assets/images/landing-page-icon.svg';
import { ReactComponent as DashboatdIcon } from './../assets/images/dashboard-subitem-icon.svg';
import { ReactComponent as TrackAndTraceIcon } from './../assets/images/tt-icon.svg';
import { ReactComponent as CreateCampaignIcon } from './../assets/images/create-campaign-icon.svg';
import { ReactComponent as ManageCampaignIcon } from './../assets/images/manage-campaigns-icon.svg';
import { ReactComponent as ReportsIcon } from './../assets/images/reports-icon.svg';
import { ReactComponent as ContactsIcon } from './../assets/images/sms-contacts-icon.svg';

import '../assets/css/main.scss'

export const Main = () => {

    const history = useHistory()
    const location = useLocation()

    const user = JSON.parse(localStorage.getItem('user'))
    const selectedOrganisation = JSON.parse(localStorage.getItem('selected-organisation'))
    const adminUser = user.organisations[0].orgType !== 'ORGANISATION'

    const organisationId = user.organisations[0].externalId
    const [activeLicenses, setaAtiveLicenses] = useState([])
    const [selectedPath, setSelectedPath] = useState()
    const [licences, setLicences] = useState()
    const [editMode, setEditMode] = useState(false)

    const [menuItems, setMenuItems] = useState([
        {
            id: 0,
            logo: require('./../assets/images/dashboard-icon.svg'),
            inactiveLogo: require('./../assets/images/dashboard-icon-inactive.svg'),
            text: "Dashboard",
            redirect: "/dashboard",
            selected: location.pathname === "/dashboard",
            active: true,
            subitems: [],
            requireLicense: ''
        },
        {
            id: 1,
            logo: require('./../assets/images/customer-icon.svg'),
            inactiveLogo: require('./../assets/images/customer-icon-inactive.svg'),
            text: "Customers",
            redirect: "/customers",
            selected: location.pathname.startsWith("/customers"),
            active: true,
            subitems: [],
            requireLicense: ''
        },
        {
            id: 2,
            logo: require('./../assets/images/connect-icon.svg'),
            inactiveLogo: require('./../assets/images/connect-icon-inactive.svg'),
            text: "Connect",
            redirect: "/connect",
            selected: location.pathname.startsWith("/connect"),
            active: false,
            requireLicense: 'CONNECT',
            showSubitems: location.pathname.startsWith("/connect"),
            subitems: [
                {
                    id: 0,
                    Icon: DashboatdIcon,
                    text: "Dashboard",
                    redirect: "/connect/dashboard",
                    selected: location.pathname.startsWith("/connect/dashboard"),
                    active: false,
                    requireLicense: 'CONNECT',
                },
                {
                    id: 1,
                    Icon: LandingPageIcon,
                    text: "Landing page",
                    redirect: "/connect/design",
                    selected: location.pathname.startsWith("/connect/design"),
                    active: false,
                    requireLicense: 'CONNECT'
                },
                {
                    id: 2,
                    Icon: TrackAndTraceIcon,
                    text: "Track and trace",
                    redirect: "/connect/track",
                    selected: location.pathname.startsWith("/connect/track"),
                    active: false,
                    requireLicense: 'TT'
                }
            ]
        },
        {
            id: 3,
            logo: require('./../assets/images/sms-icon.svg'),
            inactiveLogo: require('./../assets/images/sms-icon-inactive.svg'),
            text: "SMS",
            redirect: "/sms",
            selected: location.pathname.startsWith("/sms"),
            active: false,
            requireLicense: 'SMS',
            showSubitems: location.pathname.startsWith("/sms"),
            subitems: [
                {
                    id: 0,
                    text: "Dashboard",
                    redirect: "/sms/dashboard",
                    selected: location.pathname.startsWith("/sms/dashboard"),
                    Icon: DashboatdIcon,
                    active: false,
                    requireLicense: 'SMS',
                },
                {
                    id: 1,
                    text: "Contacts",
                    redirect: "/sms/contacts",
                    selected: location.pathname.startsWith("/sms/contacts"),
                    Icon: ContactsIcon,
                    active: false,
                    requireLicense: 'SMS',
                },
                // {
                //     id: 3,
                //     text: "Manage campaigns",
                //     redirect: "/sms/campaigns/manage",
                //     selected: location.pathname.startsWith("/sms/campaigns/manage"),
                //     Icon: ManageCampaignIcon,
                //     active: activeLicenses.includes('SMS') || adminUser,
                // },
                {
                    id: 4,
                    text: "Reports",
                    redirect: "/sms/reports",
                    selected: location.pathname.startsWith("/sms/reports"),
                    Icon: ReportsIcon,
                    active: false,
                    requireLicense: 'SMS',
                }
            ]
        },
        {
            id: 4,
            logo: require('./../assets/images/send-icon.svg'),
            inactiveLogo: require('./../assets/images/send-icon-inactive.svg'),
            text: "Send",
            redirect: "/send",
            selected: location.pathname.startsWith("/send"),
            active: true,
            requireLicense: '',
            subitems: []
        },
        {
            id: 5,
            logo: require('./../assets/images/crm-icon.svg'),
            inactiveLogo: require('./../assets/images/crm-icon-inactive.svg'),
            text: "CRM",
            redirect: "/crm",
            selected: location.pathname.startsWith("/crm"),
            active: true,
            requireLicense: '',
            subitems: []
        },
        // {
        //     id: 6,
        //     logo: require('./../assets/images/marketing-icon.svg'),
        //     inactiveLogo: require('./../assets/images/marketing-icon-inactive.svg'),
        //     text: "Marketing",
        //     redirect: "/marketing",
        //     selected: location.pathname.startsWith("/marketing"),
        //     active: true,
        //     requireLicense: '',
        //     subitems: []
        // }
    ])

    const adminItems = [
        {
            id: 0,
            logo: require('./../assets/images/dashboard-icon.svg'),
            text: "Dashboard",
            redirect: "/dashboard",
            selected: location.pathname === "/dashboard",
            active: true,
            subitems: []
        },
        {
            id: 1,
            logo: require('./../assets/images/customer-icon.svg'),
            text: "Customers",
            redirect: "/network",
            selected: location.pathname === "/network",
            active: true,
            subitems: []
        }
    ]

    const admin = user.organisations[0].orgType !== 'ORGANISATION' && !selectedOrganisation
    const organisation = selectedOrganisation ? selectedOrganisation : user.organisations[0]
    const currentOrganisation = localStorage.getItem('current-organisation')
    const [organisations, setOrganisations] = useState([])
    const [selected, setSelected] = useState()

    const [showPopup, setShowPopup] = useState()

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`organisations/${organisationId}/licences`)
            setLicences(data)
        }
        fetchData()
    }, [organisationId])

    useEffect(() => {
        if (licences) {
            const currentDate = Math.floor(Date.now() / 1000)
            setaAtiveLicenses(licences.filter(license => license.startDate < currentDate && license.expiryDate > currentDate).map(license => license.licenceType))
        }
    }, [licences])

    useEffect(() => {
        setMenuItems([...menuItems.map(mi => activeLicenses.includes(mi.requireLicense) || adminUser ? ({ ...mi, active: true, subitems: mi.subitems.map(si => activeLicenses.includes(si.requireLicense) || adminUser ? ({ ...si, active: true }) : si) }) : ({ ...mi, subitems: mi.subitems.map(si => activeLicenses.includes(si.requireLicense) || adminUser ? ({ ...si, active: true }) : si) }))])
    }, [activeLicenses])

    const fetchOrganisations = async () => {
        const result = await api.get(`/organisations/${organisation.externalId}/network?relationship=VENUE&page=0size=20`)
        var current = undefined
        const venues = [organisation, ...result.data.content]
        if (admin) {
            current = organisation
        } else if (venues.length > 0) {
            current = venues.find(network => network.externalId === currentOrganisation)
            if (!current) {
                if (venues.length === 2)
                    current = venues[1]
                else
                    current = venues[0]
            }
        }
        setSelected(current)
        setOrganisations(venues)
    }

    const [locked, setLocked] = useState(JSON.parse(localStorage.getItem('sidebar-locked')))

    const changeLocked = () => {
        localStorage.setItem('sidebar-locked', !locked)
        setLocked(!locked)
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchOrganisations()
        }
        fetchData()
    }, [organisation.externalId])

    useEffect(() => {
        if (selectedPath)
            setMenuItems([...menuItems.map(x => (selectedPath.startsWith(x.redirect) ? { ...x, subitems: x.subitems.map(subitem => (subitem.redirect === selectedPath ? { ...subitem, selected: true } : { ...subitem, selected: false })), selected: true, showSubitems: true } : { ...x, subitems: x.subitems.map(subitem => ({ ...subitem, selected: false })), selected: false, showSubitems: false }))])

    }, [selectedPath])

    useEffect(() => {
        return history.listen(location => {

            if (history.action === 'POP') {
                setSelectedPath(location.pathname)
            }

            if (!location.pathname.startsWith('/customers')) {
                localStorage.removeItem('current-customers-page')
                localStorage.removeItem('l-filter')
                localStorage.removeItem('r-filter')
            }
        })
    }, [])

    const selectSubitem = (id, subId = '') => {
        setMenuItems([...menuItems.map(x => (x.id === id ? { ...x, subitems: x.subitems.map(subitem => (subitem.id === subId ? { ...subitem, selected: true } : { ...subitem, selected: false })), selected: true, showSubitems: true } : { ...x, subitems: x.subitems.map(subitem => ({ ...subitem, selected: false })), selected: false, showSubitems: false }))])
    }

    return (
        <div className={`main-page ${!locked && 'small-main'}`}>
            <Sidebar menuItems={admin ? adminItems : menuItems} selectSubitem={selectSubitem} setMenuItems={setMenuItems} locked={locked} changeLocked={changeLocked} />
            <VenuesContext.Provider value={{ organisations, setOrganisations, selected, locked, licences, setLicences }}>
                <TopBar className='top-bar' organisations={organisations} selected={selected} setSelected={setSelected} editMode={editMode} setEditMode={setEditMode} />
                <div className='main'>
                    <div className='content'>
                        <HashRouter>
                            <Switch >
                                <Route path='/dashboard'>
                                    {admin ?
                                        <Dashboard organisationId={selected ? selected.externalId : organisation.externalId} />
                                        :
                                        <MainDashboard organisationId={selected ? selected.externalId : organisation.externalId} edit={editMode} setEdit={setEditMode} />
                                    }
                                </Route>
                                {admin &&
                                    <Route path='/network' >
                                        <Network setShowPopup={setShowPopup} />
                                    </Route>
                                }
                                {!admin &&
                                    < Route path='/connect/design' >
                                        <Design organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }

                                {!admin &&

                                    <Route path='/customers' >
                                        <Customers organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }
                                {!admin &&

                                    <Route path='/devices' >
                                        <Devices organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }
                                {!admin && (activeLicenses.includes('TT') || adminUser) &&
                                    <Route path='/connect/track' >
                                        <Track organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }
                                {!admin && (activeLicenses.includes('TT') || adminUser) &&
                                    <Route path='/alert' >
                                        <Alert organisationId={selected ? selected.externalId : organisation.externalId} setShowPopup={setShowPopup} />
                                    </Route>
                                }
                                {!admin && (activeLicenses.includes('SMS') || adminUser) &&

                                    <Route path='/sms/dashboard' >
                                        <CampaignDashbaord />
                                    </Route>
                                }
                                {!admin && (activeLicenses.includes('SMS') || adminUser) &&
                                    <Route path='/sms/campaigns/manage' >
                                        <ManageCampaigns organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }
                                {!admin && (activeLicenses.includes('SMS') || adminUser) &&

                                    <Route path='/sms/campaigns/:campaignId' >
                                        <CreateCampaign organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }
                                {!admin &&

                                    <Route path='/sms/campaigns' >
                                        <CreateCampaign organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }
                                {!admin && (activeLicenses.includes('SMS') || adminUser) &&

                                    <Route path='/sms/reports/:campaignId' >
                                        <SmsReport />
                                    </Route>
                                }
                                {!admin && (activeLicenses.includes('SMS') || adminUser) &&

                                    <Route path='/sms/reports' >
                                        <SmsReports />
                                    </Route>
                                }
                                {!admin &&

                                    <Route path='/marketing' >
                                        <NotAvailable locked={locked} icon={require('../assets/images/marketing-icon.svg')} title='Unaro Marketing' text='Comming soon...' />
                                    </Route>
                                }
                                {!admin &&

                                    <Route path='/crm' >
                                        <NotAvailable locked={locked} icon={require('../assets/images/crm-icon.svg')} title='Unaro CRM' text='Comming soon...' />
                                    </Route>
                                }
                                {!admin &&

                                    <Route path='/send' >
                                        <NotAvailable locked={locked} icon={require('../assets/images/send-icon.svg')} title='Unaro Send' text='Comming soon...' />
                                    </Route>
                                }
                                {!admin &&

                                    <Route path='/sms/contacts' >
                                        <Contacts />
                                    </Route>
                                }
                                {!admin &&

                                    <Route path='/connect/dashboard'>
                                        <Dashboard organisationId={selected ? selected.externalId : organisation.externalId} />
                                    </Route>
                                }
                                {!admin &&
                                    <Route path='/connect/poster'>
                                        <Poster />
                                    </Route>
                                }
                                <Route path='/settings' >
                                    <Settings />
                                </Route>
                                <Route path='/organisation' >
                                    <Organisation />
                                </Route>
                                <Route path='/invite' >
                                    <InviteUser setShowPopup={setShowPopup} />
                                </Route>
                                <Route path='/integrations' >
                                    <Integrations organisationId={selected ? selected.externalId : organisation.externalId} />
                                </Route>
                                <Route path='/account' >
                                    <Account />
                                </Route>
                                <Route path='/venues' >
                                    <Venues />
                                </Route>
                                <Route path='/billing' >
                                    <Billing />
                                </Route>
                                <Route path='/suspended' >
                                    <Suspend />
                                </Route>
                                <Route render={() => <Redirect to="/dashboard" />} />
                            </Switch>
                        </HashRouter>
                    </div>
                    {showPopup && <InfoPopup text={showPopup} onOk={() => setShowPopup(false)} />}

                </div>
            </VenuesContext.Provider>
        </div >
    )
}