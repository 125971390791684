import React, { useState } from 'react'

import EditVenuePopup from './venue/editVenuePopup'
import UsersPopup from './venue/usersPopup'
import api from '../utils/api'

import { ReactComponent as DeleteIcon } from '../assets/images/org-delete.svg';
import { ReactComponent as DashboardIcon } from '../assets/images/org-dashboard.svg';
import { ReactComponent as SuspendIcon } from '../assets/images/org-suspend.svg';
import { ReactComponent as UsersIcon } from '../assets/images/users-icon.svg';
import { ReactComponent as EditIcon } from '../assets/images/edit-icon.svg';

import '../assets/css/network-item.scss'

export const NetworkItem = ({ network, setNetwork, selectNetwork, deleteNetwork, setShowPopup }) => {

    const [showEdit, setShowEdit] = useState(false)
    const [users, setUsers] = useState([])
    const [showUsers, setShowUsers] = useState(false)

    const getAddress = (network) => {
        if (network && network.location)
            return network.location.fullAddress
        return ''
    }

    const showAdminUsers = async () => {
        const { data } = await api.get(`/organisations/${network.externalId}/users`)
        setUsers(data)
        setShowUsers(true)
    }

    return (
        <div className='network-item'>

            <div className='network-item-top'>
                <label className='title'>{network.name}</label>
                <div className='network-item-actions'>
                    {network.isSuspended && <SuspendIcon className='network-item-action dashboard'/>}
                    <UsersIcon className='network-item-action users' onClick={showAdminUsers} />
                    <EditIcon className='network-item-action dashboard' onClick={() => setShowEdit(true)} />
                    <DeleteIcon className='network-item-action dashboard' onClick={() => deleteNetwork(network)} />
                    <DashboardIcon onClick={() => selectNetwork(network)} className='network-item-action dashboard' />
                </div>
            </div>
            <hr />

            <div className='network-item-bottom '>
                <div className='location-container'>
                    <img src={require("../assets/images/position-icon-grey.svg")} alt='' />
                    <label>{getAddress(network)}</label>
                </div>
                <label className='venue'>1 Venue</label>
            </div>
            {showEdit && <EditVenuePopup venue={network} setVenue={setNetwork} close={() => setShowEdit(false)} />}
            {showUsers && <UsersPopup organisationId={network.externalId} users={users} setUsers={setUsers} close={() => setShowUsers(false)} setShowPopup={setShowPopup} />}
        </div>
    )
}