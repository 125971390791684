import React, { useState } from 'react'

import Dropdown from './widget/multiSelectorDropdown'

import { ReactComponent as CloseIcon } from '../assets/images/delete.svg';

import '../assets/css/export-popup.scss'

export default ({ industries, setIndustries, close, exportCustomers }) => {

    const [options, setOptions] = useState([
        {
            id: 0,
            name: 'Business name',
            selected: true,
            type: 'BUSINESS_NAME'
        }, 
        {
            id: 3,
            name: 'First name',
            selected: true,
            type: 'USER_FIRST_NAME'
        }, {
            id: 4,
            name: 'Last name',
            selected: true,
            type: 'USER_LAST_NAME'
        }, {
            id: 5,
            name: 'Email',
            selected: true,
            type: 'USER_EMAIL'
        }, {
            id: 6,
            name: 'Phone number',
            selected: true,
            type: 'USER_PHONE'
        }
    ])

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    return (
        <div className='export-popup' onClick={onClick}>
            <div className='export-popup-content'>
                <div className='export-top'>
                    <label>Export Options</label>
                    <CloseIcon className='close-icon' onClick={close} />
                </div>
                {options.map((option, id) =>
                    <div className='export-option' key={id} onClick={() => setOptions(options.map(o => o.id === option.id ? ({ ...o, selected: !o.selected }) : o))}>
                        <div className='selector'>{option.selected && <div className='selected' />}</div>
                        <label>{option.name}</label>
                    </div>
                )}
                <Dropdown options={industries} className='industries-dd' placeholder='Industries'
                    onSelect={option => setIndustries(industries.map(ind => ind.externalId === option.externalId ? ({ ...ind, selected: !ind.selected }) : ind))} />
                <div className='export-actions'>
                    <button onClick={close}>Cancel</button>
                    <button onClick={() => exportCustomers(options.filter(o => o.selected).map(o => o.type))} >Export</button>
                </div>
            </div>

        </div>
    )
}