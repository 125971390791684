import React, { useState, useEffect } from 'react'

import Dropdown from './contactDropdown'
import { ReactComponent as AddIcon } from '../../../assets/images/add-contact-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/remove.svg';
import Loader from 'react-loader-spinner'
import api from '../../../utils/api'

import '../../../assets/css/sms/contact/import-contacts.scss'


export default ({ close, tags, inputFile, file, organisationId }) => {

    const [importTagsType, setImportTagsType] = useState('not')
    const [action, setAction] = useState('add')
    const [selectedTag, setSelectedTag] = useState(tags[0])
    const [selectedFile, setSelectedFile] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [dragAndDrop, setDragAndDrop] = useState(false)
    const [showColumns, setShowColumns] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [done, setDone] = useState(false)
    const [failed, setFailed] = useState()
    const [tagName, setTagName] = useState()
    const [columns, setColumns] = useState([
        {
            id: 0,
            name: 'forename',
            selected: true
        },
        {
            id: 1,
            name: 'surname',
            selected: true
        },
        {
            id: 2,
            name: 'phone',
            selected: true
        }
    ])

    const [ddColumns, setDdColumns] = useState([
        {
            externalId: 0,
            name: 'Forename',
            value: 'forename'
        },
        {
            externalId: 1,
            name: 'Surname',
            value: 'surname'
        },
        {
            externalId: 2,
            name: 'Phone',
            value: 'phone'
        },
        {
            externalId: 3,
            name: 'Tag',
            value: 'tag'
        }
    ])

    const [selectedColumns, setSelectedColumns] = useState([

    ])

    useEffect(() => {
        if (file)
            setSelectedFile(file)
    }, [file])

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
        if (!failed)
            setDragAndDrop(true)
    }

    const dragLeave = (e) => {
        e.preventDefault();
        if (!failed)
            setDragAndDrop(false)
    }

    const fileDrop = (e) => {
        e.preventDefault();
        if (!failed) {
            setDragAndDrop(false)
            const files = e.dataTransfer.files;
            if (files.length) {
                setSelectedFile(files[0])
                setErrorMessage(null)
            }
        }
    }

    const importContacts = async () => {
        setIsUploading(true)
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('action', action)
        formData.append('tag', importTagsType === 'select' ? selectedTag.name : importTagsType === 'enter' ? tagName : '')
        formData.append('tagColumn', importTagsType === 'column')
        formData.append('columns', columns.filter(col => col.name.length > 0).map(col => col.name))

        try {
            await api.post(`/organisations/${organisationId}/contacts/import`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            setIsUploading(false)
            setDone(true)
        } catch (e) {
            setIsUploading(false)
            setDone(true)
            setFailed(e.response && e.response.data && e.response.data.errorDescription ? e.response.data.errorDescription : 'Something happened')
        }
    }

    const back = () => {
        setDragAndDrop(false)
        setShowColumns(false)
        setIsUploading(false)
        setDone(false)
        setFailed(null)
        setSelectedFile(null)
        setErrorMessage(null)
    }

    const showColumnsPage = () => {
        if (!selectedFile)
            setErrorMessage('Please select a file')
        else
            setShowColumns(true)
    }

    const getSelected = column => {
        const a = ddColumns.find(ddC => ddC.value === column.name)
        return a ? a.name : ddColumns[0].name
    }

    return (
        <div className='import-contacts'>
            {isUploading ?
                <div className='uploading-loader'>
                    <Loader
                        type="Oval"
                        color="#EA5459"
                        height={50}
                        width={50}
                    />
                </div>
                :
                <>
                    <label className='title'>Add file</label>
                    <div className='drag-and-drop'
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                    >
                        {
                            !dragAndDrop && selectedFile ?
                                <div className='selected-file'>
                                    {!failed && <img src={require('../../../assets/images/uploaded-file-icon.svg')} />}
                                    {!failed && <label>{selectedFile.name}</label>}
                                </div>
                                :
                                !dragAndDrop &&
                                <>
                                    <img src={require('../../../assets/images/upload-icon.svg')} className='download-img' />
                                    <label className='dd-title'>Drag & Drop</label>
                                    <label className='dd-text'>Every number needs to include a country code for a successful import.</label>
                                    <button onClick={() => inputFile.current.click()}>Choose File</button>
                                    <label className='dd-file-types'>(File types, .XLS, .XLSX, .CSV, .TXT</label>
                                </>
                        }
                    </div>
                    {
                        done ?
                            failed ?
                                <label className='success-label'>Upload Failed.</label>
                                :
                                <label className='success-label'>Success!</label>
                            :
                            !showColumns ?
                                <div className='bottom'>
                                    <div className='import-tags'>
                                        <label className='import-selector-name'>Tags</label>
                                        <div className='import-tag-selector' onClick={() => setImportTagsType('not')}>
                                            <div className='radio-selector'>{importTagsType === 'not' && <div className='radio-selector-selected' />}</div>
                                            <label className='selector-name'>Don't tag contacts</label>
                                        </div>
                                        <div className='import-tag-selector' onClick={() => setImportTagsType('column')}>
                                            <div className='radio-selector'>{importTagsType === 'column' && <div className='radio-selector-selected' />}</div>
                                            <label className='selector-name'>Tag name is a column in the file</label>
                                        </div>
                                        <div className='import-tag-selector' onClick={() => setImportTagsType('select')}>
                                            <div className='radio-selector'>{importTagsType === 'select' && <div className='radio-selector-selected' />}</div>
                                            <Dropdown className='tags-dd' options={tags} selected={selectedTag ? selectedTag.name : null} placeholder='No tags' onSelect={option => setSelectedTag(option)} />
                                        </div>
                                        <div className='import-tag-selector' onClick={() => setImportTagsType('enter')}>
                                            <div className='radio-selector'> {importTagsType === 'enter' && <div className='radio-selector-selected' />}</div>
                                            <input placeholder='Name of new Tag' value={tagName || ''} onChange={e => setTagName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='choose-actions'>
                                        <label className='import-selector-name'>Choose action</label>
                                        <div className='import-tag-selector' onClick={() => setAction('add')}>
                                            <div className='radio-selector'>{action === 'add' && <div className='radio-selector-selected' />}</div>
                                            <label className='selector-name'>Add contacts</label>
                                        </div>
                                        {/* <div className='import-tag-selector' onClick={() => setAction('remove')}>
                                            <div className='radio-selector'>{action === 'remove' && <div className='radio-selector-selected' />}</div>
                                            <label className='selector-name'>Remove contacts</label>
                                        </div> */}
                                    </div>
                                </div>
                                :
                                <div className='columns-container'>
                                    <label className='import-selector-name'>Columns</label>
                                    {columns.map((col, i) =>
                                        <div className='import-column' key={col.id} >
                                            <div className='radio-selector' onClick={() => setColumns(columns.map(c => c.id === col.id ? { ...c, selected: !c.selected } : c))} >{col.selected && <div className='radio-selector-selected' onClick={() => setColumns(columns.map(c => c.id === col.id ? { ...c, selected: !c.selected } : c))} />}</div>
                                            <label className='column-name' onClick={() => setColumns(columns.map(c => c.id === col.id ? { ...c, selected: !c.selected } : c))}>Column {i + 1}</label>
                                            <Dropdown className='columns-dd' options={ddColumns} selected={getSelected(col)} onSelect={option => setColumns(columns.map(c => c.id === col.id ? { ...c, name: option.value } : c))} />
                                            <DeleteIcon className='remove-icon' onClick={() => setColumns(columns.filter(c => c.id != col.id))} />
                                        </div>
                                    )}
                                    <div className='add-column-btn' onClick={() => setColumns([...columns, { name: '', selected: false, id: columns.length > 0 ? columns[columns.length - 1].id + 1 : 0 }])}>
                                        <AddIcon />
                                        <label>Add Column</label>
                                    </div>
                                </div>
                    }
                    {failed && <label className='failed-message'>{failed}</label>}
                    {errorMessage && <label className='error-message'>{errorMessage}</label>}
                    {
                        done ?
                            failed ?
                                <button className='failed-btn' onClick={back}>Back</button>
                                :
                                <button className='done-btn' onClick={close}>Done</button>
                            :
                            <div className='import-actions'>
                                <button onClick={() => setShowColumns(false)}>Back</button>
                                <button onClick={showColumns ? importContacts : showColumnsPage}>Next</button>
                            </div>
                    }

                </>
            }
        </div >
    )
}