import React, { useState, useEffect, useContext } from 'react'
import api from '../utils/api'
import { useHistory } from "react-router-dom";

import { Dropdown } from '../components/widget/dropdown'
import { BackButton } from '../components/widget/backButton'
import { VenuesContext } from '../context/venueContext'

import { ReactComponent as PersonIcon } from '../assets/images/person-icon.svg';

import '../assets/css/invite-user.scss'

export const InviteUser = ({ setShowPopup }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const selectedOrganisation = JSON.parse(localStorage.getItem('selected-organisation'))
    const organisation = selectedOrganisation ? selectedOrganisation : user.organisations[0]

    const { organisations: venues } = useContext(VenuesContext)
    const [roles, setRoles] = useState([])
    const [invitedUser, setInvitedUser] = useState({})
    const [selectedRole, setSelectedRole] = useState()
    const [selectedVenue, setSelectedVenue] = useState(venues[0])

    const [showFirstNameError, setShowFirstNameError] = useState(false)
    const [showLastNameError, setShowLastNameError] = useState(false)
    const [showEmailError, setShowEmailError] = useState(false)
    const [showServerError, setShowServerError] = useState(false)

    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            const rolesResponse = await api.get(`/roles`)
            setRoles(rolesResponse.data)
            const role = rolesResponse.data[0]
            setSelectedRole(role)

            setInvitedUser({ ...invitedUser, roleExternalId: role.externalId, organisationExternalId: selectedVenue ? selectedVenue.externalId : ''})
        }
        fetchData()
    }, [organisation.externalId])

    useEffect(() => {
        if(venues && venues.length > 0) {
            setInvitedUser({ ...invitedUser, organisationExternalId: venues[0].externalId })
            setSelectedVenue(venues[0])
        } else {
            setInvitedUser({ ...invitedUser, organisationExternalId: organisation.externalId })
            setSelectedVenue(organisation)
        }
    }, [venues])

    const selectRole = (role) => {
        setInvitedUser({ ...invitedUser, roleExternalId: role.externalId })
        setSelectedRole(role)
    }

    const selectVenue = (venue) => {
        setInvitedUser({ ...invitedUser, organisationExternalId: venue.externalId })
        setSelectedVenue(venue)
    }

    const invite = async () => {
        if (!invitedUser.forename || invitedUser.forename.length < 3)
            setShowFirstNameError(true)
        else if (!invitedUser.surname || invitedUser.surname.length < 3)
            setShowLastNameError(true)
        else if (!invitedUser.email || invitedUser.email.length < 3)
            setShowEmailError(true)

        else {
            setShowFirstNameError(false)
            setShowLastNameError(false)
            setShowEmailError(false)

            try {
                await api.post('/user/invite', invitedUser)
                setShowPopup('Invitation sent')
                setTimeout(() => setShowPopup(null), 2000)
                setInvitedUser({ ...invitedUser, forename: '', surname: '', email: '' })
                history.goBack()
            } catch (e) {
                setShowServerError(true)
            }
        }
    }

    const onEmailChange = (email) => {
        setInvitedUser({ ...invitedUser, email: email })
        setShowServerError(false)
    }

    return (
        <div className='invite-user-page'>
            <BackButton />

            <div className='invite-user'>
                <div className='invite-user-top'>
                    <div className='invite-user-logo'>
                        <PersonIcon className='invite-user-logo-svg' />
                    </div>
                    <label>Invite User</label>
                </div>
                <hr />
                <div className='invite-user-inputs'>
                    <div className='invite-user-input'>
                        <label>First name</label>
                        <input placeholder='Please enter first name' className={`${showFirstNameError && 'input-error'}`} value={invitedUser.forename || ''} onChange={e => setInvitedUser({ ...invitedUser, forename: e.target.value })} />
                    </div>
                    <div className='invite-user-input'>
                        <label>Last name</label>
                        <input placeholder='Please enter last name' className={`${showLastNameError && 'input-error'}`} value={invitedUser.surname || ''} onChange={e => setInvitedUser({ ...invitedUser, surname: e.target.value })} />
                    </div>
                    <div className='invite-user-input'>
                        <label>Email</label>
                        <input placeholder='Please enter email' className={`${showEmailError && 'input-error'}`} value={invitedUser.email || ''} onChange={e => onEmailChange(e.target.value)} />
                    </div>
                    <div className='invite-user-select'>
                        <label>Venue</label>
                        <Dropdown options={venues} selected={selectedVenue} onSelect={selectVenue} />
                    </div>
                    <div className='invite-user-select roles'>
                        <label>User role</label>
                        <Dropdown options={roles} selected={selectedRole} onSelect={selectRole} />
                    </div>
                    {showServerError && <label className='error-message'>{`User with email ${invitedUser.email} already exists`} </label>}
                </div>
                <hr />
                <div className='invite-user-actions'>
                    <button className='invite-user-cancel-btn' onClick={() => history.goBack()}>Cancel</button>
                    <button onClick={invite}>Invite</button>
                </div>
            </div>
        </div>
    )
}