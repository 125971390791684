import React, { useState, useEffect } from 'react'

import { useHistory } from "react-router-dom";

import PaymentDone from './paymentDone'

import '../../assets/css/billing/payment-done-popup.scss'

export default ({ paymentResponse, type, close, retry }) => {

    const user = JSON.parse(localStorage.getItem('user'))

    const history = useHistory()
    const [error, setError] = useState()

    const goToDashboard = () => {
        close()
        history.push(`/${type.toLowerCase()}/dashboard`)
    }

    useEffect(() => {
        if (paymentResponse.error)
            setError(paymentResponse.error.code)
    }, [paymentResponse])

    return (
        <div className='payment-done-popup'>
            <div className='payment-done-popup-content'>
                <PaymentDone finish={goToDashboard} email={user.email} error={error} type={type} close={close} retry={retry} />
            </div>
        </div>
    )
}