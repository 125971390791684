import React, { useState, useEffect } from 'react'
import ChartWidget from '../components/main-dashboard/widgets/chart'
import MessagesWidget from '../components/main-dashboard/widgets/messages'
import EmailsCollectedWidget from '../components/main-dashboard/widgets/emailsCollected'
import CustomersWidget from '../components/main-dashboard/widgets/customers'
import DemographicsWidget from '../components/main-dashboard/widgets/demographics'
import CustomerLoginsWidget from '../components/main-dashboard/widgets/customerLogins'
import SmsCampaignsWidget from '../components/main-dashboard/widgets/smsCampaigns'
import SmsCampaignsList from '../components/main-dashboard/widgets/smsCampaignsList'
import WidgetSelector from '../components/main-dashboard/widgetSelector'

import RGL, { WidthProvider } from "react-grid-layout";
import api from '../utils/api'

import '../assets/css/main-dashboard.scss'

export default ({ organisationId, edit, setEdit }) => {
    const defaultConnectSmsDashboard = [{ "w": 1, "h": 5, "x": 3, "y": 0, "i": "connect customer logins", "minW": 1, "minH": 2, "moved": false, "static": false }, { "w": 1, "h": 2, "x": 0, "y": 0, "i": "connect emails collected", "minW": 1, "minH": 2, "moved": false, "static": false }, { "w": 1, "h": 2, "x": 0, "y": 2, "i": "connect demographics", "minW": 1, "minH": 2, "moved": false, "static": false }, { "w": 2, "h": 4, "x": 0, "y": 6, "i": "connect chart", "minW": 2, "minH": 4, "moved": false, "static": false }, { "w": 1, "h": 2, "x": 0, "y": 4, "i": "sms sent", "minW": 1, "minH": 2, "moved": false, "static": false }, { "w": 1, "h": 6, "x": 1, "y": 0, "i": "sms campaigns", "minW": 1, "minH": 4, "moved": false, "static": false }, { "w": 1, "h": 5, "x": 3, "y": 5, "i": "other customers", "minW": 1, "minH": 3, "moved": false, "static": false }, { "w": 1, "h": 10, "x": 2, "y": 0, "i": "sms campaigns list", "minW": 1, "minH": 4, "moved": false, "static": false }]
    const defaultConnetDashbaord = [{ "w": 2, "h": 5, "x": 2, "y": 0, "i": "connect customer logins", "minW": 1, "minH": 2, "moved": false, "static": false }, { "w": 2, "h": 2, "x": 0, "y": 0, "i": "connect emails collected", "minW": 1, "minH": 2, "moved": false, "static": false }, { "w": 2, "h": 3, "x": 0, "y": 2, "i": "connect demographics", "minW": 1, "minH": 2, "moved": false, "static": false }, { "w": 3, "h": 5, "x": 0, "y": 5, "i": "connect chart", "minW": 2, "minH": 4, "moved": false, "static": false }, { "w": 1, "h": 5, "x": 3, "y": 5, "i": "other customers", "minW": 1, "minH": 3, "moved": false, "static": false }]

    const ReactGridLayout = WidthProvider(RGL);

    const selected = JSON.parse(localStorage.getItem('selected-organisation'))
    const user = JSON.parse(localStorage.getItem('user'))
    const adminUser = user.organisations[0].orgType !== 'ORGANISATION'
    const userOrganisation = adminUser && selected && selected.orgType === 'ORGANISATION' ? selected.externalId : user.organisations[0].externalId

    const [statistics, setStatistics] = useState()
    const [messageStatistics, setMessageStatistics] = useState({})
    const [analytics, setAnalytics] = useState()
    const [activeLicenses, setActiveLicenses] = useState([])
    const [height, setHeight] = useState(window.innerHeight);
    const [colHeight, setColHeight] = useState(50)
    const [widgetSearch, setWidgetSearch] = useState()

    const [state, setState] = useState(JSON.parse(localStorage.getItem('dashboard-layout')) || null)

    const updateDimensions = () => {
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    useEffect(() => {
        if (!state) {
            if (activeLicenses.includes('CONNECT')) {
                if (activeLicenses.includes('SMS')) {
                    setState(defaultConnectSmsDashboard)
                    localStorage.setItem('dashboard-layout', JSON.stringify(defaultConnectSmsDashboard))
                } else {
                    setState(defaultConnetDashbaord)
                    localStorage.setItem('dashboard-layout', JSON.stringify(defaultConnetDashbaord))
                }
            }
        }
    }, [activeLicenses]);

    const [selectors, setSelectors] = useState([
        {
            externalId: 0,
            name: "Day",
            unit: "day",
            selected: false,
            apiUnit: 'hourly'
        },
        {
            externalId: 1,
            name: "Week",
            unit: "week",
            selected: false,
            apiUnit: 'daily'
        },
        {
            externalId: 2,
            name: "Month",
            unit: "month",
            selected: true,
            apiUnit: 'daily'
        },
        {
            externalId: 3,
            name: "Year",
            unit: "year",
            selected: false,
            apiUnit: 'monthly'
        }
    ])

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`organisations/${userOrganisation}/licences`)
            const currentDate = Math.floor(Date.now() / 1000)
            setActiveLicenses(data.filter(license => license.startDate < currentDate && license.expiryDate > currentDate).map(license => license.licenceType))

        }
        fetchData()
    }, [userOrganisation])


    useEffect(() => {
        const fetchData = async () => {
            if (organisationId != null) {
                const result = await api.get(`/organisation/${organisationId}/summary`)
                setStatistics(result.data)
            }
        }
        fetchData()
    }, [organisationId])

    useEffect(() => {
        const fetchData = async () => {
            if (organisationId != null) {
                const selected = selectors.find(s => s.selected)
                const result = await api.get(`/organisation/${organisationId}/analytics?unit=${selected.apiUnit}&startDate=${getStartDate(selected.unit)}`)
                setAnalytics(result.data)
            }
        }
        fetchData()
    }, [organisationId, JSON.stringify(selectors)])

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`/sms/organisation/${organisationId}/messages/status/statistics`)
            setMessageStatistics(data)
        }
        if (organisationId)
            fetchData()
    }, [organisationId])

    const getStartDate = unit => {
        if (unit === 'day') {
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - 1)
            startDate.setHours(startDate.getHours(), 0, 0, 0);
            return startDate.getTime() / 1000

        } else if (unit === 'week') {
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - 7)
            startDate.setHours(0, 0, 0, 0)
            return startDate.getTime() / 1000

        } else if (unit === 'month') {
            const now = new Date()
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate())
            startDate.setHours(0, 0, 0, 0)
            return startDate.getTime() / 1000
        } else if (unit === 'year') {
            const startDate = new Date()
            startDate.setHours(0, 0, 0, 0);
            startDate.setMonth(startDate.getMonth() - 12, 1)
            return startDate.getTime() / 1000
        }
    }

    const props = {
        items: 20,
        onLayoutChange: function () { },
        cols: 4,
    };

    const onLayoutChange = (layout) => {
        var max
        if (layout && layout.length > 0)

            if (layout.length <= state.length)
                setState(layout)
    }

    const widgets = [
        {
            id: 0,
            name: 'Connect',
            type: 'connet',
            icon: require('../assets/images/connect-icon.svg'),
            open: false,
            requireLicense: 'CONNECT',
            widgets: [
                {
                    id: 0,
                    name: 'connect emails collected',
                    image: require('../assets/images/widgets/emails-collected.svg'),
                    width: 1,
                    height: 2
                }, {
                    id: 1,
                    name: 'connect demographics',
                    image: require('../assets/images/widgets/demographics.svg'),
                    width: 1,
                    height: 2
                }, {
                    id: 2,
                    name: 'connect customer logins',
                    image: require('../assets/images/widgets/customer-logins.svg'),
                    width: 1,
                    height: 2
                },
                {
                    id: 3,
                    name: 'connect chart',
                    image: require('../assets/images/widgets/connect-chart.svg'),
                    wide: true,
                    width: 2,
                    height: 4
                }
            ]
        }, {
            id: 1,
            name: 'SMS',
            type: 'sms',
            icon: require('../assets/images/sms-icon.svg'),
            open: false,
            requireLicense: 'SMS',
            widgets: [
                {
                    id: 0,
                    name: 'sms sent',
                    image: require('../assets/images/widgets/sms-sent.svg'),
                    width: 1,
                    height: 2
                }, {
                    id: 1,
                    name: 'sms campaigns',
                    image: require('../assets/images/widgets/sms-campaigns.svg'),
                    tall: true,
                    width: 1,
                    height: 4
                }, {
                    id: 2,
                    name: 'sms campaigns list',
                    image: require('../assets/images/widgets/sms-campaigns-list.svg'),
                    tall: true,
                    width: 1,
                    height: 4
                }
            ]
        }, {
            id: 2,
            name: 'Other',
            type: 'other',
            icon: require('../assets/images/customer-icon.svg'),
            open: false,
            widgets: [
                {
                    id: 0,
                    name: 'other customers',
                    image: require('../assets/images/widgets/customers.svg'),
                    tall: true,
                    width: 1,
                    height: 3
                }
            ]
        }
    ]

    const [products, setProducts] = useState(widgets)

    const [draggedWidget, setDraggedWidget] = useState({})

    const onDrop = (layout, layoutItem, event) => {
        layoutItem.isDraggable = undefined
        setState([...state, layoutItem])
    }

    useEffect(() => {
        setProducts(products.map(p => ({ ...p, open: false })))

        if (edit) {
            setProducts(products.map(p => ({ ...p, widgets: p.widgets.filter(w => !state.map(s => s.i).includes(w.name)) })))
        }
    }, [edit])

    const saveDashboardLayout = () => {
        localStorage.setItem('dashboard-layout', JSON.stringify(state))
        setEdit(false)
    }

    const onWidgetDragStart = (product, widget) => {
        setDraggedWidget(widget)
    }

    const onWidgetDragEnd = (product, widget) => {
        if (state.map(s => s.i).includes(widget.name))
            setProducts(products.map(p => p.id === product.id ? ({ ...p, widgets: p.widgets.filter(w => w.id !== widget.id) }) : p))
    }


    useEffect(() => {
        if (height && state && state.length > 0) {
            setColHeight((height - 320) / 10)
        }
    }, [state, height])

    useEffect(() => {
        if (widgetSearch && widgetSearch.length > 1)
            setProducts(widgets.map(product => ({ ...product, widgets: product.widgets.filter(w => w.name.includes(widgetSearch)), open: true })))
        else
            setProducts(widgets)
    }, [widgetSearch])

    const removeWidget = widget => {
        setState(state.filter(s => s.i !== widget.i))
        const product = widgets.find(w => w.widgets.find(ww => ww.name === widget.i))
        if (product) {
            setProducts(products.map(p => p.id === product.id ? { ...p, widgets: [...p.widgets, product.widgets.find(ww => ww.name === widget.i)] } : p))
        }
    }
    
    return (
        <div className='main-dashboard'>
            <WidgetSelector
                show={edit}
                products={products}
                setProducts={setProducts}
                widgetSearch={widgetSearch}
                setWidgetSearch={setWidgetSearch}
                activeLicenses={activeLicenses}
                onWidgetDragStart={onWidgetDragStart}
                onWidgetDragEnd={onWidgetDragEnd}
                saveDashboardLayout={saveDashboardLayout}
            />


            <ReactGridLayout
                className={`layout`}
                layout={state ? state : []}
                {...props}
                onLayoutChange={onLayoutChange}
                margin={[20, 20]}
                useCSSTransforms={true}
                onDrop={onDrop}
                isDroppable={edit}
                isDraggable={edit}
                isResizable={edit}
                measureBeforeMount={false}
                maxRows={10}
                rowHeight={colHeight}
                droppingItem={{ i: draggedWidget.name, w: draggedWidget.width, h: draggedWidget.height, minW: draggedWidget.width, minH: draggedWidget.height }}
            >
                {
                    state && state.map(widget => {
                        if (widget.i === 'connect emails collected')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }}>
                                    <EmailsCollectedWidget
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                        value={statistics ? Object.entries(statistics.signups).map(([key, value]) => value.total).reduce((c, t) => t += c) : 0} />
                                </div>
                            )
                        else if (widget.i === 'connect demographics')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }} >
                                    <DemographicsWidget
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                        male={statistics ? ((statistics.demographics.male * 100 / (statistics.demographics.male + statistics.demographics.female)) || 0).toFixed(0) : 0}
                                        female={statistics ? ((statistics.demographics.female * 100 / (statistics.demographics.male + statistics.demographics.female)) || 0).toFixed(0) : 0}
                                    />
                                </div>
                            )
                        else if (widget.i === 'connect customer logins')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }} >
                                    <CustomerLoginsWidget
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                        statistics={statistics} />
                                </div>
                            )
                        else if (widget.i === 'connect chart')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }} >
                                    <ChartWidget
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                        yTitle='Total visits'
                                        title='Visits'
                                        icon={require('../assets/images/connect-icon.svg')}
                                        selectors={selectors}
                                        setSelectors={setSelectors}
                                        field='visits'
                                        analytics={analytics}
                                    />
                                </div>
                            )
                        else if (widget.i === 'sms sent')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }} >
                                    <MessagesWidget
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                        statistics={messageStatistics} />
                                </div>
                            )
                        else if (widget.i === 'other customers')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }} >
                                    <CustomersWidget
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                        connectedCustomers={statistics ? statistics.connectedDevices : 0}
                                        newCustomers={statistics ? Object.entries(statistics.signups).map(([key, value]) => value.today).reduce((c, t) => t += c) : 0}
                                        returnRate={statistics ? Math.round(statistics.visits.returnRate) : 0}
                                    />
                                </div>
                            )
                        else if (widget.i === 'sms campaigns')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }} >
                                    <SmsCampaignsWidget
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                        totalSent={messageStatistics.success}
                                        textsFailed={messageStatistics.error}
                                        contacts={messageStatistics.contacts}
                                    />
                                </div>
                            )
                        else if (widget.i === 'sms campaigns list')
                            return (
                                <div key={widget.i} data-grid={{ w: widget.w, h: widget.h, x: widget.x, y: widget.y, minW: widget.w, minH: widget.h }} >
                                    <SmsCampaignsList
                                        edit={edit}
                                        remove={() => removeWidget(widget)}
                                    />
                                </div>
                            )
                        else
                            return (
                                <div key={widget.id} data-grid={{ w: 2, h: 3, x: 0, y: 0 }} style={{ backgroundColor: 'grey' }}>
                                    <span className="text">{widget.id}</span>
                                    {/* <button onClick={() => setWidgets(widgets.filter(w => w.id !== widget.id))}>remove</button> */}
                                </div>
                            )
                    })
                }
            </ReactGridLayout>
        </div >
    )
}