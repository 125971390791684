import React, { useState, useEffect } from 'react'

import { Dropdown } from '../../../../components/widget/dropdown'
import EditMessage from './editMessage'
import api from '../../../../utils/api'

import '../../../../assets/css/sms/campaign/create/compose-message.scss'

export default ({ campaign, setCampaign, next, saveTemplate }) => {

    const [phoneNumber, setPhoneNumber] = useState()
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const years = [2020, 2021, 2022, 2023, 2024, 2025]
    const currentDate = new Date()

    const [selectedDay, setSelectedDay] = useState(currentDate.getDate())
    const [selectedMonth, setSelectedMonth] = useState(months[currentDate.getMonth()])
    const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear())
    const [selectedHour, setSelectedHour] = useState(currentDate.getHours())
    const [selectedMinute, setSelectedMinute] = useState(currentDate.getMinutes())
    const [editMessage, setEditMessage] = useState(false)

    const [sendTestLabel, setSendTestLabel] = useState('Send Test')
    const [sendTestActive, setSendTestActive] = useState(true)
    const [sendTestError, setSendTestError] = useState(false)
    const [sendTestColor, setSendTestColor] = useState('#FFCC33')
    const [errorMessage, setErrorMessage] = useState()

    const [scheduleTypes, setScheduleTypes] = useState([
        {
            id: 0,
            name: 'Daily',
            type: 'DAILY',
            selected: true
        },
        {
            id: 1,
            name: 'Weekly',
            type: 'WEEKLY',
            selected: false
        },
        {
            id: 2,
            name: 'Monthly',
            type: 'MONTHLY',
            selected: false
        },
        {
            id: 3,
            name: 'Only Once',
            type: 'ONCE',
            selected: false
        }
    ])

    const [everyDayNumber, setEveryDayNumber] = useState(1)
    const [lastDayNumber, setLastDayNumber] = useState(1)
    const [selectedSelector, setSelectedSelector] = useState('every_day')
    const [selectedSelectorMonth, setSelectedSelectorMonth] = useState('day_of_month')

    const [weekDays, setWeekDays] = useState([
        {
            id: 1,
            name: 'Monday',
            selected: false
        },
        {
            id: 2,
            name: 'Tuesday',
            selected: false
        },
        {
            id: 3,
            name: 'Wednesday',
            selected: false
        },
        {
            id: 4,
            name: 'Thursday',
            selected: false
        },
        {
            id: 5,
            name: 'Friday',
            selected: false
        },
        {
            id: 6,
            name: 'Saturday',
            selected: false
        },
        {
            id: 0,
            name: 'Sunday',
            selected: false
        }
    ])

    useEffect(() => {
        if (campaign && campaign.campaignType) {
            setScheduleTypes(scheduleTypes.map(type => ({ ...type, selected: type.type === campaign.campaignType })))
            if (campaign.campaignType === 'ONCE') {
                const scheduleDate = new Date(campaign.schedules[0].scheduleDate * 1000)
                setSelectedMinute(scheduleDate.getMinutes())
                setSelectedHour(scheduleDate.getHours())
                setSelectedDay(scheduleDate.getDate())
                setSelectedMonth(months[scheduleDate.getMonth()])
                setSelectedYear(scheduleDate.getFullYear())
            } else if (campaign.campaignType === 'DAILY') {
                const schedule = campaign.schedules[0]
                const scheduleDate = new Date(schedule.scheduleDate * 1000)

                if (schedule.frequency === 'EVERY_X_DAYS') {
                    setEveryDayNumber(schedule.noOfDays)
                    setSelectedMinute(scheduleDate.getMinutes())
                    setSelectedHour(scheduleDate.getHours())
                    setSelectedDay(scheduleDate.getDate())
                    setSelectedMonth(months[scheduleDate.getMonth()])
                    setSelectedYear(scheduleDate.getFullYear())
                    setSelectedSelector('every_day')
                } else if (schedule.frequency === 'WEEKLY_FOR_A_YEAR') {
                    setEveryDayNumber(schedule.noOfDays)
                    setSelectedMinute(scheduleDate.getMinutes())
                    setSelectedHour(scheduleDate.getHours())
                    setSelectedDay(scheduleDate.getDate())
                    setSelectedMonth(months[scheduleDate.getMonth()])
                    setSelectedYear(scheduleDate.getFullYear())
                    setSelectedSelector('every_week_day')
                }
            } else if (campaign.campaignType === 'WEEKLY') {
                const schedule = campaign.schedules[0]
                const scheduleDays = campaign.schedules.map(s => s.scheduleDate).map(d => new Date(d * 1000).getDay())
                const scheduleDate = new Date(schedule.scheduleDate * 1000)
                setSelectedMinute(scheduleDate.getMinutes())
                setSelectedHour(scheduleDate.getHours())
                setSelectedDay(scheduleDate.getDate())
                setSelectedMonth(months[scheduleDate.getMonth()])
                setSelectedYear(scheduleDate.getFullYear())
                setWeekDays(weekDays.map(day => ({ ...day, selected: scheduleDays.includes(day.id) })))
            } else if (campaign.campaignType === 'MONTHLY') {
                const schedule = campaign.schedules[0]
                const scheduleDate = new Date(schedule.scheduleDate * 1000)
                setSelectedMinute(scheduleDate.getMinutes())
                setSelectedHour(scheduleDate.getHours())
                setSelectedDay(scheduleDate.getDate())
                setSelectedMonth(months[scheduleDate.getMonth()])
                setSelectedYear(scheduleDate.getFullYear())

                if (schedule.frequency === 'MONTHLY') {
                    setSelectedSelectorMonth('day_of_month')
                    setEveryDayNumber(scheduleDate.getDate())

                } else if (schedule.frequency === 'LAST_DAY_OF_MONTH') {
                    if (schedule.noOfDays === 0) {
                        setSelectedSelectorMonth('last_day_of_month')
                    } else {
                        setSelectedSelectorMonth('first_day')
                        setLastDayNumber(schedule.noOfDays)
                    }
                }
            }
        }

    }, [campaign])

    const changeMessage = message => {
        setCampaign({ ...campaign, messageBody: message })
    }

    const sendTestMessage = async () => {
        if (!phoneNumber || phoneNumber.length < 3)
            setSendTestError(true)
        else {
            setSendTestLabel('Sent')
            setSendTestActive(false)
            setSendTestColor('#EA5454')

            await api.post('/sms/test', { organisationExternalId: campaign.organisationExternalId, body: campaign.messageBody, to: campaign.sender.phone, from: phoneNumber })
            setPhoneNumber(null)

            setTimeout(() => {
                setSendTestLabel('Send Test')
                setSendTestActive(true)
                setSendTestColor('#FFCC33')
            }, 1000)
        }
    }

    const changePhoneNumber = value => {
        setPhoneNumber(value)
        setSendTestError(false)
    }

    const getMonths = () => {
        if (currentDate.getFullYear() === selectedYear)
            return months.filter((m, i) => i >= currentDate.getMonth())
        return months
    }

    const getDays = () => {
        if (currentDate.getFullYear() === selectedYear && currentDate.getMonth() === months.indexOf(selectedMonth))
            return Array.from(Array(new Date(selectedYear, months.indexOf(selectedMonth) + 1, 0).getDate()).keys()).map(k => k + 1).filter(k => k >= currentDate.getDate())
        return Array.from(Array(new Date(selectedYear, months.indexOf(selectedMonth) + 1, 0).getDate()).keys()).map(k => k + 1)
    }

    const getHours = () => {
        return Array.from(Array(24).keys())
    }

    const getMinutes = () => {
        return Array.from(Array(60).keys())
    }

    const getDateForDay = day => {
        var counter = 0
        const currentDate = new Date()
        while (currentDate.getDay() !== day && counter < 50) {
            currentDate.setDate(currentDate.getDate() + 1)
            counter++;
        }
        return currentDate
    }

    const getDateForDayOfMonth = day => {
        const currentDate = new Date()
        currentDate.setDate(day)
        return currentDate
    }

    const createCampaign = async () => {
        const request = { ...campaign }
        const type = scheduleTypes.find(st => st.selected).type
        switch (type) {
            case 'DAILY':
                if (selectedSelector === 'every_day') {
                    request.schedules = [{
                        frequency: 'EVERY_X_DAYS',
                        date: new Date(selectedYear, months.indexOf(selectedMonth), selectedDay, selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                        noOfDays: everyDayNumber
                    }]
                } else if (selectedSelector === 'every_week_day') {
                    request.schedules = [
                        {
                            frequency: 'WEEKLY_FOR_A_YEAR',
                            date: new Date(selectedYear, getDateForDay(1).getMonth(), getDateForDay(1).getDate(), selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                            noOfDays: everyDayNumber
                        },
                        {
                            frequency: 'WEEKLY_FOR_A_YEAR',
                            date: new Date(selectedYear, getDateForDay(2).getMonth(), getDateForDay(2).getDate(), selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                            noOfDays: everyDayNumber
                        },
                        {
                            frequency: 'WEEKLY_FOR_A_YEAR',
                            date: new Date(selectedYear, getDateForDay(3).getMonth(), getDateForDay(3).getDate(), selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                            noOfDays: everyDayNumber
                        },
                        {
                            frequency: 'WEEKLY_FOR_A_YEAR',
                            date: new Date(selectedYear, getDateForDay(4).getMonth(), getDateForDay(4).getDate(), selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                            noOfDays: everyDayNumber
                        },
                        {
                            frequency: 'WEEKLY_FOR_A_YEAR',
                            date: new Date(selectedYear, getDateForDay(5).getMonth(), getDateForDay(5).getDate(), selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                            noOfDays: everyDayNumber
                        }
                    ]
                }
                break
            case 'WEEKLY':
                request.schedules = weekDays.filter(day => day.selected).map(day => ({
                    frequency: 'WEEKLY_FOR_A_YEAR',
                    date: new Date(selectedYear, getDateForDay(day.id).getMonth(), getDateForDay(day.id).getDate(), selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                    noOfDays: everyDayNumber
                }))
                break
            case 'MONTHLY':
                if (selectedSelectorMonth === 'day_of_month') {
                    request.schedules = [{
                        frequency: 'MONTHLY',
                        date: new Date(selectedYear, months.indexOf(selectedMonth), getDateForDayOfMonth(everyDayNumber).getDate(), selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                    }]
                } else if (selectedSelectorMonth === 'last_day_of_month') {
                    request.schedules = [{
                        frequency: 'LAST_DAY_OF_MONTH',
                        date: new Date(selectedYear, months.indexOf(selectedMonth), selectedDay, selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                        noOfDays: 0
                    }]
                } else if (selectedSelectorMonth === 'first_day') {
                    request.schedules = [{
                        frequency: 'LAST_DAY_OF_MONTH',
                        date: new Date(selectedYear, months.indexOf(selectedMonth), selectedDay, selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                        noOfDays: lastDayNumber
                    }]
                }
                break
            case 'ONCE':
                request.schedules = [{
                    frequency: 'ONCE',
                    date: new Date(selectedYear, months.indexOf(selectedMonth), selectedDay, selectedHour, selectedMinute, 0, 0).getTime() / 1000,
                }]
                break
        }
        request.campaignType = type

        try {
            if (campaign.externalId) {
                await api.put(`sms/organisation/${campaign.organisationExternalId}/campaign/${campaign.externalId}`, request)
            } else {
                await api.post('/sms/campaign/schedule', request)
            }
            next()
        } catch (e) {
            setErrorMessage(e.response.data.errorDescription)
        }
    }

    return (
        <div className='compose-message-container'>
            {editMessage ?
                <EditMessage saveTemplate={saveTemplate} organisationId={campaign.organisationExternalId} changeMessage={changeMessage} campaignMessage={campaign.messageBody} close={() => setEditMessage(false)} />
                :
                <div className='compose-message'>
                    <div className='left'>
                        <label className='title'>Test your Message</label>
                        <div className='phone-number'>
                            <label className='pn-name'>Phone number</label>
                            <div className='phone-numbers'>
                                <input style={{ borderColor: sendTestError ? '#EA5454' : '#DBDBDB' }} value={phoneNumber || ''} onChange={e => changePhoneNumber(e.target.value)} />
                            </div>
                            <button style={{ backgroundColor: sendTestColor }} disabled={!sendTestActive} onClick={sendTestMessage}>{sendTestLabel}</button>
                        </div>
                        <hr />
                        <label className='title'>Schedule Message Date</label>
                        <div className='schedule-types'>
                            {scheduleTypes.map(scheduleType =>
                                <label
                                    key={scheduleType.id}
                                    className={`schedule-type ${scheduleType.selected && 'schedule-type-selected'}`}
                                    onClick={() => setScheduleTypes(scheduleTypes.map(s => ({ ...s, selected: s.id === scheduleType.id })))}
                                >
                                    {scheduleType.name}
                                </label>)}
                        </div>
                        {
                            scheduleTypes[0].selected ?
                                <div className='daily-schedule'>
                                    <div className='schedule-selector' onClick={() => setSelectedSelector('every_day')}>
                                        <div className='radio-selector'> {selectedSelector === 'every_day' && <div className='selected' />}</div>
                                        <label>Every</label>
                                        <input maxLength='2' value={everyDayNumber || ''} onChange={e => setEveryDayNumber(e.target.value)} />
                                        <label>day(s)</label>
                                    </div>
                                    <div className='schedule-selector' onClick={() => setSelectedSelector('every_week_day')}>
                                        <div className='radio-selector'>{selectedSelector === 'every_week_day' && <div className='selected' />}</div>
                                        <label>Every Week Day</label>
                                    </div>
                                </div>
                                :
                                scheduleTypes[1].selected ?
                                    <div className='weekly-schedule'>
                                        <label className='weekly-schedule-title'>Specific day(s) of the week</label>
                                        <div className='week-days'>
                                            {weekDays.map(day =>
                                                <div key={day.id} className='week-day' onClick={() => setWeekDays(weekDays.map(d => d.id === day.id ? { ...d, selected: !d.selected } : d))}>
                                                    <div className='radio-selector'> {day.selected && <div className='selected' />}</div>
                                                    <label>{day.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    :
                                    scheduleTypes[2].selected ?
                                        <div className='monthly-schedule'>
                                            <div className='schedule-selector' onClick={() => setSelectedSelectorMonth('day_of_month')}>
                                                <div className='radio-selector' > {selectedSelectorMonth === 'day_of_month' && <div className='selected' />}</div>
                                                <label>Day</label>
                                                <input maxLength='2' value={everyDayNumber || ''} onChange={e => setEveryDayNumber(e.target.value)} />
                                                <label>of every month(s)</label>
                                            </div>
                                            <div className='schedule-selector' onClick={() => setSelectedSelectorMonth('last_day_of_month')}>
                                                <div className='radio-selector' >{selectedSelectorMonth === 'last_day_of_month' && <div className='selected' />}</div>
                                                <label>Last day of every month</label>
                                            </div>
                                            {/* <div className='schedule-selector' onClick={() => setSelectedSelectorMonth('last_week_day_of_month')}>
                                                <div className='radio-selector' >{selectedSelectorMonth === 'last_week_day_of_month' && <div className='selected' />}</div>
                                                <label>Last week day of every month</label>
                                            </div> */}
                                            <div className='schedule-selector' onClick={() => setSelectedSelectorMonth('first_day')}>
                                                <div className='radio-selector' >{selectedSelectorMonth === 'first_day' && <div className='selected' />}</div>
                                                <input maxLength='2' value={lastDayNumber || ''} onChange={e => setLastDayNumber(e.target.value)} />
                                                <label>day(s) before the end of the month</label>
                                            </div>
                                        </div>
                                        :
                                        <div className='phone-number date-selector'>
                                            <label className='pn-name'>Date</label>
                                            <Dropdown options={years.map(year => ({ name: year }))} selected={{ name: selectedYear }} onSelect={option => setSelectedYear(option.name)} />
                                            <Dropdown options={getMonths().map(month => ({ name: month }))} selected={{ name: selectedMonth }} onSelect={option => setSelectedMonth(option.name)} />
                                            <Dropdown options={getDays().map(day => ({ name: day }))} selected={{ name: selectedDay }} onSelect={option => setSelectedDay(option.name)} />
                                        </div>
                        }
                        <div className='phone-number time-selector'>
                            <label className='pn-name'>Start Time</label>
                            <Dropdown className='time' options={getHours().map(i => ({ name: i }))} selected={{ name: selectedHour }} onSelect={option => setSelectedHour(option.name)} />
                            <Dropdown className='time' options={getMinutes().map(i => ({ name: i }))} selected={{ name: selectedMinute }} onSelect={option => setSelectedMinute(option.name)} />
                        </div>
                        {errorMessage && <label className='error-message'>{errorMessage}</label>}
                        <hr />
                        <div className='actions'>
                            <button onClick={() => setEditMessage(true)}>Edit Message</button>
                            <button onClick={createCampaign}>Send</button>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='message-preview'>
                            <div className='message-container'>
                                <label>{campaign.messageBody}</label>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}
