import React, { useState, useEffect } from 'react'
import api from '../../utils/api'
import { Dropdown } from '../../components/widget/dropdown'
import TagsInput from '../widget/tagInput'

import '../../assets/css/integrations/add-integration-popup-item.scss'

export default ({ connector, venue, addIntegration }) => {

    const [integration, setIntegration] = useState({ name: `${connector} ${venue.name}`, organisationId: venue.externalId, connector: connector, tags: [] })
    const [ids, setIds] = useState([])

    const [selectedId, setSelectedId] = useState()

    const [isSelected, setIsSelected] = useState(false)

    const [errors, setErrors] = useState([])
    const [showError, setShowError] = useState()
    const [serverError, setServerError] = useState()
    const [password, setPassword] = useState()
    const [siteId, setSiteId] = useState()

    const [name, setName] = useState('')

    const changeApiKey = value => {
        setErrors(errors.filter(error => error !== 'apiKey'))
        setShowError()
        setSelectedId(null)
        setIntegration({ ...integration, apiKey: value })
    }

    const changePassword = value => {
        setErrors(errors.filter(error => error !== 'password'))
        setShowError()
        setSelectedId(null)
        setPassword(value)
    }

    const changeSelected = selected => {
        setErrors(errors.filter(error => error !== 'id'))
        setShowError()
        setSelectedId(selected)
    }

    const changeApiEndpoint = value => {
        setErrors(errors.filter(error => error !== 'apiEndpoint'))
        setShowError()
        setIntegration({ ...integration, apiEndpoint: value })
    }

    const changeName = value => {
        setErrors(errors.filter(error => error !== 'name'))
        setShowError()
        setName(value)
    }

    const save = async () => {
        var e = []

        if (connector === 'MAILCHIMP') {
            if (!integration.apiKey || integration.apiKey === ' ')
                e = [...e, 'apiKey']
            if (!selectedId)
                e = [...e, 'id']
        } else if (connector === 'UNIFI') {
            if (!password || password === ' ' || !/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(password))
                e = [...e, 'password']
            if (!integration.apiEndpoint || integration.apiEndpoint === ' ')
                e = [...e, 'apiEndpoint']
            if (!name || name === ' ')
                e = [...e, 'name']
        }

        setErrors(e)

        if (e.length > 0) {
            if (e.includes('password'))
                setShowError('Password should contain 1 upercase, 1 lowercase and 1 number')
            else
                setShowError('Please fill all fields')

        } else {
            setErrors([])
            if (connector === 'MAILCHIMP')
                integration.integrationParams = [{ paramName: 'AUDIENCE_ID', paramValue: selectedId.id, paramText: selectedId.name }]
            else if (connector === 'UNIFI') {
                integration.apiKey = `${name};${password}`
                integration.integrationParams = [{ paramName: 'SITE_ID', paramValue: siteId }]
            }
            const { data } = await api.post('/integrations', integration)
            addIntegration(data)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get('/integrations/mailchimp', { headers: { apiKey: integration.apiKey } })
                const audienceIds = data.lists.map((list, index) => ({ externalId: index, id: list.id, name: list.name }))
                setIds(audienceIds)
                changeSelected(audienceIds[0])
            } catch (e) {
                setServerError('Invalid api key')
            }
        }
        if (connector === 'MAILCHIMP' && integration.apiKey && integration.apiKey.length > 3)
            fetchData()

    }, [integration])

    return (
        <div className='add-integration-popup-item'>
            <div key={venue.externalId} className='add-integration-popup-item-venue' onClick={() => setIsSelected(!isSelected)}>
                <div className='add-integration-popup-item-venue-logo'>
                    <img src={require('../../assets/images/shop.svg')} />
                </div>
                <label>{venue.name}</label>
            </div>

            {connector === 'MAILCHIMP' ?
                <>
                    <label className='input-label'>API key</label>
                    <input placeholder='Enter api key' value={integration.apiKey || ''} onChange={e => changeApiKey(e.target.value)} className={`add-integration-popup-item-input ${errors.length > 0 && errors.includes('apiKey') && 'input-error'}`} />

                    <label className='input-label'>Audience id</label>
                    <div className={`add-integration-popup-item-dropdown ${errors.length > 0 && errors.includes('id') && 'input-error'}`}>
                        <Dropdown options={ids} selected={selectedId || { name: 'Please select audience id' }} onSelect={changeSelected} />
                    </div>

                    <label className='input-label'>Tags</label>
                    <TagsInput tags={integration.tags} setTags={tags => setIntegration({ ...integration, tags: tags })} />
                </>
                :
                connector === 'UNIFI' ?
                    <>
                        <label className='input-label'>Controller URL</label>
                        <input placeholder='Enter controller url' value={integration.apiEndpoint || ''} onChange={e => changeApiEndpoint(e.target.value)} className={`add-integration-popup-item-input ${errors.length > 0 && errors.includes('apiEndpoint') && 'input-error'}`} />
                        <label className='input-label'>Username</label>
                        <input placeholder='Enter username' value={name || ''} onChange={e => changeName(e.target.value)} className={`add-integration-popup-item-input ${errors.length > 0 && errors.includes('name') && 'input-error'}`} />
                        <label className='input-label'>Password</label>
                        <input placeholder='Enter password' type='password' value={password || ''} onChange={e => changePassword(e.target.value)} className={`add-integration-popup-item-input ${errors.length > 0 && errors.includes('password') && 'input-error'}`} />
                        <label className='input-label'>Site ID</label>
                        <input placeholder='Enter site id' value={siteId || ''} onChange={e => setSiteId(e.target.value)} className={`add-integration-popup-item-input`} />
                    </>
                    :
                    <>
                        Coming soon
                    </>
            }

            {showError && <label className='error-label'>{showError}</label>}
            {serverError && <label className='error-label'>{serverError}</label>}

            <button onClick={save}>SAVE</button>
        </div>
    )
}