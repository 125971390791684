import React from 'react'

import { hexToRGB } from '../../../utils/common'

import DropDownIcon from '../../../icon/drop-down-icon'
import { ReactComponent as ArrowIcon } from '../../../assets/images/arrow.svg';


import '../../../assets/css/design/screens/email-registration-screen.scss'

export default ({ landingPage, settings, logo }) => {

    return (
        <div className='email-registration-screen' style={{ backgroundColor: !landingPage.boxSize || landingPage.boxSize === 'BIG' ? landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#FECC33' : hexToRGB('#FECC33', 0) }}>
            <div className='email-registration-screen-content' style={{
                backgroundColor: landingPage.boxColor,
            }}>
                <div className='registration-top'>
                    <style jsx>{`
                    input::placeholder {
                        color: ${hexToRGB(landingPage.inputColor, 0.6)};
                    }
                    `}</style>
                    <img className='registration-logo' src={logo ? logo : require("../../../assets/images/full-icon.svg")} />
                    <label className='registration-title' style={{ color: landingPage.textColor }}>Connect with email</label>
                    <input placeholder='Enter email' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} />
                    <div className='registration-dropdown' style={{ borderColor: landingPage.inputColor }}>
                        <label style={{ color: landingPage.inputColor }}>Title:</label>
                        <div>
                            <label style={{ color: landingPage.inputColor }}>Mr</label>
                            <DropDownIcon className='preview-dropdown-logo' color={landingPage.inputColor} />
                        </div>
                    </div>
                    <input placeholder='Enter forename' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} />
                    <input placeholder='Enter surname' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} />
                    {settings.requireDob === true &&
                        <div className='birthday' style={{ borderColor: landingPage.inputColor }}>
                            <label style={{ color: landingPage.inputColor }}>Birthday:</label>
                            <label style={{ color: landingPage.inputColor }}>DD/MM/YY</label>
                        </div>
                    }
                    {settings.requireGender === true &&
                        <div className='registration-dropdown' style={{ borderColor: landingPage.inputColor }}>
                            <label style={{ color: landingPage.inputColor }}>Gender:</label>
                            <div>
                                <label style={{ color: landingPage.inputColor }}>male</label>
                                <DropDownIcon className='preview-dropdown-logo' color={landingPage.inputColor} />
                            </div>
                        </div>
                    }
                    {settings.requirePassword === true &&
                        <>
                            <input placeholder='Enter password' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} />
                        </>
                    }
                    {settings.requireMobile === true &&
                        <>
                            <input placeholder='Enter phone number' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} />
                        </>
                    }
                    {settings.requireZipPostcode === true &&
                        <>
                            <input placeholder='Enter postcode' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} />
                        </>
                    }
                    {settings.requireCompanyName === true &&
                        <>
                            <input placeholder='Enter company name' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} />
                        </>
                    }
                </div>
                <div className='registration-bottom'>
                    <div className='register-btn' style={{ backgroundColor: landingPage.buttonColor }}>
                        <label>Register</label>
                        <ArrowIcon className='arrow' />
                    </div>
                    <label className='privacy' style={{ color: landingPage.textColor }}>Click here to read our <a style={{ color: landingPage.linkColor, textDecoration: 'none' }} target="_blank" href='https://unaro.co.uk/privacy-policy'>Privacy Policy</a></label>
                </div>
            </div>
        </div>
    )
}