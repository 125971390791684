import React, { useState } from 'react'

import '../../assets/css/widget/buttoned-input.scss'

export const ButtonedInput = ({placeholder, title, save }) => {

    const [value, setValue] = useState()

    const saveValue = () => {
        if (value && value.length > 0) {
            save(value)
            setValue('')
        }
    }

    return (
        <div className='buttoned-input'>
            <label>{title}</label>
            <input type='search' placeholder={placeholder} value={value || ''} onChange={e => setValue(e.target.value)} />
            <button onClick={saveValue}>+</button>
        </div>
    )
}