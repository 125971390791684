import React, { useState } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import Axios from 'axios';

import { ReactComponent as RememberMeBox } from './../assets/images/remember-me-box.svg';
import { ReactComponent as RememberMeBoxSelected } from './../assets/images/remember-me-box-selected.svg';

import '../assets/css/login.scss'

export const Login = () => {
    const history = useHistory()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [redirect, setRedirect] = useState(false)
    const [showError, setShowError] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)

    const login = async event => {
        try {
            event.preventDefault();
            const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/user/authenticate`, { 'name': email, 'password': password })
            localStorage.setItem('user', JSON.stringify(response.data));

            setRedirect(true)
        } catch (e) {
            setShowError(true)
        }
    }

    const changePassword = (e) => {
        setPassword(e.target.value)
        setShowError(false)
    }

    const changeEmail = (email) => {
        setEmail(email)
        setShowError(false)
    }

    if (redirect)
        return <Redirect to="/dashboard" />
    return (
        <div className='login-page'>
            <div className='login-form'>
                <img className='logo' src={require("../assets/images/full-icon.svg")} />
                <div className={`error-message-container ${!showError && 'error-message-container-hidden'}`}>
                    <label>Invalid username or password</label>
                </div>
                <form onSubmit={login} autocomplete='on'>
                    <input placeholder="Email address" name='login-email' autoComplete="section-email" onChange={e => changeEmail(e.target.value)} />
                    <input className='password-input' name='login-pass' autoComplete="section-password" type='password' placeholder='Password' onChange={e => changePassword(e)} />
                    <a href='#/password/new'>Forgotten password?</a>
                    <input type='submit' value='Login' />
                </form>
                <div className='remember-me'>
                    {rememberMe ? <RememberMeBoxSelected className='remember-me-logo' onClick={() => setRememberMe(false)} /> :
                        <RememberMeBox className='remember-me-logo' onClick={() => setRememberMe(true)} />
                    }
                    <label>Remember me</label>
                </div>
                {/* <label className='sign-up'>Don’t have an account? <span onClick={() => history.push('/signup')}>sign up</span></label> */}
            </div>
        </div>
    )
}