import React, { useState } from 'react'

import { RadioButton } from './radioButton'
import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';

import '../../assets/css/widget/select-device-popup.scss'

export const SelectDevicePopup = ({ close, next }) => {

    const types = [
        {
            id: 0,
            name: 'ubiquiti',
            source: 'unifi',
            logo: require("../../assets/images/ubiquiti.png"),
            selected: true
        },
        {
            id: 1,
            name: 'draytek',
            source: 'draytek',
            logo: require("../../assets/images/draytek.png"),
            selected: false
        }
    ]

    const [importTypes, setImportTypes] = useState(types)

    const selectType = (id) => {
        setImportTypes([...importTypes.map(x => (x.id === id ? { ...x, selected: true } : { ...x, selected: false }))])
    }

    return (
        <div className='select-device-popup'>
            <div className="select-device-popup-content">
                <CloseIcon className='close-icon' onClick={close} />
                <label>Select type of device</label>
                <div className='selectors'>
                    {importTypes.map(type =>
                        <div key={type.id} className='types' onClick={() => selectType(type.id)}>
                            <img src={type.logo} />
                            <RadioButton selected={type.selected} onClick={() => selectType(type.id)} />
                        </div>
                    )}
                </div>
                <div className='actions'>
                    <button className='back' onClick={close} >Back</button>
                    <button onClick={() => next(importTypes.find(type => type.selected).source)}>Continue</button>
                </div>
            </div>
        </div>
    )
}