import React from 'react'

import '../../assets/css/widget/switch.scss'

import { ReactComponent as DropdownLogoDown } from '../../assets/images/dropdown-icon-down.svg';
import { ReactComponent as DropdownLogoUp } from '../../assets/images/dropdown-icon-up.svg';


export const Switch = ({ Icon, value, setValue }) => {

    return (
        <div className='switch'>
            <Icon className='switch-icon' fill="#676767" />
            <div className='switch-actions' onClick={() => setValue(!value)}>
                <label>{value ? 'On' : 'Off'}</label>
                <div className='switch-buttons'>
                    <DropdownLogoUp className='switch-button' />
                    <DropdownLogoDown className='switch-button' />
                </div>
            </div>
        </div>
    )
}
