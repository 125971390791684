import React, { useState, useEffect } from 'react'

import '../../../assets/css/sms/contact/assign-tag-popup.scss'

export default ({ tags, assignTags, close }) => {

    const [selectedTags, setSelectedTags] = useState([])
    const [tagLines, setTagLines] = useState([])
    const [showName, setShowName] = useState()

    const save = async () => {
        await assignTags(selectedTags)
        close()
    }

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    useEffect(() => {
        if (tags) {
            if (tags.length < 9)
                setTagLines([tags])
            else if (tags.length < 19)
                setTagLines([[...tags.slice(0, 8)], [...tags.slice(8, 18)]])
            else
                setTagLines([[...tags.slice(0, 8)], [...tags.slice(8, 18)], [...tags.slice(18, 28)]])
        }
    }, [tags])

    return (
        <div className='assign-tag-popup' onClick={onClick}>
            <div className='assign-tag-popup-content'>
                <div className='assign-tag-top'>
                    <label>Assign tag</label>
                    <img src={require('../../../assets/images/delete.svg')} onClick={close} />
                </div>
                <div className='assign-tag-content'>
                    <div className='tag-colors'>
                        {tagLines.map((tagLine, i) =>
                            <div className='tags-line'>
                                {i === 0 && <label className='tag-name'>Your tags:</label>}
                                {
                                    tagLine.map(tag =>
                                        <div
                                            className={`tag-color-container ${selectedTags.find(st => st.externalId === tag.externalId) && 'tag-color-container-selected '}`}
                                            onClick={() => selectedTags.find(st => st.externalId === tag.externalId) ? setSelectedTags(selectedTags.filter(st => st.externalId !== tag.externalId)) : setSelectedTags([...selectedTags, tag])}
                                            onMouseEnter={() => setShowName(tag.externalId)}
                                            onMouseLeave={() => setShowName(null)}
                                        >
                                            <div style={{ backgroundColor: tag.color, borderColor: tag.color === '#ffffff' ? '#C4C4C4' : tag.color }} className='tag-color' />
                                            {showName === tag.externalId && <label className='tag-name'>{tag.name}</label>}
                                        </div>)
                                }
                            </div>

                        )
                        }
                    </div>
                    <div className='assign-tag-actions'>
                        <button onClick={close}>Cancel</button>
                        <button onClick={save}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    )
}