import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";

import api from '../../utils/api'
import VenueItem from './venueItem'
import NetworkItem from './venueNetworkItem'
import { ShowDevices } from '../devices/showDevices'
import AddNetworkPopup from '../widget/addNetworkPopup'

import '../../assets/css/venue/venue-details.scss'

export default () => {

    const { venueId } = useParams();

    const [venue, setVenue] = useState({})
    const [devices, setDevices] = useState([])
    const [name, setName] = useState("")
    const [navigation, setNavigation] = useState({ total: 1, current: 0 })
    const [showAddNetworkPopup, setShowAddNetworkPopup] = useState(false)
    const [networks, setNetworks] = useState([])

    const fetchVenue = async () => {
        const { data } = await api.get(`/organisations/${venueId}`)
        setVenue(data)
    }

    const fetctDevices = async (page) => {
        const result = await api.get(`/organisations/${venueId}/devices?size=6&page=${page}&name=${name}`)

        setDevices(result.data.content)
        setNavigation({ total: Math.ceil(result.data.total / 6), current: result.data.pageable.page })
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetctDevices(0)
        }
        fetchData()
    }, [venueId, name])

    useEffect(() => {
        const fetchData = async () => {
            await fetchVenue()
        }
        fetchData()
    }, [venueId])

    useEffect(() => {
        const fetchData = async () => {
            const result = await api.get(`/organisations/${venueId}/networks`)
            setNetworks(result.data)
        }
        fetchData()
    }, [venueId])

    const changePage = (page) => {
        fetctDevices(page)
    }

    const addNetwork = async network => {
        setNetworks([...networks, network])
        setShowAddNetworkPopup(false)
        await api.post(`/organisations/${venueId}/networks`, network)
    }

    const changeGuest = async network => {
        setNetworks([...networks.map(n => n._id === network._id ? { ...n, is_guest: !n.is_guest } : n)])
        const request = { ...network, is_guest: !network.is_guest }
        await api.put(`/organisations/${venueId}/network/${network._id}`, request)
    }

    return (
        <div className='venue-details'>
            <div className='venue-details-venue-network'>
                <div className='venue-details-venue'>
                    <label className='venue-details-title'>Venue</label>
                    <VenueItem venue={venue} setVenue={setVenue} hideRemove={true} hideMore={true} />
                </div>
                <div className='venue-details-network'>
                    <label className='venue-details-title venue-details-title-network'>Network</label>
                    {networks.map(network => <NetworkItem key={network._id} name={network.name} on={network.is_guest} changeOn={() => changeGuest(network)} />)}
                    <button className='create-network-btn' onClick={() => setShowAddNetworkPopup(true)}>Create new network</button>
                </div>
            </div>
            <div className='venue-details-devices'>
                <label className='venue-details-title'>Devices</label>
                <ShowDevices
                    devices={devices}
                    setDevices={setDevices}
                    setName={setName}
                    navigation={navigation}
                    setNavigation={setNavigation}
                    changePage={changePage}
                    venue={venueId}
                />
            </div>
            {showAddNetworkPopup && <AddNetworkPopup close={() => setShowAddNetworkPopup(false)} save={addNetwork} />}
        </div>
    )
}