import React from 'react';
import HttpsRedirect from 'react-https-redirect';

import './App.scss';

import { Routes } from './routes/router'

const App = () => {
  return (
    <div className="App">
      <HttpsRedirect>
        <Routes />
      </HttpsRedirect>
    </div>
  );
}

export default App