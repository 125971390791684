import React, { useRef, useState, useEffect } from 'react'

import '../../../assets/css/main-dashboard/widgets/customers.scss'

export default ({ style, connectedCustomers = 0, newCustomers = 0, returnRate = 0, remove, edit }) => {
    const ref = useRef()

    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])

    return (
        <div className={`customers-widget ${edit && 'customers-widget-edit'}`} style={style} ref={ref}>
            <div className='customers-widget-container'>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                <img className='customers-widget-icon' src={require('../../../assets/images/customer-icon.svg')} />
                <label className='customers-widget-title bordered'>Customers</label>
                <div className='customers-widget-content'>
                    <label style={{ fontSize: (height / 12) }} className='customers-widget-value'>{connectedCustomers}</label>
                    <label className='customers-widget-title'>Currently Connected</label>
                    <label style={{ fontSize: (height / 12) }} className='customers-widget-value'>{newCustomers}</label>
                    <label className='customers-widget-title'>New Customers Today</label>
                    <label style={{ fontSize: (height / 12) }} className='customers-widget-value'>{returnRate}%</label>
                    <label className='customers-widget-title'>Return Rate</label>
                </div>
            </div>

        </div>
    )
}