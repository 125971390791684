import React, { useState, useEffect } from 'react'
import uuid from 'react-uuid'

import { FileSelector } from '../widget/fileSelector'
import { Switch } from '../widget/switch'
import { ColorPicker } from '../widget/colorPicker'
import { TextSwitch } from '../widget/textSwitch';
import { PercentSlider } from '../widget/slider';
import api, { cloudinaryApi } from '../../utils/api'

import { ReactComponent as FacebookLogo } from '../../assets/images/facebook-icon.svg';
import { ReactComponent as TwitterLogo } from '../../assets/images/twitter.svg';
import { ReactComponent as LinkedInLogo } from '../../assets/images/linkedin-icon.svg';
import { ReactComponent as EmailLogo } from '../../assets/images/email-icon.svg';

import '../../assets/css/design/edit-landing-page-design.scss'

export const EditLandingPageDesign = ({ landingPage, setLandingPage, logoText, setLogoText, setLogoUrl, backgroundText, setBackgroundText, setBackgroundUrl, setEditDesign, save }) => {
    const [socialColor, setSocialColor] = useState()

    const getSignature = async (type, folder, trackingKey) => {
        const result = await api.get(`/upload/sign?mediaGroupExternalId=${landingPage.externalId}&mediaUsageType=${type}&folder=${folder}&trackingKey=${trackingKey}`)
        return result.data
    }

    const uploadToCloudinary = async (file, signature) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('api_key', signature['apiKey'])
        formData.append('type', 'upload')
        formData.append('folder', signature['uploadOptions']['folder'])
        formData.append('context', signature['context'])
        formData.append('timestamp', signature['timestamp'].toString())
        formData.append('signature', signature['signature'])

        const response = await cloudinaryApi.post(`/${signature.cloudName}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return response.data
    }

    const uploadFile = async (type, folder, file) => {
        const trackingKey = uuid()
        const signature = await getSignature(type, folder, trackingKey)
        return await uploadToCloudinary(file, signature)
    }

    const uploadLogo = async file => {
        const { public_id, url } = await uploadFile('LOGO', 'logo', file)
        setLandingPage({ ...landingPage, logoExternalId: public_id })
        setLogoText(file.name)
        setLogoUrl(url)
    }

    const uploadBackground = async file => {
        const { public_id, url } = await uploadFile('BACKGROUND', 'bg', file)
        setLandingPage({ ...landingPage, bkgrdExternalId: public_id })
        setBackgroundText(file.name)
        setBackgroundUrl(url)
    }

    const changeSocialColor = color => {
        setLandingPage({ ...landingPage, facebookColor: color, twitterColor: color, linkedinColor: color })
        setSocialColor(color)
    }

    return (
        <div className='edit-landing-page-design'>
            <div className='landing-page-name-input'>
                <label>Name:</label>
                <input value={landingPage.name || ''} onChange={e => setLandingPage({ ...landingPage, name: e.target.value })} />
            </div>
            <label className='landing-page-edit-subtitle'>Design - Landing Page</label>
            <p className='landing-page-edit-description'>Use the form below to upload and select assets for your landing page.</p>
            <FileSelector title='Select logo:' className='file-selector' fileName={logoText} setFile={uploadLogo} />
            <FileSelector title='Select background:' className='file-selector' fileName={backgroundText} setFile={uploadBackground} />
            <hr className='edit-landing-page-menu-line' />
            <label className='landing-page-edit-subtitle'>Menu preferences</label>
            <p className='landing-page-edit-description'>Please use the controls below to style and enable login options on your landing page.</p>
            <div className='landing-page-edit-social-switch-container'>
                <Switch Icon={FacebookLogo} value={landingPage.facebook} setValue={value => setLandingPage({ ...landingPage, facebook: value })} />
                <Switch Icon={TwitterLogo} value={landingPage.twitter} setValue={value => setLandingPage({ ...landingPage, twitter: value })} />
            </div>
            <div className='landing-page-edit-social-switch-container'>
                <Switch Icon={LinkedInLogo} value={landingPage.linkedin} setValue={value => setLandingPage({ ...landingPage, linkedin: value })} />
                <Switch Icon={EmailLogo} value={landingPage.email} setValue={value => setLandingPage({ ...landingPage, email: value })} />
            </div>
            <hr className='edit-landing-page-menu-line' />
            <label className='landing-page-edit-subtitle'>Page styling</label>
            <p className='landing-page-edit-description'>Please use the controls below to style text, hyperlinks and backgrounds.</p>
            <PercentSlider title='Menu Box Opacity:' value={1 - landingPage.menuOpacity} setValue={value => setLandingPage({ ...landingPage, menuOpacity: 1 - value })} />
            <ColorPicker title='Box Color:' value={landingPage.boxColor} setValue={value => setLandingPage({ ...landingPage, boxColor: value })} />
            <TextSwitch title='Box Size:' value={landingPage.boxSize || 'BIG'} values={['BIG', 'SMALL']} setValue={value => setLandingPage({ ...landingPage, boxSize: value })} />
            {landingPage.boxSize === 'SMALL' &&
                <>
                    <TextSwitch title='Box Border Visibility:' value={landingPage.boxBorderVisibility} values={['HIDE', 'SHOW']} setValue={value => setLandingPage({ ...landingPage, boxBorderVisibility: value })} />
                    {landingPage.boxBorderVisibility === 'SHOW' &&
                        <ColorPicker title='Box Border Color:' value={landingPage.boxBorderColor} setValue={value => setLandingPage({ ...landingPage, boxBorderColor: value })} />
                    }
                </>
            }
            {(landingPage.facebook || landingPage.twitter || landingPage.linkedin) && <ColorPicker title='Social Color:' value={socialColor || '#ffffff'} setValue={changeSocialColor} />}
            {landingPage.facebook && <ColorPicker title='Facebook Color:' value={landingPage.facebookColor || '#ffffff'} setValue={value => setLandingPage({ ...landingPage, facebookColor: value })} />}
            {landingPage.twitter && <ColorPicker title='Twitter Color:' value={landingPage.twitterColor || '#ffffff'} setValue={value => setLandingPage({ ...landingPage, twitterColor: value })} />}
            {landingPage.linkedin && <ColorPicker title='Linkedin Color:' value={landingPage.linkedinColor || '#ffffff'} setValue={value => setLandingPage({ ...landingPage, linkedinColor: value })} />}

            <ColorPicker title='Text Color:' value={landingPage.textColor} setValue={value => setLandingPage({ ...landingPage, textColor: value })} />
            <ColorPicker title='Input Color:' value={landingPage.inputColor} setValue={value => setLandingPage({ ...landingPage, inputColor: value })} />
            <ColorPicker title='Link Color:' value={landingPage.linkColor} setValue={value => setLandingPage({ ...landingPage, linkColor: value })} />
            <ColorPicker title='Link Hover Color:' value={landingPage.linkHoverColor} setValue={value => setLandingPage({ ...landingPage, linkHoverColor: value })} />
            <ColorPicker title='Error Text Color:' value={landingPage.errorTextColor} setValue={value => setLandingPage({ ...landingPage, errorTextColor: value })} />
            <ColorPicker title='Button Color:' value={landingPage.buttonColor} setValue={value => setLandingPage({ ...landingPage, buttonColor: value })} />
            <ColorPicker title='Go Button Color:' value={landingPage.goButtonColor} setValue={value => setLandingPage({ ...landingPage, goButtonColor: value })} />
            <ColorPicker title='Logo Color:' value={landingPage.logoColor || '#000000'} setValue={value => setLandingPage({ ...landingPage, logoColor: value })} />
            <ColorPicker title='Wait Indicator Color:' value={landingPage.waitIndicatorColor} setValue={value => setLandingPage({ ...landingPage, waitIndicatorColor: value })} />
            <div className='landing-page-name-input'>
                <label className='landing-page-text-input'>Top text:</label>
                <input value={landingPage.welcomeText || ''} onChange={e => setLandingPage({ ...landingPage, welcomeText: e.target.value })} />
            </div>
            <div className='landing-page-name-input'>
                <label className='landing-page-text-input'>Middle text:</label>
                <input value={landingPage.descriptionText || ''} onChange={e => setLandingPage({ ...landingPage, descriptionText: e.target.value })} />
            </div>
            {/* <hr className='edit-landing-page-menu-line' />
            <label className='landing-page-edit-subtitle'>Preview on device</label>
            <p className='landing-page-edit-description'>Please enter an email address below to preview the landing page on a device.</p>
            <div className='edit-landing-page-send'>
                <input placeholder='Email address' />
                <button>Send</button>
            </div> */}
            <hr className='edit-landing-page-menu-line' />
            <button className='edit-landing-page-save-btn' onClick={save}>Save changes</button>
        </div>
    )
}
