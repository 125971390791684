import React from 'react'

import { ReactComponent as FacebookIcon } from '../../../assets/images/facebook.svg';
import { ReactComponent as TwitterLogo } from '../../../assets/images/twitter.svg';
import { ReactComponent as LinkedInLogo } from '../../../assets/images/linkedin-icon.svg';
import UnaroIcon from '../unaroIcon'

import { hexToRGB } from '../../../utils/common'

import '../../../assets/css/design/screens/login-screen.scss'

export default ({ landingPage, logoUrl }) => {
    return (
        <div className='login-screen' style={{ backgroundColor: !landingPage.boxSize || landingPage.boxSize === 'BIG' ? landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff' : hexToRGB('#FFFFFF', 0) }}>
            <div className='phone-preview-content-top'>
                <label style={{ color: landingPage.textColor, marginTop: '10px', textAlign: 'center' }} className='phone-preview-text'>{landingPage.welcomeText || ''}</label>
                <div className='small-box' style={{
                    backgroundColor: landingPage.boxSize === 'SMALL' ? landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff' : hexToRGB('#FFFFFF', 0),
                    border: landingPage.boxSize === 'SMALL' && landingPage.boxBorderVisibility === 'SHOW' ? `1px solid ${landingPage.boxBorderColor}` : 'none'
                }}>
                    {logoUrl ? <img className='phone-preview-logo' src={logoUrl} /> : <div className='logo-placeholder' />}
                    <label style={{ color: landingPage.textColor, marginTop: '10px', textAlign: 'center' }}>{landingPage.descriptionText || ''}</label>
                    <div className='phone-preview-social'>
                        {landingPage.facebook === true && <FacebookIcon width='50px' height='50px' fill={landingPage.facebookColor || '#ffffff'} style={{ margin: '5px' }} />}
                        {landingPage.twitter === true && <TwitterLogo width='50px' height='50px' fill={landingPage.twitterColor || '#ffffff'} style={{ margin: '5px' }} />}
                        {landingPage.linkedin === true && <LinkedInLogo width='50px' height='50px' fill={landingPage.linkedinColor || '#ffffff'} style={{ margin: '5px' }} />}
                    </div>
                    {landingPage.email === true &&
                        <div className='phone-preview-register-by-email-container'>
                            {(landingPage.facebook || landingPage.twitter || landingPage.linkedin) && <label style={{ color: landingPage.textColor, marginTop: '10px', marginBottom: '10px' }}>Or</label>}
                            <div className='phone-preview-register-by-email'>
                                <input placeholder='Email' />
                                <button style={{ backgroundColor: landingPage.goButtonColor }} className='phone-preview-go-btn'>Go</button>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className='powered-by'>
                <label style={{ color: landingPage.logoColor || '#000000', fontSize: '12px' }}>POWERED BY</label>
                <div className='powered-by-unaro-icon'>
                    <UnaroIcon color={landingPage.logoColor || '#000000'} />
                </div>
            </div>
        </div>
    )
}