import React from 'react'

import { BackButton } from './widget/backButton'

import '../assets/css/not-available.scss'

export default ({ title, icon, text, locked }) => {

    return (
        <div className='not-available'>
            <BackButton />
            <div className='content'>
                <img src={require('../assets/images/comming-soon.svg')} className={`comming-soon ${locked && 'comming-soon-locked'}`} />
                <img src={icon} className='icon' />
                <label className='title'>{title}</label>
                <label className='text'>{text}</label>
            </div>
        </div>
    )
}