import React, { useState } from 'react'
import { Dropdown } from '../../../../components/widget/dropdown'
import SaveTemplatePopup from './saveTemplatePopup'
import api from '../../../../utils/api'

import '../../../../assets/css/sms/campaign/create/edit-message.scss'

export default ({ saveTemplate, changeMessage, campaignMessage, close }) => {

    const [message, setMessage] = useState(campaignMessage)
    const [personalisations, setPersonalisations] = useState([
        {
            externalId: '0',
            name: 'Title',
            value: 'title'
        },
        {
            externalId: '1',
            name: 'Forename',
            value: 'forename'
        },
        {
            externalId: '2',
            name: 'Surname',
            value: 'surname'
        },
        {
            externalId: '3',
            name: 'DOB',
            value: 'dob'
        }
    ])
    const [error, setError] = useState()
    const [showSaveTemplatePopup, setShowSaveTemplatePopup] = useState(false)

    const changeValue = text => {
        if (text.length <= 160)
            setMessage(text)
        setError(null)
    }

    const saveMessage = () => {
        if (!message || message.length === 0)
            setError('Message is empty')
        else {
            changeMessage(message)
            close()
        }
    }

    return (
        <div className='edit-message'>
            <div className='edit-message-top'>
                <label className='title'>Compose Message</label>
                <button onClick={() => setShowSaveTemplatePopup(true)}>Save as template</button>
            </div>
            <label className='subtitle'>Text message</label>
            <textarea style={{ borderColor: error ? '#EA5454' : '#DBDBDB' }} value={message || ''} onChange={e => changeValue(e.target.value)} />
            <label className='characters'>Characters - {message ? message.length : 0}/160</label>
            <label className='subtitle'>Insert personalisation</label>
            <div className='personalisations'>
                <Dropdown className='personalisations-dd' selected={{ name: 'Select...' }} options={personalisations}
                    onSelect={personalisation => setMessage(message + ' {{' + personalisation.value + '}}')} />
                <div className='info-container'>
                    <div className='info'>
                        <label>i</label>
                    </div>
                </div>
                <button>Add</button>
            </div>
            {error && <label className='error-label'>{error}</label>}
            <div className='edit-message-actions'>
                <button onClick={close}>Back</button>
                <button onClick={saveMessage}>Save</button>
            </div>
            {showSaveTemplatePopup && <SaveTemplatePopup close={() => setShowSaveTemplatePopup(false)} save={template => saveTemplate({ ...template, messageBody: message })} />}
        </div>
    )
}