import React from 'react'

import '../../../assets/css/design/screens/consent-screen.scss'

export default ({ landingPage }) => {
    return (
        <div className='consent-screen'>
            <div className='consent-screen-top'>
                <div className='consent-screen-title'>
                    <label>WIFI TERMS OF USE</label>
                </div>
                {/* <div className='consent-screen-consents'>
                    <label className='consent-screen-consent consent-screen-consent-selected'>Data</label>
                    <label className='consent-screen-consent'>Marketing</label>
                    <label className='consent-screen-consent'>Feedback</label>
                </div> */}
                <div className='consent-screen-data'>
                    <label className='consent-screen-data-title'>Data</label>
                    <label className='consent-screen-data-text'>We collect personal information about you and your device when you use the WiFi. The owner of this venue will also have access to the collected information. For more details on the data we collect and how we use it, see our Privacy Policy.</label>
                </div>
                <div className='consent-screen-tick-boxes'>
                    <div className='consent-screen-tick-box'>
                        <input type="checkbox" />
                        <label>Tick this box to receive exclusive updates, news and special offers.</label>
                    </div>
                    <div className='consent-screen-tick-box consent-screen-tick-box-terms'>
                        <input type="checkbox" />
                        <label>Tick this box to agree to Unaro <a href='#'>Terms and Conditions</a> and <a href='#'>Privacy Policy</a></label>
                    </div>
                </div>
            </div>

            <div className='consent-screen-bottom' style={{ backgroundColor: landingPage.goButtonColor }}>
                <label>ACCEPT AND CONTINUE WITH EMAIL</label>
            </div>
        </div>
    )
}