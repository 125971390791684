import React, { useState, useEffect, useContext } from 'react'

import api from '../../utils/api'
import PlansPopup from './plansPopup'
import PaymentPopup from './paymentPopup'
import PaymentDonePopup from './paymentDonePopup'
import { VenuesContext } from '../../context/venueContext'

import '../../assets/css/billing/subscriptions.scss'

import { ReactComponent as BulletIcon } from '../../assets/images/bullet.svg';

export default ({ userOrganisationId }) => {

    const user = JSON.parse(localStorage.getItem('user'))
    const adminUser = user.organisations[0].orgType !== 'ORGANISATION'
    const selected = JSON.parse(localStorage.getItem('selected-organisation'))
    const [selectedPlan, setSelectedPlan] = useState()
    const [showPlans, setShowPlans] = useState(false)
    const [showPaymentPopup, setShowPaymentPopup] = useState(false)
    const [showPaymentDone, setShowPaymentDone] = useState(false)
    const [paymentResponse, setPaymentResponse] = useState()
    const [plans, setPlans] = useState()
    const { licences, setLicences } = useContext(VenuesContext)

    const organisationId = adminUser && selected && selected.orgType === 'ORGANISATION' ? selected.externalId : userOrganisationId

    const [userLicences, setUserLicenses] = useState([
        {
            id: '1',
            licenceType: "CONNECT",
            icon: require('../../assets/images/connect-icon.svg'),
            name: 'Connect',
            active: false,
            available: true
        },
        {
            id: '2',
            licenceType: "TT",
            icon: require('../../assets/images/tt-icon.svg'),
            name: 'Track & trace',
            active: false,
            available: true
        },
        {
            id: '3',
            licenceType: "SMS",
            icon: require('../../assets/images/sms-icon.svg'),
            name: 'SMS',
            active: false,
            available: true
        },
        {
            id: '4',
            licenceType: "CRM",
            icon: require('../../assets/images/crm-icon.svg'),
            name: 'CRM',
            active: false,
            available: false
        },
        {
            id: '5',
            licenceType: "SEND",
            icon: require('../../assets/images/send-icon.svg'),
            name: 'Send',
            active: false,
            available: false
        },
        {
            id: '6',
            licenceType: "MARKETING",
            icon: require('../../assets/images/marketing-icon.svg'),
            name: 'Marketing',
            active: false,
            available: false
        }
    ])

    const getLicenses = async organisationId => {
        const { data } = await api.get(`organisations/${organisationId}/licences`)
        const currentDate = Math.floor(Date.now() / 1000)
        setUserLicenses(userLicences.map(l => {
            const license = data.find(d => d.licenceType === l.licenceType)
            if (license)
                return { ...l, id: license.id, active: license.startDate < currentDate && license.expiryDate > currentDate, startDate: license.startDate }
            else
                return l
        }
        ))

    }

    useEffect(() => {
        const fetchData = async () => {
            getLicenses(organisationId)
        }
        fetchData()
    }, [organisationId])

    const addLicense = async license => {
        const startDate = new Date().getTime() / 1000
        const expiryDate = new Date()
        expiryDate.setDate(expiryDate.getDate() + 365);

        const request = {
            startDate: startDate,
            expiryDate: expiryDate.getTime() / 1000,
            licenceType: license.licenceType,
            licence: "test"
        }

        const { data } = await api.post(`organisations/${organisationId}/licences`, [request])
        setUserLicenses(userLicences.map(l => l.licenceType === license.licenceType ? { ...l, active: true, id: data[0].id, startDate: startDate } : l))
    }

    const removeLicense = async license => {
        await api.delete(`/organisations/${organisationId}/licences/${license.id}`)
        setUserLicenses(userLicences.map(l => l.licenceType === license.licenceType ? { ...l, active: false } : l))
    }

    const viewPlans = async license => {
        const { data } = await api.get(`/products`)
        setPlans(data.filter(plan => plan.productType === license.licenceType))
        setShowPlans(true)
    }

    const buy = async plan => {
        setShowPlans(false)
        const { data } = await api.post(`/organisation/${userOrganisationId}/paymentintent`, { amount: plan.price, currency: plan.currency, productId: plan.id })
        setSelectedPlan({ ...plan, clientSecret: data.token })
        setShowPaymentPopup(true)
    }

    const paymentDone = response => {
        setPaymentResponse(response)
        setShowPaymentPopup(false)
        setShowPaymentDone(true)
    }

    const retryPayment = () => {
        setShowPaymentDone(false)
        setShowPaymentPopup(true)
    }

    const sendOrder = async paymentResponse => {
        await api.post(`/organisation/${organisationId}/order`,
            {
                productId: selectedPlan.id,
                productName: selectedPlan.name,
                amount: selectedPlan.price,
                currency: selectedPlan.currency,
                term: selectedPlan.term,
                productType: selectedPlan.productType,
                paymentIntentId: paymentResponse.paymentIntent.id,
                paymentMethodId: paymentResponse.paymentIntent.payment_method
            })
        const startDate = Math.floor((new Date().getTime() / 1000) - 100)
        const expiryDate = new Date()
        expiryDate.setDate(expiryDate.getDate() + 1);
        setLicences([...licences, { expiryDate: Math.floor(expiryDate.getTime() / 1000), startDate: startDate, licence: "licence", licenceType: selectedPlan.productType }])
    }

    return (
        <div className='subscriptions-page'>
            <div className='subscriptions-header'>
                <label>Plans</label>
                <label>License</label>
                <label>Active since</label>
                <label>Status</label>
            </div>
            <div className='subscriptions'>
                {userLicences.map(license =>
                    <div key={license.id} className='subscription'>
                        <div className='subscription-field plan'>
                            <img src={license.icon} />
                            <label>{license.name}</label>
                        </div>
                        {adminUser && selected && selected.orgType === 'ORGANISATION' ?
                            <div className='subscription-field add-license'> {license.available ? license.active ? <label className='owned' onClick={() => removeLicense(license)} /> : <label onClick={() => addLicense(license)}>Add license</label> : <label>N/A</label>}</div>
                            :
                            <div className='subscription-field add-license'> {license.available && license.active ? <label className='owened-user' /> : <label>N/A</label>}</div>
                        }
                        <label className='subscription-field'>{license.startDate ? new Date(license.startDate * 1000).toJSON().slice(0, 10).split('-').reverse().join('/') : 'N/A'}</label>
                        <div className='subscription-field'>
                            <BulletIcon className={license.available ? license.active || adminUser && (!selected || selected.orgType !== 'ORGANISATION') ? 'active' : 'expired' : 'expired'} />
                            <label>{license.available ? adminUser && (!selected || selected.orgType !== 'ORGANISATION') ? 'Admin' : license.active ? 'Active' : 'Inactive' : 'Comming soon'}</label>
                        </div>
                    </div>
                )}
            </div>

            {plans && showPlans && <PlansPopup
                title='Unaro Connect Plans'
                subtitle={<label>Please choose from one of the following <span>Yearly</span> plans</label>}
                plans={plans}
                buy={buy}
                close={() => setShowPlans(false)} />}
            {showPaymentPopup && selectedPlan && <PaymentPopup plan={selectedPlan} close={() => setSelectedPlan(null)} sendOrder={sendOrder} done={paymentDone} />}
            {showPaymentDone && paymentResponse && <PaymentDonePopup close={() => setShowPaymentDone(false)} retry={retryPayment} paymentResponse={paymentResponse} type={selectedPlan.productType} />}
        </div>
    )
}
