import React from 'react'

import '../../assets/css/widget/social-preview.scss'

export const SocialPreview = ({ Icon, color }) => {
    return (
        <div className='social-preview' style={{ backgroundColor: color }}>
            <Icon className='social-preview-icon' />
        </div>
    )
}