import React, { useEffect, useState, useContext } from 'react'
import { Line } from 'react-chartjs-2';
import { VenuesContext } from '../context/venueContext'

import api from '../utils/api'

import { chartDefaults } from '../utils/defaults'

import '../assets/css/chart.scss'

export const Chart = ({ organisationId }) => {
    const admin = JSON.parse(localStorage.getItem('user')).organisations[0].orgType !== 'ORGANISATION' && !localStorage.getItem('selected-organisation')
    const [statistics, setStatistics] = useState([])
    const { locked } = useContext(VenuesContext)

    const getDayLabels = () => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() - 1)
        return Array.from(Array(24).keys()).map(k => (currentDate.getHours() + k + 1) % 24)
    }

    const getWeekLabels = () => {
        return Array.from(Array(8).keys()).map(a => {
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 7 + a)
            return currentDate
        }).map(d => d.getDate())
    }

    const getMonthLabels = () => {
        const now = new Date()
        const daysInMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate()
        return Array.from(Array(daysInMonth).keys()).map(a => {
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - daysInMonth + a + 1)
            return currentDate
        }).map(d => d.getDate())
    }

    const getYearLabels = () => {
        return Array.from(Array(13).keys()).map(a => {
            const currentDate = new Date()
            currentDate.setMonth(currentDate.getMonth() - 11 + a, 1)
            return currentDate
        }).map(d => d.getMonth() === 0 ? 12 : d.getMonth())
    }

    const [chartLabels, setChartLabels] = useState(getDayLabels())
    const [currentUnit, setCurrentUnit] = useState('month')

    const chartButtons = [
        {
            id: 0,
            text: "Day",
            unit: "day",
            selected: false
        },
        {
            id: 1,
            text: "Week",
            unit: "week",
            selected: false
        },
        {
            id: 2,
            text: "Month",
            unit: "month",
            selected: true
        },
        {
            id: 3,
            text: "Year",
            unit: "year",
            selected: false
        }
    ]

    const [items, setItems] = useState(chartButtons)

    const changeLabels = (unit) => {
        if (unit === 'day')
            setChartLabels(getDayLabels())
        else if (unit === 'week')
            setChartLabels(getWeekLabels())
        else if (unit === 'month')
            setChartLabels(getMonthLabels)
        else if (unit === 'year')
            setChartLabels(getYearLabels())
    }

    const changeSelected = (index) => {
        const unit = items[index].unit
        if (unit !== currentUnit) {
            items.map(item => item.selected = false)
            items[index].selected = true
            setItems([...items])
            setCurrentUnit(unit)
            fetchStatistics(unit)
            changeLabels(unit)
        }
    }

    const getStartDate = (unit) => {
        if (unit === 'day') {
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - 1)
            startDate.setHours(startDate.getHours(), 0, 0, 0);
            return startDate.getTime() / 1000

        } else if (unit === 'week') {
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - 7)
            startDate.setHours(0, 0, 0, 0)
            return startDate.getTime() / 1000

        } else if (unit === 'month') {
            const now = new Date()
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate())
            startDate.setHours(0, 0, 0, 0)
            return startDate.getTime() / 1000
        } else if (unit === 'year') {
            const startDate = new Date()
            startDate.setHours(0, 0, 0, 0);
            startDate.setMonth(startDate.getMonth() - 12, 1)
            return startDate.getTime() / 1000
        }
    }

    const getApiUnit = (unit) => {
        if (unit === 'day')
            return 'hourly'
        else if (unit === 'week' || unit === 'month')
            return 'daily'
        else if (unit === 'year')
            return 'monthly'
    }

    const fetchStatistics = async (unit) => {
        if (organisationId != null) {
            const result = await api.get(`/organisation/${organisationId}/analytics?unit=${getApiUnit(unit)}&startDate=${getStartDate(unit)}`)
            setStatistics(result.data)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchStatistics(currentUnit)
        }
        fetchData()
    }, [organisationId])

    const colors = [
        {
            zero: 'rgba(254, 203, 69, 0.75)',
            half: 'rgba(254, 203, 69, 0.5)',
            one: 'rgba(254, 203, 69, 0)'
        },
        {
            zero: 'rgba(242, 151, 57, 0.75)',
            half: 'rgba(242, 151, 57, 0.5)',
            one: 'rgba(242, 151, 57, 0)'
        },
        {
            zero: 'rgba(232, 85, 88, 0.75)',
            half: 'rgba(232, 85, 88, 0.5)',
            one: 'rgba(232, 85, 88, 0)'
        },
        {
            zero: 'rgba(222, 65, 70, 0.75)',
            half: 'rgba(222, 65, 70, 0.5)',
            one: 'rgba(222, 65, 70, 0)'
        },
        {
            zero: 'rgba(0, 0, 0, 0.75)',
            half: 'rgba(0, 0, 0, 0.5)',
            one: 'rgba(0, 0, 0, 0)'
        }
    ]

    const mapToChartData = (canvas, analytics, visits, index) => {
        const dataset = { ...chartDefaults }
        dataset.label = analytics.organisationName
        const visitValues = [...visits]
        if (currentUnit === 'day')
            analytics.visits.forEach(v => visitValues[chartLabels.indexOf(new Date(v.timestamp * 1000).getHours())] = v.number)
        else if (currentUnit === 'week')
            analytics.visits.forEach(v => visitValues[chartLabels.indexOf(new Date(v.timestamp * 1000).getDate())] = v.number)
        else if (currentUnit === 'month')
            analytics.visits.forEach(v => visitValues[chartLabels.indexOf(new Date(v.timestamp * 1000).getDate())] = v.number)
        else if (currentUnit === 'year')
            analytics.visits.forEach(v => visitValues[chartLabels.indexOf(new Date(v.timestamp * 1000).getMonth()) + 1] = v.number)

        dataset.data = visitValues

        if (admin) {
            // dataset.borderColor = getRandomColor()
        }
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height / 2);
        const color = colors[index]
        gradient.addColorStop(0, color.zero);
        gradient.addColorStop(0.5, color.half);
        gradient.addColorStop(1, color.one);

        dataset.backgroundColor = gradient

        return dataset
    }

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const formatLabels = (labels) => {
        if (currentUnit === 'day')
            return labels.map(label => `${label}:00`)
        else if (currentUnit === 'week')
            return labels.map((label, index) => {
                const currentDate = new Date()
                currentDate.setDate(new Date().getDate() - index)
                return days[currentDate.getDay()]
            }).slice().reverse()
        else if (currentUnit === 'month')
            return labels
        else if (currentUnit === 'year')
            return labels.map((label, index) => {
                const currentDate = new Date()
                currentDate.setMonth(new Date().getMonth() - index)
                return `${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`
            }).slice().reverse()
        else
            return []
    }

    const getData = (canvas) => {
        const stats = statistics.slice(0, 5)
        const dataset = {}
        const initialVisits = new Array(31).fill(0)
        dataset.labels = formatLabels(chartLabels)
        dataset.datasets = stats.map((a, index) => mapToChartData(canvas, a, initialVisits, index))
        return dataset
    }
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: statistics.length > 1 ? true : false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                ticks: {
                    min: 0,
                    precision:0
                }
            }]
        }
    }

    return (
        <div className={`chart-container  ${admin && 'chart-container-row'}`}>
            <div className='chart-top'>
                <div className='logo-title'>
                    <div className='logo'>
                        <img src={require("../assets/images/position-icon.svg")} />
                    </div>
                    <label className='visits-title'>Visits</label>
                </div>
                <div className='unit-selector'>
                    {items.map(item =>
                        <button key={item.id} className={`chart-button ${item.selected && 'chart-button-selected'}`} onClick={() => changeSelected(item.id)}>{item.text}</button>)}
                </div>
            </div>
            <div className={`stats-chart ${!admin && locked && 'stats-chart-locked'} ${!admin && 'stats-chart-row'}`}>
                <Line data={getData} options={options} />
            </div>
        </div>

    )
}