import React from 'react'
import { useHistory } from "react-router-dom";

import { Analytics } from '../components/analytics'
import { Map } from '../components/map'

import 'react-dropdown/style.css';
import '../assets/css/dashboard.css'

export const Dashboard = ({ organisationId }) => {
    const history = useHistory()

    const admin = JSON.parse(localStorage.getItem('user')).organisations[0].orgType !== 'ORGANISATION' && !localStorage.getItem('selected-organisation')

    return (
        <div className='dashboard-page'>
            <div className='dashboard-top'>
                <label className='dashboard-title'>{admin ? 'Welcome to your control panel home page' : 'Dashboard'}</label>
                {admin && <button className='add-btn' onClick={() => history.push('/organisation')}>Add a new customer</button>}
            </div>
            <Analytics organisationId={organisationId} />
            {admin && <Map />}
        </div>
    )
} 