import React from 'react'

import '../assets/css/statistics-card.scss'

export const StatisticsCard = ({ statistics }) => {
    const admin = JSON.parse(localStorage.getItem('user')).organisations[0].orgType !== 'ORGANISATION' && !localStorage.getItem('selected-organisation')

    return (
        <div className={`stats-card ${admin && 'stats-card-columns'}`}>
            <div className='statistics-card-top '>
                <div>
                    <div className='logo'>
                        <img src={statistics.bigIcon} alt=''/>
                    </div>
                </div>

                <div className='card-numbers'>
                    <label className='card-title' title={statistics.title}>{statistics.title}</label>
                    <label className='card-number'>{statistics.number}</label>
                </div>
            </div>
            <hr />
            <div className='statistics-card-bottom'>
                <img src={statistics.smallIcon} alt=''/>
                <label>{statistics.text}</label>
            </div>
        </div>
    )
}