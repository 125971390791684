import React from 'react'

import IntegrationsItem from './integrationsItem'

import '../../assets/css/integrations/show-integrations.scss'

export default ({ integrations, createNewIntegration, removeIntegration, updateIntegration }) => {
    return (
        <div className='show-integrations'>
            <div className='show-integrations-top'>
                <label>My connections</label>
                <button onClick={createNewIntegration}>Create new integration</button>
            </div>

            <div className='show-integrations-items'>
                {integrations.length === 0 && <label className='no-integrations'>No integrations</label>}
                {integrations.map(integration => <IntegrationsItem key={integration.externalId} integration={integration} removeIntegration={removeIntegration} updateIntegration={updateIntegration}/>)}
            </div>
        </div>
    )
}