import React, { useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import api from '../utils/api'

import '../components/customers/showCustomers'

import { ShowCustomers } from '../components/customers/showCustomers'
import { CustomerDetails } from '../components/customers/customerDetails'

import { ReactComponent as PersonLogo } from '../assets/images/person-icon.svg';
import { ReactComponent as CalendarLogo } from '../assets/images/interface.svg';
import { ReactComponent as EmailLogo } from '../assets/images/email-icon.svg';
import { ReactComponent as FacebookLogo } from '../assets/images/facebook.svg';
import { ReactComponent as TwitterLogo } from '../assets/images/twitter.svg';
import { ReactComponent as ConnectedLogo } from '../assets/images/connected.svg';

import '../assets/css/customers/customers.scss'

export const Customers = ({ organisationId }) => {

    const match = useRouteMatch()
    const currentPage = localStorage.getItem('current-customers-page')

    const [customers, setCustomers] = useState([])
    const [navigation, setNavigation] = useState({ total: 1, current: currentPage ? currentPage : 0 })

    const lFilter = localStorage.getItem('l-filter')
    const rFilter = localStorage.getItem('r-filter') || ''
    const [name, setName] = useState('')


    const filtersData = [
        {
            id: 0,
            name: 'NOW',
            icon: ConnectedLogo,
            type: 'connected',
            selected: lFilter === 'connected'
        },
        {
            id: 1,
            name: 'RECENT',
            icon: CalendarLogo,
            type: 'recent',
            selected: lFilter === 'recent'
        },
        {
            id: 2,
            name: 'ALL',
            icon: PersonLogo,
            type: '',
            selected: rFilter === ''
        }
    ]

    const usableFilters = [
        {
            id: 3,
            name: 'EMAIL',
            icon: EmailLogo,
            type: 'email',
            selected: rFilter === 'email'
        },
        {
            id: 4,
            name: 'FACEBOOK',
            icon: FacebookLogo,
            type: 'facebook',
            selected: rFilter === 'facebook'
        },
        {
            id: 5,
            name: 'TWITTER',
            icon: TwitterLogo,
            type: 'twitter',
            selected: rFilter === 'twitter'
        }
    ]

    const [filters, setFilters] = useState(filtersData)

    const getFilterTypes = () => {
        return filters.filter(filter => filter.selected).map(filter => filter.type).filter(type => type.length > 0)
    }

    const fetctCustomers = async (page) => {
        const types = getFilterTypes()
        const sort = !types.includes('connected') ? 'forename' : undefined
        var url = `/organisations/${organisationId}/contacts?size=16&page=${page}&name=${name}&type=${types.filter(t => t !== 'recent')}`
        if (types.includes('recent'))
            url += '&sort=lastVisit,DESC'
        if (sort)
            url += `&sort=${sort}`
        const result = await api.get(url)
        setCustomers(result.data.content)
        localStorage.setItem('current-customers-page', result.data.pageable.page)
        setNavigation({ total: Math.ceil(result.data.total / 16), current: result.data.pageable.page })
    }

    useEffect(() => {
        const fetchData = async () => {
            fetctCustomers(currentPage ? currentPage : 0)
        }
        fetchData()
    }, [name, filters, organisationId])

    const fetchLandingPage = async () => {
        const { data: landingPages } = await api.get(`/organisations/${organisationId}/landingPages`)
        if (landingPages.length > 0) {
            const { data: landingPage } = await api.get(`/landingPages/${landingPages[0].externalId}`)
            const { email, facebook, twitter } = landingPage
            let newFilters = []
            if (email === true)
                newFilters = [...newFilters, usableFilters.find(f => f.type === 'email')]
            if (facebook === true)
                newFilters = [...newFilters, usableFilters.find(f => f.type === 'facebook')]
            if (twitter === true)
                newFilters = [...newFilters, usableFilters.find(f => f.type === 'twitter')]

            if (newFilters.length > 0)
                setFilters(filtersData.concat(newFilters))
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchLandingPage()
        }
        fetchData()
    }, [organisationId])

    return (
        <div className='customers-page'>

            <Switch>
                <Route path={`${match.path}/:customerId`}>
                    <CustomerDetails />
                </Route>
                <Route path={match.path}>
                    <ShowCustomers
                        customers={customers}
                        fetctCustomers={fetctCustomers}
                        filters={filters}
                        setFilters={setFilters}
                        setName={setName}
                        navigation={navigation}
                        setNavigation={setNavigation}
                    />
                </Route>
            </Switch>
        </div>
    )
}