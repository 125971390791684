import React, { useState } from 'react'
import Axios from 'axios';
import { useHistory } from "react-router-dom";

import { isValidEmail } from '../utils/common'

import '../assets/css/forgot-password.scss'

export default () => {

    const history = useHistory()

    const [email, setEmail] = useState()

    const [showEmailError, setShowEmailError] = useState(false)

    const reset = async () => {
        if (!email || email.length < 3 || !isValidEmail(email))
            setShowEmailError(true)

        // await Axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/password/reset`, {})

    }

    const changeEmail = (email) => {
        setEmail(email)
        setShowEmailError(false)
    }

    return (
        <div className='forgot-password-page'>
            <div className='forgot-password'>
                <div className='forgot-password-top'>
                    <label>Forgot Password</label>
                </div>
                <hr />
                <div className='forgot-password-input'>
                    <label>Email</label>
                    <input placeholder='Please enter your email' type='text' className={`${showEmailError && 'input-error'}`} value={email || ''} onChange={e => changeEmail(e.target.value)} />
                </div>
                <hr />
                <div className='forgot-password-actions'>
                    <button onClick={reset}>Reset</button>
                    <button onClick={() => history.goBack()} className='back-to-login-btn'>Back to login</button>

                </div>
            </div>
        </div>
    )
}