import React from 'react'
import { useHistory, useLocation } from "react-router-dom";

import { Dropdown } from './widget/dropdown'
import { UserDropdown } from './widget/userDropdown'
import SwitchBtn from './widget/switchBtn'

import { ReactComponent as SwitchOn } from '../assets/images/switch-on.svg';
import { ReactComponent as SwitchOff } from '../assets/images/switch-off.svg';

import '../assets/css/top-bar.scss'

export const TopBar = ({ organisations, selected, setSelected, editMode, setEditMode }) => {
    const admin = JSON.parse(localStorage.getItem('user')).organisations[0].orgType !== 'ORGANISATION' && !localStorage.getItem('selected-organisation')

    const location = useLocation()
    const history = useHistory()

    const exitOrganisation = () => {
        localStorage.removeItem('selected-organisation')
        localStorage.removeItem('current-organisation')
        history.replace('/dashboard')
        localStorage.removeItem('admin-page')
        window.location.reload();
    }

    const onSelect = (option) => {
        setSelected(option)
        // if (location.pathname.lastIndexOf('/') > 0)
        //     history.replace(location.pathname.substr(0, location.pathname.lastIndexOf('/')))
        localStorage.setItem('current-organisation', option.externalId)
        localStorage.setItem('current-customers-page', 0)
    }

    return (
        <div className='top-bar'>
            {location.pathname === '/dashboard' ?
                <div className='support-icon'>
                    <img src={require('../assets/images/support-icon.svg')} />
                    <label>Support</label>
                </div>
                :
                <div>
                </div>
            }
            {location.pathname === '/dashboard' &&
                // <div className='edit-mode' onClick={() => setEditMode(!editMode)}>
                //     <label>Edit Mode</label>
                //     {editMode ? <SwitchOn className='switch-icon' /> : <SwitchOff className='switch-icon' />}
                // </div>

                <SwitchBtn active={editMode} setActice={setEditMode} />
            }
            {location.pathname === '/alert' &&
                <div className='email-creator'>
                    <img src={require('../assets/images/email-creator.svg')} />
                    <label>Email Creator</label>
                </div>
            }
            {location.pathname.includes('/sms/reports/') &&
                <div className='email-creator'>
                    <label>Message Report</label>
                </div>
            }
            <div className='top-bar-right'>
                {localStorage.getItem('selected-organisation') &&
                    <div className='exit-organistion' onClick={exitOrganisation}>
                        <label>Exit organisation</label>
                    </div>
                }
                {!admin && (
                    organisations.length > 2 ?
                        <Dropdown options={organisations} selected={selected} onSelect={onSelect} />
                        :
                        organisations.length > 0 && <label className='venue-name'>{[...organisations].pop().name}</label>
                )}
                <UserDropdown />
            </div>

        </div>
    )
}
