import React from 'react'

import UnaroIcon from '../design/unaroIcon'

import '../../assets/css/poster/poster-preview.scss'

export default ({ poster, logoUrl }) => {
    return (
        <div className='poster-preview'>
            <div className='poster-preview-content'>
                <div className='poster-preview-top' style={{ backgroundColor: poster.backgroundColor }}>
                    <label className='powered-by'>Powered by</label>
                    <UnaroIcon height="20" />
                    <img src={require('../../assets/images/wifi-icon.svg')} />
                    <label className='join'>Join our  free public</label>
                    <label className='wifi'>Wi-Fi</label>
                </div>

                <div class="container">
                    <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                        <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" fill={poster.backgroundColor}></path>
                    </svg>
                </div>

                <div class="container-2">
                    <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                        <path d="M0,80 C178,190 300,0 500,105 L500,00 L0,0 Z" fill={poster.accentColor}></path>
                    </svg>
                </div>

                <div className='poster-preview-bottom'>
                    <div className='message-container'>
                        <label>{poster.message}</label>
                    </div>
                    <div className='logo-container'>
                        <img src={logoUrl} />
                    </div>
                </div>
            </div>
        </div>
    )
}