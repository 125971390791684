import React, { useRef, useState, useEffect, useContext } from 'react'

import { VenuesContext } from '../../../context/venueContext'
import api from '../../../utils/api'

import '../../../assets/css/main-dashboard/widgets/sms-campaigns-list.scss'

export default ({ style, remove, edit }) => {

    const ref = useRef()
    const { selected } = useContext(VenuesContext)

    const [height, setHeight] = useState(0)
    const [campaigns, setCampaigns] = useState([])
    const [loadingCampaigns, setLoadingCampaigns] = useState(true)
    const [jobs, setJobs] = useState([])
    const [tags, setTags] = useState()

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])


    useEffect(() => {
        const fetchData = async () => {
            setLoadingCampaigns(true)
            const { data } = await api.get(`/sms/organisation/${selected.externalId}/campaign`)
            setCampaigns(data.map(campaign => ({
                ...campaign, tags: campaign.tags && campaign.tags.length > 0 ? campaign.tags.map(t => tags[t]).filter(t => t) : [{ name: 'All Contacts', color: '#14A697' }]
            })))
            setLoadingCampaigns(false)
        }
        if (selected && tags)
            fetchData()
    }, [selected, tags])


    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`/organisations/${selected.externalId}/tags`)
            const tagsDic = []
            data.forEach(t => tagsDic[t.externalId] = t)
            setTags(tagsDic)
        }
        if (selected)
            fetchData()
    }, [selected])

    useEffect(() => {
        if (campaigns && campaigns.length > 0) {
            const schedules = campaigns.map(campaign => {
                const firstSchedule = campaign.schedules.reduce((a, b) => a.nextFireTime < b.nextFireTime ? a : b)
                return (
                    {
                        externalId: firstSchedule.externalId,
                        campaignExternalId: campaign.externalId,
                        campaignName: campaign.name,
                        state: firstSchedule.state,
                        stateOrder: firstSchedule.state === 'NONE' ? 0 : firstSchedule.state === 'NORMAL' ? 1 : 2,
                        nextFireTime: new Date(firstSchedule.nextFireTime * 1000),
                        tags: campaign.tags
                    })
            })
                .sort((a, b) => a.nextFireTime.getTime() - b.nextFireTime.getTime())
                .sort((a, b) => b.stateOrder - a.stateOrder)

            setJobs(schedules)
        }
    }, [campaigns])

    return (
        <div className={`sms-campaigns-list-widget ${edit && 'sms-campaigns-list-widget-edit'}`} style={style} ref={ref}>
            <div className='sms-campaigns-list-widget-container'>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                <img className='sms-campaigns-list-widget-icon' src={require('../../../assets/images/sms-icon.svg')} />
                <label className='sms-campaigns-list-widget-title'>SMS Campaigns</label>
                <div className='sms-campaigns-list-widget-content'>
                    <div className='header'>
                        <label>Campaign Title</label>
                        <label>Send Date</label>
                        <label>Sent to</label>
                    </div>
                    <div className='campaigns' style={{ height: height - 140 }}>
                        {
                            jobs.filter(job => job.state !== 'NONE').map((job, i) =>
                                <div key={job.externalId} className={`campaign ${i % 2 === 0 && 'campaign-dark'}`}>
                                    <label>{job.campaignName}</label>
                                    <label>{job.nextFireTime.getDate()}/{job.nextFireTime.getMonth() + 1}/{job.nextFireTime.getFullYear()}</label>
                                    <label>{job.tags.map((t, i) => <span style={{ color: t.color }}>{i > 0 ? ', ' : ''}{t.name}</span>)}</label>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
