import React, { useState } from 'react'

import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg';
import { ReactComponent as DropdownLogo } from '../../assets/images/dropdown-icon.svg';

import '../../assets/css/main-dashboard/widget-selector.scss'

export default ({show, products, setProducts, widgetSearch, setWidgetSearch, activeLicenses, onWidgetDragStart, onWidgetDragEnd, saveDashboardLayout }) => {

    const [smallWidgets, setSmallWidgets] = useState(true)

    const onDrag = (e, product, widget) => {
        onWidgetDragStart(product, widget)
    }

    return (
        <div className={`widget-selector ${smallWidgets && 'small-widget-selector'} ${show && 'show-widget-selector'}`} onMouseEnter={() => setSmallWidgets(false)} onMouseLeave={() => setSmallWidgets(true)} >
            <div className='menu-icons'>
                <div className='menu-logo'>
                    <img className='logo-icon' src={require("../../assets/images/small-icon.svg")} />
                    <img className='logo' src={require("../../assets/images/unaro-main.svg")} />
                </div>
            </div>
            <div className='search-widget'>
                <SearchIcon className='icon' />
                <input placeholder='Search Widget...' type='search' value={widgetSearch || ''} onChange={e => setWidgetSearch(e.target.value)} />
            </div>
            <div className='products'>
                {products.filter(p => !p.requireLicense || activeLicenses.includes(p.requireLicense)).map(product =>
                    <div className='product'>
                        <div className='product-name' onClick={() => setProducts(products.map(p => p.id === product.id ? ({ ...p, open: !p.open }) : ({ ...p, open: false })))}>
                            <div className='pn-left'>
                                <img src={product.icon} />
                                <label>{product.name}</label>
                            </div>
                            <DropdownLogo className={`product-open-icon ${product.open && 'product-open-icon-open'}`} />
                        </div>
                        {product.open && <div className='product-widgets'>
                            {product.widgets.map(widget =>
                                <div
                                    className={`widget droppable-elemet ${widget.wide && 'wide'} ${widget.tall && 'tall'}`}
                                    onDragStart={e => e.dataTransfer.setData("text/plain", "")}
                                    draggable={true}
                                    unselectable="on"
                                    onDragStart={e => onDrag(e, product, widget)}
                                    onDragEnd={() => onWidgetDragEnd(product, widget)}
                                >
                                    <img src={widget.image} />
                                </div>
                            )}
                        </div>
                        }
                    </div>
                )}
            </div>
            <button onClick={saveDashboardLayout}>Save</button>
        </div>
    )
}