import React, { useRef, useState, useEffect } from 'react'

import { useHistory } from "react-router-dom";

import '../../../assets/css/main-dashboard/widgets/messages.scss'

export default ({ style, statistics, remove, edit }) => {

    const history = useHistory()

    const ref = useRef()

    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])

    return (
        <div className={`messages-widget ${edit && 'messages-widget-edit'}`} style={style} ref={ref}>
            <div className='messages-widget-container'>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                <div className='messages-widget-top'>
                    <img className='dashboatd-item-icon' src={require('../../../assets/images/sms-icon.svg')} />
                </div>
                <div className='sms-content'>
                    <div className='sms-stats' onClick={() => history.push('/sms/reports')}>
                        <label style={{ fontSize: (height / 7) }}>{statistics.success}/{statistics.error}</label>
                        <label>Total Texts Sent</label>
                    </div>
                    <div className='sms-stats' onClick={() => history.push('/sms/contacts')}>
                        <label style={{ fontSize: (height / 7) }}>{statistics.contacts}</label>
                        <label>Total SMS Contacts</label>
                    </div>
                </div>
            </div>
        </div>
    )
}