import React, { useState, useEffect } from 'react'

import api from '../utils/api'

import { StatisticsCard } from '../components/statisticsCard'
import { Signups } from '../components/signups'
import Demographics from '../components/demographics'

import '../assets/css/statistics.scss'

export const Statistics = ({ organisationId }) => {

    const admin = JSON.parse(localStorage.getItem('user')).organisations[0].orgType !== 'ORGANISATION' && !localStorage.getItem('selected-organisation')

    const [statistics, setStatistics] = useState()

    const adminCards = [
        {
            id: 0,
            bigIcon: require("../assets/images/emails-icon.svg"),
            title: "Total Emails Collected",
            number: 0,
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        },
        {
            id: 1,
            bigIcon: require("../assets/images/person-icon.svg"),
            title: "Total Accounts",
            number: 2,
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        },
        {
            id: 2,
            bigIcon: require("../assets/images/position-icon.svg"),
            title: "Total Venues",
            number: 0,
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        },
        {
            id: 3,
            bigIcon: require("../assets/images/phone-icon.svg"),
            title: "Total Devices",
            number: 0,
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        }
    ]

    const cards = [
        {
            id: 0,
            bigIcon: require("../assets/images/position-icon.svg"),
            title: "Customers connected",
            number: '',
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        },
        {
            id: 1,
            bigIcon: require("../assets/images/position-icon.svg"),
            title: "Customer emails collected",
            number: 0,
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        },
        {
            id: 2,
            bigIcon: require("../assets/images/phone-icon.svg"),
            title: "Customer return rate",
            number: "0%",
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        },
        {
            id: 3,
            bigIcon: require("../assets/images/phone-icon.svg"),
            title: "New customers today",
            number: 0,
            smallIcon: require("../assets/images/calendar-icon.svg"),
            text: "All time"
        }
    ]

    const [adminStats, setAdminStats] = useState(adminCards)
    const [stats, setStats] = useState(cards)


    const getCards = () => {
        return admin ? adminStats : stats
    }

    const setAdminCardStats = (statistics) => {
        const relationships = statistics.relationships
        const stats = adminStats.map(x => {
            if (x.id === 0)
                return { ...x, number: Object.entries(statistics.signups).map(([key, value]) => value.total).reduce((c, t) => t += c) }
            else if (x.id === 1)
                return { ...x, number: relationships.customers }
            else if (x.id === 2)
                return { ...x, number: relationships.venues }
            else if (x.id === 3)
                return { ...x, number: relationships.devices }
        })
        setAdminStats(stats)
    }

    const setNonAdminCardStats = (statistics) => {
        const result = stats.map(x => {
            if (x.id === 0)
                return { ...x, number: statistics.connectedDevices }

            if (x.id === 1)
                return { ...x, number: Object.entries(statistics.signups).map(([key, value]) => value.total).reduce((c, t) => t += c) }
            else if (x.id === 2)
                return { ...x, number: `${Math.round(statistics.visits.returnRate)}%` }

            else if (x.id === 3)
                return { ...x, number: Object.entries(statistics.signups).map(([key, value]) => value.today).reduce((c, t) => t += c) }
        })
        setStats(result)
    }

    const setCardStats = (statistics) => {
        return admin ? setAdminCardStats(statistics) : setNonAdminCardStats(statistics)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (organisationId != null) {
                const result = await api.get(`/organisation/${organisationId}/summary`)
                setStatistics(result.data)
                setCardStats(result.data)
            }
        }
        fetchData()
    }, [organisationId])

    return (
        <div className='tops'>
            <div className={`cards ${admin && 'cards-2-columns'}`}>
                {getCards().map(stat => <StatisticsCard key={stat.title} statistics={stat} />)}
            </div>
            {!admin &&
                <div className='tops-stats'>
                    <Demographics demographics={statistics ? statistics.demographics : {}} />
                    <Signups statistics={statistics} />
                </div>
            }
        </div>
    )
}