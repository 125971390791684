import React from 'react'

import '../../assets/css/widget/delete-popup.scss'

export const DeletePopup = ({onCancel, onDelete}) => {
    return (
        <div className='delete-popup'>
            <div className="delete-popup-content">
                <h3>Are you sure?</h3>
                <div className='delete-popup-actions'>
                    <button onClick={onCancel}>CANCEL</button>
                    <button onClick={onDelete}>DELETE</button>
                </div>
            </div>
        </div>
    )
}