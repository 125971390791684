import React, { useState } from 'react'

import Loader from 'react-loader-spinner'
import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';

import '../../assets/css/billing/update-payment-method-popup.scss'

export default ({ close, paymentMethod, setPaymentMethod, savePaymentMethod }) => {

    const [country, setCountry] = useState({ name: 'United Kingdom', code: 'GB' })
    const [error, setError] = useState()
    const [paymentInProgress, setPaymentInProgress] = useState(false)

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    const savePm = async event => {
        event.preventDefault();
        if (!paymentMethod.name || paymentMethod.name.length < 3)
            setError(true)
        else
            savePaymentMethod()
    }

    const changeName = e => {
        setError('')
        setPaymentMethod({ ...paymentMethod, name: e.target.value })
    }

    return (
        <div className='update-payment-method-popup' onClick={onClick}>
            <div className='update-payment-method-popup-content'>
                <div className='update-payment-method-top'>
                    <label>Update payment method</label>
                    <CloseIcon className='close-icon' onClick={close} />
                </div>
                <label>Cardholder Name</label>
                <input className={error ? 'input-error' : ''} value={paymentMethod.name || ''} onChange={changeName} />
                <label>Country</label>
                <input value={country.name} disabled='true' />
                <label>Address</label>
                <div className='cf-row-input'>
                    <input placeholder='Address Line 1' value={paymentMethod.address ? paymentMethod.address.line1 : ''} onChange={e => setPaymentMethod({ ...paymentMethod, address: { ...paymentMethod.address, line1: e.target.value } })} />
                    <input placeholder='Address Line 2' value={paymentMethod.address ? paymentMethod.address.line2 : ''} onChange={e => setPaymentMethod({ ...paymentMethod, address: { ...paymentMethod.address, line2: e.target.value } })} />
                </div>
                <label></label>
                <div className='cf-row-input'>
                    <input placeholder='City' value={paymentMethod.address ? paymentMethod.address.city : ''} onChange={e => setPaymentMethod({ ...paymentMethod, address: { ...paymentMethod.address, city: e.target.value } })} />
                    <input placeholder='ZIP/Postal Code' value={paymentMethod.address ? paymentMethod.address.postalCode : ''} onChange={e => setPaymentMethod({ ...paymentMethod, address: { ...paymentMethod.address, postalCode: e.target.value } })} />
                </div>
                {paymentInProgress ?
                    <div className='payment-loader'>
                        <Loader
                            type="Oval"
                            color="#EA5459"
                            height={30}
                            width={30}
                        />
                    </div>

                    :
                    <div className='payment-actions'>
                        <button onClick={close}>Cancel</button>
                        <button onClick={savePm}>Save</button>
                    </div>
                }
            </div>
        </div>
    )
}