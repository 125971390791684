import React, { useContext } from 'react'

import { useHistory } from "react-router-dom";

import { VenuesContext } from '../context/venueContext'

import { BackButton } from '../components/widget/backButton'

import { ReactComponent as VenuesIcon } from '../assets/images/venues.svg';
import { ReactComponent as CustomerLogo } from './../assets/images/customer-icon.svg';
import { ReactComponent as IntegrationIcon } from '../assets/images/integration.svg';


import '../assets/css/settings.scss'

export default () => {
    const history = useHistory()

    const { organisations } = useContext(VenuesContext)
    const venues = () => {
        history.push('/venues')
    }

    const inviteUser = () => {
        history.push('/invite')
    }

    const integrations = () => {
        history.push('/integrations')
    }

    const connections = [
        {
            id: 1,
            text: 'Venues',
            Icon: VenuesIcon,
            action: venues
        },
        {
            id: 2,
            text: 'Invite user',
            Icon: CustomerLogo,
            action: inviteUser
        },
        {
            id: 3,
            text: 'Integration',
            Icon: IntegrationIcon,
            action: integrations
        }
    ]

    return (
        <div className='settings-page'>
            <BackButton />
            <label className='settings-title'>Settings</label>
            <div className='settings'>
                <div className='settings-top'>
                    <label>My conections</label>
                </div>
                <div className='settings-content'>
                    {connections.map(connection =>
                        <div key={connection.id} className='connection-item' onClick={connection.action}>
                            <connection.Icon className='connection-icon' />
                            <label>{connection.text}</label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}