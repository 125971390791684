import React, { useState, useEffect } from 'react';

import api from '../utils/api'

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ShowDevices } from '../components/devices/showDevices'
import { ImportDevices } from '../components/devices/importDevices'
import { InfoPopup } from '../components/widget/infoPopup'
import { BackButton } from '../components/widget/backButton'

import '../assets/css/devices/devices.scss'

export const Devices = ({ organisationId }) => {

    const match = useRouteMatch()
    const [showInfoPopup, setShowInfoPopup] = useState(false)
    const [devices, setDevices] = useState([])
    const [name, setName] = useState("")
    const [navigation, setNavigation] = useState({ total: 1, current: 0 })

    const fetctDevices = async (page) => {
        const result = await api.get(`/organisations/${organisationId}/devices?size=8&page=${page}&name=${name}`)

        setDevices(result.data.content)
        setNavigation({ total: Math.ceil(result.data.total / 8), current: result.data.pageable.page })
    }

    useEffect(() => {
        const fetchData = async () => {
            fetctDevices(0)
        }
        fetchData()
    }, [name, organisationId])

    const changePage = (page) => {
        fetctDevices(page)
    }

    const importDevices = (addedDevices) => {
        const d = [...devices]
        d.push(...addedDevices)
        setDevices(d)
    }

    return (
        <div className='devices-page'>
            <BackButton />
            <label className='devices-page-test'>Devices</label>
            <Switch>
                <Route path={`${match.path}/import`}>
                    <ImportDevices
                        setShowInfoPopup={setShowInfoPopup}
                        organisationId={organisationId}
                        addDevices={importDevices}
                    />
                </Route>
                <Route path={match.path}>
                    <ShowDevices
                        devices={devices}
                        setDevices={setDevices}
                        setName={setName}
                        navigation={navigation}
                        setNavigation={setNavigation}
                        changePage={changePage}
                    />
                </Route>
            </Switch>
            {showInfoPopup && <InfoPopup text='Devices imported' onOk={() => setShowInfoPopup(false)} />}
        </div >
    )
}