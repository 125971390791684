import React, { useState } from 'react'

import '../../assets/css/widget/popup.scss'

export default ({ onCancel, onCreate, type }) => {

    const [name, setName] = useState()

    return (
        <div className='popup'>
            <div className="popup-content">
                <h3>{`Create ${type === 'poster' ? 'poster' : 'landing page'}`}</h3>
                <input placeholder={`${type === 'poster' ? 'Poster' : 'Landing page'} name`} type="text" autoFocus onChange={e => setName(e.target.value)} />
                <div className='popup-actions'>
                    <button className='cancel' onClick={() => onCancel()}>CANCEL</button>
                    <button onClick={() => onCreate(name)}>CREATE</button>
                </div>
            </div>
        </div >
    )
}