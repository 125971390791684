import React, { useRef } from 'react'

import '../../assets/css/widget/file-selector.scss'

export const FileSelector = ({ title, fileName, setFile }) => {

    const inputFile = useRef(null)

    return (
        <div className='file-selector'>
            <label className='file-selector-title'>{title}</label>
            <input type='file' ref={inputFile} onChange={(e) => setFile(e.target.files[0])} />
            <label className='file-selector-file-name'>{fileName}</label>
            <label className='file-selector-btn' onClick={() => inputFile.current.click()}>Upload a file</label>
        </div>
    )
}