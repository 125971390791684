import React, { useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import api from '../utils/api'

import '../components/customers/showCustomers'

import { ShowCustomers } from '../components/customers/showCustomers'
import { CustomerDetails } from '../components/customers/customerDetails'

import '../assets/css/track/track.scss'

export default ({ organisationId }) => {
    const match = useRouteMatch()
    const history = useHistory()

    const [customers, setCustomers] = useState([])
    const [navigation, setNavigation] = useState({ total: 1, current: 0 })

    const [name, setName] = useState('')
    const [selectedDate, setSelectedDate] = useState()


    const getDate = () => {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 21);
        currentDate.setMilliseconds(0)
        currentDate.setSeconds(0)
        currentDate.setMinutes(0)
        currentDate.setHours(0)
        return currentDate.getTime()
    }

    const getCustomers = async (page, size = 16) => {
        var url = `/organisations/${organisationId}/contacts?size=${size}&page=${page}&sort=lastVisit,DESC&type=&name=${name}`
        if (selectedDate)
            url += `&visitFrom=${selectedDate.getTime()}`
        else
            url += `&visitFrom=${getDate()}`

        return await api.get(url)
    }

    const fetctCustomers = async (page) => {
        const result = await getCustomers(page)
        setCustomers(result.data.content)
        setNavigation({ total: Math.ceil(result.data.total / 16), current: result.data.pageable.page })
    }

    useEffect(() => {
        const fetchData = async () => {
            fetctCustomers(0)
        }
        fetchData()
    }, [name, selectedDate, organisationId])

    const goToAlert = async () => {
        const result = await getCustomers(0, 1000)
        history.push({ pathname: '/alert', state: { customers: result.data.content } })
    }

    return (
        <div className='track-page'>

            <Switch>
                <Route path={`${match.path}/:customerId`}>
                    <CustomerDetails track={true}/>
                </Route>
                <Route path={match.path}>
                    <ShowCustomers
                        customers={customers}
                        fetctCustomers={fetctCustomers}
                        setName={setName}
                        navigation={navigation}
                        setNavigation={setNavigation}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        track={true}
                        organisationId={organisationId}
                        goToAlert={goToAlert}
                    />
                </Route>
            </Switch>
        </div>
    )
}