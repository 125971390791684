import React, { useRef, useState, useEffect } from 'react'

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import '../../../assets/css/main-dashboard/widgets/customer-logins.scss'

export default ({ style, statistics, remove, edit }) => {

    const [twitter, setTwitter] = useState(0)
    const [facebook, setFacebook] = useState(0)
    const [email, setEmail] = useState(0)
    const [data, setData] = useState([])

    const colors = ['#E95353', '#F39638', '#FFCC33']

    const ref = useRef()

    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.clientWidth)
    }, [ref])

    useEffect(() => {
        if (statistics) {
            delete statistics.signups['sms_added']
            const total = Object.entries(statistics.signups).map(([key, value]) => value.total).reduce((c, t) => t += c)
            setTwitter(Math.round(statistics.signups.twitter.total * 100 / total) || 0)
            setFacebook(Math.round(statistics.signups.facebook.total * 100 / total) || 0)
            setEmail(Math.round(statistics.signups.email.total * 100 / total) || 0)
        }
    }, [statistics])

    useEffect(() => {
        setData([{ value: twitter }, { value: facebook }, { value: email }])
    }, [twitter, facebook, email])

    return (
        <div className={`customer-logins-widget ${edit && 'customer-logins-widget-edit'}`} style={style} ref={ref}>
            <div className='customer-logins-widget-container'>
                <div className={`remove-widget-icon ${edit && 'show-remove'}`} onClick={remove}>
                    <img src={require('../../../assets/images/remove-widget-icon.svg')} />
                </div>
                <img className='customer-logins-widget-icon' src={require('../../../assets/images/connect-icon.svg')} />
                <label className='customer-logins-widget-title'>Customer Logins</label>
                <div className='customer-logins-content' style={{ flexDirection: width < 360 && height > 200 ? 'column' : 'row' }}>
                    {width !== 0 ?
                        <div className='cl-chart'>
                            <ResponsiveContainer width='99%' height="99%" minWidth="0" minHeight="0">
                                <PieChart >
                                    <Pie data={data} >
                                        {
                                            data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index]} stroke={colors[index]} />
                                            ))
                                        }
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        :
                        <div>
                        </div>
                    }
                    <div className='cl-stats' style={{ alignSelf: width < 360 && height > 200 ? 'center' : 'unset' }}>
                        <div className='cl-stat'>
                            <img style={{ width: height > 300 ? 30 : height / 10, height: height > 300 ? 35 : height / 10 }} src={require('../../../assets/images/twitter-login.svg')} />
                            <label style={{ fontSize: width < 360 ? '12px' : '17px' }}>Twitter: <span style={{ fontSize: width < 360 ? '12px' : '17px' }}>{twitter}%</span></label>
                        </div>
                        <div className='cl-stat'>
                            <img style={{ width: height > 300 ? 30 : height / 10, height: height > 300 ? 35 : height / 10 }} src={require('../../../assets/images/fb-login.svg')} />
                            <label style={{ fontSize: width < 360 ? '12px' : '17px' }}>Facebook: <span style={{ fontSize: width < 360 ? '12px' : '17px' }}>{facebook}%</span></label>
                        </div>
                        <div className='cl-stat'>
                            <img style={{ width: height > 300 ? 30 : height / 10, height: height > 300 ? 35 : height / 10 }} src={require('../../../assets/images/email-login.svg')} />
                            <label style={{ fontSize: width < 360 ? '12px' : '17px' }}>Email: <span style={{ fontSize: width < 360 ? '12px' : '17px' }}>{email}%</span></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}