import React from 'react'

import { ReactComponent as SelectedIcon } from '../../assets/images/selected.svg'
import { ReactComponent as UnselectedIcon } from '../../assets/images/unselected.svg'

export const RadioButton = ({selected, onSelect}) => {
    return (
        <div onClick={onSelect}>
            {selected ? <SelectedIcon className='selector'/> : <UnselectedIcon className='selector'/>}
        </div>
    )
}