import React from 'react'

import { ReactComponent as BulletIcon } from '../../assets/images/bullet.svg';

import '../../assets/css/widget/bullet-navigator.scss'

export default ({screens, current = 0, setCurrent }) => {
    return (
        <div className='bullet-navigator'>
            {screens.map(value =>
                <BulletIcon key={value} className={`bullet-navigator-bullet ${value === current && 'bullet-navigator-bullet-active'}`} onClick={() => setCurrent(value)}/>
            )}
        </div>
    )
}