import React, { useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import Axios from 'axios';

import { ReactComponent as EyeIcon } from '../assets/images/eye.svg';
import { ReactComponent as EyeShowIcon } from '../assets/images/eye-show.svg';

import '../assets/css/reset-password.scss'


export default () => {
    const location = useLocation()
    const history = useHistory()

    const token = new URLSearchParams(location.search).get('token')

    const [activation, setActivation] = useState({ token: token })
    const [passwordMatch, setPasswordMatch] = useState()
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [showPasswordNotMatchError, setShowPasswordNotMatchError] = useState(false)

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)


    const save = async () => {
        if (!activation.password || activation.password.length < 6)
            setShowPasswordError(true)
        else if (activation.password !== passwordMatch)
            setShowPasswordNotMatchError(true)

        else {
            setShowPasswordError(false)
            setShowPasswordNotMatchError(false)
            await Axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/user/activate`, activation)
            setActivation({ ...activation, password: '', token: '' })
            setPasswordMatch('')
            history.replace('/login')
        }
    }

    const onConfirmChange = (confirm) => {
        setShowPasswordNotMatchError(false)
        setPasswordMatch(confirm)
    }

    return (
        <div className='reset-password-page'>
            <div className='reset-password'>
                <div className='reset-password-top'>
                    <label>Reset Password</label>
                </div>
                <hr />
                <div className='reset-password-inputs'>
                    <div className='reset-password-input'>
                        <label>Password</label>
                        <div className='password-input'>
                            <input placeholder='Please enter password' type={showPassword ? 'text' : 'password'} className={`${showPasswordError && 'input-error'}`} value={activation.password || ''} onChange={e => setActivation({ ...activation, password: e.target.value })} />
                            {showPassword ? <EyeIcon className='eye-icon' onClick={() => setShowPassword(!showPassword)} /> : <EyeShowIcon className='eye-icon' onClick={() => setShowPassword(!showPassword)} />}
                        </div>
                    </div>
                    <div className='reset-password-input'>
                        <label>Confirm password</label>
                        <div className='password-input'>
                            <input placeholder='Please confirm password' type={showConfirmPassword ? 'text' : 'password'} className={`${showPasswordNotMatchError && 'input-error'}`} value={passwordMatch || ''} onChange={e => onConfirmChange(e.target.value)} />
                            {showConfirmPassword ? <EyeIcon className='eye-icon' onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> : <EyeShowIcon className='eye-icon' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />}
                        </div>
                    </div>
                    {showPasswordNotMatchError === true && <label className='error-message'>Password mismatch</label>}
                </div>
                <hr />
                <div className='reset-password-actions'>
                    <button onClick={save}>Save</button>
                </div>
            </div>
        </div>
    )
}