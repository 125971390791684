import React, { useState, useEffect } from 'react'

import { BackButton } from '../../../components/widget/backButton'
import NewCampaign from './create/newCampaign'
import ComposeMessage from './create/composeMessage'
import SendMessage from './create/sendMessage'
import { useParams } from "react-router-dom";
import api from '../../../utils/api'

import '../../../assets/css/sms/campaign/create-campaign.scss'

export default ({ organisationId }) => {

    const { campaignId } = useParams();

    const [step, setStep] = useState(0)
    const [campaign, setCampaign] = useState()

    const goBack = s => {
        if (s < step)
            setStep(s)
    }

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`/sms/organisation/${organisationId}/campaign/${campaignId}`)
            setCampaign({ ...data, recipients: [] })
        }

        if (organisationId) {
            if (campaignId)
                fetchData()
            else
                setCampaign({ messageBody: 'test', organisationExternalId: organisationId, recipients: [] })
        }
    }, [organisationId, campaignId])

    const saveTemplate = async template => {
        template.organisationExternalId = organisationId
        if (!template.messageBody)
            template.messageBody = campaign.messageBody
        await api.post(`/sms/template`, template)
    }

    return (
        <div className='create-campaign'>
            <BackButton />
            <div className='top'>
                <div className='steps'>
                    <div className='step' onClick={() => goBack(0)}>
                        <div className='number'>
                            <label>1</label>
                        </div>
                    </div>
                    <div className='line' />
                    <div className='step' onClick={() => goBack(1)}>
                        <div className='number' style={{ backgroundColor: step < 1 ? '#A7A7A7' : '#FF9933' }}>
                            <label>2</label>
                        </div>
                    </div>
                    <div className='line' />
                    <div className='step' onClick={() => goBack(2)}>
                        <div className='number' style={{ backgroundColor: step < 2 ? '#D8D8D8' : '#EA5454' }}>
                            <label>3</label>
                        </div>
                    </div>
                </div>
                <div className='titles'>
                    <label>Create Campaign</label>
                    <label style={{ color: step < 1 ? '#C2C2C2' : 'black' }}>Compose Message</label>
                    <label style={{ color: step < 2 ? '#C2C2C2' : 'black' }}>Send Message</label>
                </div>
            </div>
            {step === 0 ? <NewCampaign campaign={campaign} setCampaign={setCampaign} next={() => setStep(step + 1)} /> : step === 1 ? <ComposeMessage campaign={campaign} setCampaign={setCampaign} next={() => setStep(step + 1)} saveTemplate={saveTemplate} /> : <SendMessage saveTemplate={saveTemplate} />}
        </div>
    )
}