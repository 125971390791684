import React from 'react'

import Slider from 'rc-slider';

import '../../assets/css/widget/percent-slider.scss'
import 'rc-slider/assets/index.css';

export const PercentSlider = ({ title, value = 0, setValue }) => {

    return (
        <div className='percent-slider'>
            <label className='percent-slider-title'>{title}</label>
            <Slider className='slider' value={value} min={0} max={1} step={0.1} onChange={value => setValue(value)} />
            <label className='percent-slider-value'>{`${Math.round(value * 100)}%`}</label>
        </div>
    )
}